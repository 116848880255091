



































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ClientModule from '@/stores/modules/ClientModule';

@Component
export default class ClientGeneral extends Vue {
  public clientModule: ClientModule = getModule(ClientModule);
}
