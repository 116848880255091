import Vue from 'vue';
import { API_BASE } from '@config/api';
import { mapState } from 'vuex';
import Component from 'vue-class-component';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './stores';

import './plugins';
import './filters';

import emitter from './services/event_bus.service';
import StickyThead from './plugins/stickyThead';

declare var $: any;

Vue.use(StickyThead);
Vue.prototype.$emitter = emitter;

Vue.config.productionTip = false;
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);

// multiplas gerencias de estado
export const bus = new Vue();

const vue = new (<any>Vue)({
  store,
  router,
  vuetify,
  emitter,
  render: (h: any) => h(App),
  computed: mapState('authentication', ['user']),
  mounted() {
    this.checkToken();
    this.createMenuAction();
  },
  methods: {
    checkToken() {
      var self = <any> this;
      if (
        !self.$session.exists()
        && (self.$route.name === 'TokenHandler' || self.$route.name === 'Login')
      ) {
        return;
      }

      if (!self.$session.exists() && self.$route.name != 'LoginToken') {
        router.push('login');
        return;
      }

      const url = `${API_BASE}checkstatus`;

      var timerID = setInterval(() => {
        $.ajax({
          url,
          method: 'POST',
          data: {
            token: self.$session.get('token'),
          },
        })
          .done(() => {
            console.log('Session Check Ok!');
          })
          .fail((error : any) => {
            if (error.status == 401) {
              router.push('login');
            }
          });
      }, 60 * 1000);

      // clearInterval(timerID);
    },

    createMenuAction() {
      $('#m_aside_left_minimize_toggle').click(() => {
        var header_inncash = $('.header-inncash');

        if (header_inncash.length > 0) {
          setTimeout(() => {
            var width_reference = $('.level-main')[0].clientWidth;

            header_inncash.css({ width: `${width_reference}px` });
          }, 500);
        }

        // Na tela de conciliacao de DDA, tem que destruir e recriar o header
        if ($('#table-conciliation').length > 0) {
          var reinit = $('table').floatThead('destroy');

          setTimeout(() => reinit(), 1000);
        }
      });
    },
  },
}).$mount('#app');

export default vue;
