


















import Vue from 'vue';
import Component from 'vue-class-component';
import { getModule } from 'vuex-module-decorators';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import Module from '@/domain/models/Module';
import Drawer from './drawer/Drawer.vue';
import Toolbar from './toolbar/Toolbar.vue';

@Component({
  components: {
    Drawer,
    Toolbar,
  },
})
export default class AppView extends Vue {
  companyVisible: boolean = false;
  authStore?: AuthenticationModule;

  created() {
    this.authStore = getModule(AuthenticationModule, this.$store);
  }

  get currentModule(): Module | null {
    return (this.authStore && this.authStore!.currentModule) ?? null;
  }

  get hideAll(): boolean {
    return (
      this.$route.path === '/login'
      || this.$route.path === '/register'
      || this.$route.path == '/logout'
      || this.$route.path == '/reset-password'
    );
  }

  get hideDrawer(): boolean {
    return this.hideAll || this.currentModule == null;
  }

  actionCompany(value: boolean) {
    this.companyVisible = value;
  }
}
