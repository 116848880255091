































































import {
  Component,
  Vue,
  Watch,
  Prop,
  Ref,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { VForm } from '@/types/VForm';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import ConcludeActionsIdentifiers from '@/components/timeline/types/ConcludeActionsIdentifiers';
import ConcludeInteractionParams from '@/components/timeline/types/ConcludeInteractionParams';
import EventsRepository from '@/repositories/EventsRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';

@Component
export default class ConcludeEventDialog extends Vue {
  public model: boolean;
  public loading: boolean = false;

  public observation: string = '';

  public readonly inputRules: InputRulesHelper = new InputRulesHelper();

  public authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  public eventAction: ConcludeActionsIdentifiers = {
    success: { text: 'Sucesso', color: 'green' },
    failure: { text: 'Falha', color: 'red darken-1' },
  };

  public constructor() {
    super();

    this.model = this.value;
  }

  @Ref('ConcludeEventForm') readonly concludeEventForm!: VForm;

  @Prop(Boolean) readonly value!: boolean;

  @Prop(Number) readonly id!: number;

  @Prop(String) readonly status!: 'failure' | 'success';

  @Watch('value')
  OnChangeValue(newValue: boolean) {
    this.model = newValue;
  }

  @Watch('model')
  OnChangeModel(newModel: boolean) {
    this.$emit('input', newModel);
  }

  public get clientId(): string {
    return this.$route.params.id;
  }

  public get companyGroupId(): string {
    return this.authenticationModule.user.company_group_id;
  }

  public handleSave(): void {
    if (!this.concludeEventForm.validate()) {
      return;
    }

    this.loading = true;

    const params: ConcludeInteractionParams = {
      observation: this.observation,
      status: this.status,
    };

    try {
      new EventsRepository().concludeEvent(this.clientId, this.companyGroupId, this.id, params);

      this.$notification.success('Interação concluida com sucesso!');
      this.handleClose('save');
    } catch (error) {
      this.$notification.error('Houve um problema ao concluir a interação!');
    } finally {
      this.loading = false;
    }
  }

  public handleClose(action: string): void {
    this.$emit(action);
    this.reset();
    this.model = false;
  }

  public reset(): void {
    this.observation = '';
  }
}
