<template>
  <v-row dense class="flex-grow-1 mt-2">
    <v-col v-bind="columns">
      <v-card
        elevation="0"
        :color="getInfoCardColor"
        class="pa-3 d-flex flex-column justify-space-between height-100"
      >
        <h6 class="text-left">Títulos selecionados</h6>
        <p
          class="mb-0 text-right highlight"
          :style="`color: ${getTextPriceValuesColor}`"
        >
          {{ quantity }}
        </p>
      </v-card>
    </v-col>
    <v-col v-bind="columns">
      <v-card
        elevation="0"
        :color="getInfoCardColor"
        class="pa-3 d-flex flex-column justify-space-between height-100"
      >
        <h6 class="text-left">Valor a receber</h6>
        <p
          class="mb-0 text-right highlight"
          :style="`color: ${getTextPriceValuesColor}`"
        >
          {{ formattedSubtotal }}
        </p>
      </v-card>
    </v-col>
    <v-col v-bind="columns">
      <v-card
        elevation="0"
        :color="getInfoCardColor"
        class="pa-3 d-flex flex-column justify-space-between height-100"
      >
        <h6 class="text-left">Juros (%)</h6>

        <div class="mb-0 text-right highlight">
          <span
            class="highlight mr-2"
            :style="`color: ${getTextPriceValuesColor}`"
          >
            {{ formattedFee }}
          </span>

          <editor-menu v-model="fee" />
        </div>
      </v-card>
    </v-col>
    <v-col v-bind="columns">
      <v-card
        elevation="0"
        :color="getInfoCardColor"
        class="pa-3 d-flex flex-column justify-space-between height-100"
      >
        <h6 class="text-left">Juros (R$)</h6>
        <p
          class="mb-0 text-right highlight"
          :style="`color: ${getTextPriceValuesColor}`"
        >{{ formattedCalculatedFee }}</p>
      </v-card>
    </v-col>
    <v-col v-bind="columns">
      <v-card
        elevation="0"
        :color="getInfoCardColor"
        class="pa-3 d-flex flex-column justify-space-between height-100"
      >
        <h6 class="text-left">Total a receber</h6>
        <p
          class="mb-0 text-right highlight"
          :style="`color: ${getTextPriceValuesColor}`"
        >{{ formattedTotal }}</p>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { toCurrency, arraySumProperty } from '@/utils';
import EditorMenu from '@/components/tables/EditorMenu.vue';

export default {
  name: 'TotalCalculator',
  props: {
    selected: Array,
  },
  data() {
    return {
      fee: 0,
    };
  },
  computed: {
    getTextPriceValuesColor() {
      if (this.$vuetify.theme.dark) {
        return '';
      }

      return '#007BDD';
    },
    getInfoCardColor() {
      if (this.$vuetify.theme.dark) {
        return '#565656';
      }

      return '#F2F3F7';
    },
    quantity() {
      return this.selected.length;
    },
    subtotal() {
      return arraySumProperty(this.selected, 'value');
    },
    formattedSubtotal() {
      return toCurrency(this.subtotal);
    },
    calculatedFee() {
      return (this.subtotal * Number(this.fee)) / 100;
    },
    formattedCalculatedFee() {
      return toCurrency(this.calculatedFee);
    },
    total() {
      return this.subtotal + this.calculatedFee;
    },
    formattedTotal() {
      return toCurrency(this.total);
    },
    formattedFee() {
      return `${Number(this.fee).toFixed(2)}%`;
    },
    columns() {
      return this.$vuetify.breakpoint.smAndDown ? { cols: '12' } : {};
    },
  },
  components: {
    EditorMenu,
  },
};
</script>

<style></style>
