import { DateTime } from 'luxon';

const ptBrDateFormat = 'dd/MM/yyyy';

export function dateNow(): string {
  return DateTime.now().toISODate();
}

export function formateDate(date: string): string {
  return DateTime.fromISO(date).toFormat(ptBrDateFormat);
}

export function formateDateToISO(date: string): string {
  return DateTime.fromFormat(date, ptBrDateFormat).toISODate();
}

export function validateDateRange(initial: string, end: string): boolean {
  const initialDate = DateTime.fromISO(initial);
  const endDate = DateTime.fromISO(end);

  return endDate.diff(initialDate, 'days').days >= 0;
}

export function dateGreaterOrEqualToday(value: string): boolean {
  if (value === undefined) return false;
  const today = DateTime.fromISO(dateNow());
  const date = DateTime.fromFormat(value, ptBrDateFormat);
  return date.diff(today, 'days').days >= 0;
}

export function monthsDistanceBetweenTwoDates(initial: string, end: string): number {
  const initialDate = DateTime.fromISO(initial);
  const endDate = DateTime.fromISO(end);

  return endDate.diff(initialDate, 'months').months;
}
