enum GroupFilterParametersEnum {
    CLIENT_LIST = 'CLIENT_LIST',
    CONCILIATION_LIST = 'CONCILIATION_LIST',
    ACCOUNT_RECEIVABLE_CLIENT_LIST = 'ACCOUNT_RECEIVABLE_CLIENT_LIST',
    SALES_ORDER_CLIENT_LIST = 'SALES_ORDER_CLIENT_LIST',
    SALES_ORDER_ORDER_LIST = 'SALES_ORDER_ORDER_LIST',
    BILLING_HISTORY = 'BILLING_HISTORY',
    WRITE_OFF_MOVEMENT = 'WRITE_OFF_MOVEMENT',
    BILLING_REMITTANCE = 'BILLING_REMITTANCE',
    ORDERS = 'ORDERS',
    ORDER_ANALISYS = 'ORDER_ANALISYS',
}

export default GroupFilterParametersEnum;
