

































































import { Component, Vue, Watch } from 'vue-property-decorator';

@Component

export default class LoginLayout extends Vue {
	public valid: boolean = false;

	@Watch('valid')
		isValid() {
			this.$emit('input', this.valid);
		}
	public onSubmit(): any {
		this.$emit('submit');
	}
	public revalidate(): any {
		(this.$refs.authForm as Vue & { validate: () => boolean }).validate();
	}
}
