


















































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import NavigationTabs from '@/components/navigation/tabs/NavigationTabs.vue';
import NavigationTabsType from '@/types/NavigationTabsType';
import ClientModule from '@/stores/modules/ClientModule';
import OrderModule from '@/stores/modules/OrderModule';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import ClientRepository from '@/repositories/ClientRepository';
import OrderRepository from '@/repositories/OrderRepository';
import IWebhookBaseParams from '@/repositories/parameters/IWebhookBaseParams';
import UpdateClientInfoParams from '@/views/clients/types/UpdateClientInfoParams';

@Component({
  components: {
    NavigationTabs,
  },
})
export default class Order extends Vue {
  public selectedTab: number = 0;

  public orderLoading: boolean = true;

  public tabs: NavigationTabsType[] = [
    { name: 'Análise Crédito', path: `/pedidos/${this.orderID}/${this.customerId}` },
    {
      name: 'Informações Cliente',
      path: `/pedidos/${this.orderID}/${this.customerId}/cliente`,
    },
    { name: 'Itens do pedido', path: `/pedidos/${this.orderID}/${this.customerId}/itens` },
    { name: 'Vendas', path: `/pedidos/${this.orderID}/${this.customerId}/vendas` },
    {
      name: 'Histórico Cobrança',
      path: `/pedidos/${this.orderID}/${this.customerId}/historico-cobranca`,
    },
  ];

  private readonly clientModule: ClientModule = getModule(ClientModule);
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly orderModule: OrderModule = getModule(OrderModule);
  private readonly clientRepository: ClientRepository = new ClientRepository();
  private readonly orderRepository: OrderRepository = new OrderRepository();

  public get orderID(): string {
    return this.$route.params.orderId;
  }

  public get customerId(): string {
    return this.$route.params.customerId;
  }

  public get canReleaseOrder(): boolean {
    return this.orderModule.orderStatus !== 1;
  }

  public created(): void {
    this.selectedTab = this.defineTabByRoute(this.$route.name);

    Promise.all([
      this.getClientById(),
      this.getOrderInformations(),
    ]);
  }

  public async getClientById(): Promise<void> {
    try {
      const client = await this.clientRepository.getClientById(
        this.customerId,
        this.authenticationModule.user.company_group_id,
        this.authenticationModule.companyIds,
      );

      this.clientModule.setClient(client);
      this.clientModule.setClientLoading(false);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar o cliente selecionado!');
    }
  }

  public async getOrderInformations(): Promise<void> {
    this.$dialog.startLoading();

    try {
      const response = await this.orderRepository.getCreditAnalisysOrderFinancialValuesByOrderInnId(
        this.authenticationModule.companyIds,
        this.authenticationModule.user.company_group_id,
        this.$route.params.orderId,
      );

      this.orderModule.setOrderValue(response.totalValueOrder);
      this.orderModule.setHigherOrderValue(response.higherOrderValue);
      this.orderModule.setOrderStatus(response.status);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as informações desse pedido!');
    } finally {
      this.orderLoading = false;
      this.$dialog.stopLoading();
    }
  }

  public async saveChanges(): Promise<void> {
    if (this.clientModule.client.companyId === null) {
      this.$notification.error('Este cliente não possui o id da empresa que pertence!');

      return;
    }

    this.$dialog.startLoading();

    const clientParameters: IWebhookBaseParams<UpdateClientInfoParams> = {
        id: this.clientModule.client.id,
        company_id: this.clientModule.client.companyId,
        data: {
          phone: this.clientModule.client.phone,
          invoiceMail: this.clientModule.client.invoiceMail,
          financialMail1: this.clientModule.client.financialMail1,
          financialMail2: this.clientModule.client.financialMail2,
          group: this.clientModule.client.group,
          subGroup: this.clientModule.client.subGroup,
          deadline: this.clientModule.client.deadline,
          limit: this.clientModule.client.limit,
          risk: this.clientModule.client.risk,
          clientClass: this.clientModule.client.clientClass,
          notes: this.clientModule.client.notes,
        },
    };

    try {
      await this.clientRepository.updateClient(
        this.clientModule.client.companyId,
        this.authenticationModule.user.company_group_id,
        clientParameters,
      );

      this.$notification.success('Alterações do cliente salvas com sucesso!');
    } catch (error) {
      this.$notification.error('Houve um problema ao salvar o cliente selecionado!');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public async releaseOrder(): Promise<void> {
    this.$dialog.startLoading();

    try {
      await this.orderRepository.releaseOrderOnERP(
        this.authenticationModule.user.company_group_id,
        this.authenticationModule.companyIds,
        this.orderID,
        this.customerId,
      );

      this.$notification.info('O pedido foi enviado para a fila de liberação!');
    } catch (error) {
      console.log(error);
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public handleCancel(): void {
    this.$router.push({ name: 'OrderList' });
  }

  public defineTabByRoute(routeName: string | null | undefined): number {
    switch (routeName) {
      case 'OrderAnalisys':
        return 0;
      case 'OrderCustomer':
        return 1;
      case 'OrderItems':
        return 2;
      case 'OrderCustomerSells':
        return 3;
      case 'OrderHistory':
        return 4;
      default:
        return 0;
    }
  }
}
