<template>
  <page-container>
    <breadcrumbs pageTitle="Agenda de cobrança"></breadcrumbs>
    <page-card dividerOn>
      <template #title>
        <v-row>
          <v-col  cols="12" lg="4" md="4" sm="6" xs="12">
            <v-select
              v-model="viewType"
              :items="viewTypeOptions"
              label="Visualização"
              hide-details
              filled
            >
            </v-select>
          </v-col>
          <v-spacer/>
          <v-col  cols="12" lg="2" md="4" sm="6" xs="12">
             <v-btn
                @click="eventDialogShow = true"
                class="font-weight-bold"
                x-large
                block
                depressed
                color="primary"
              >
                ADICIONAR
              </v-btn>
          </v-col>
        </v-row>
      </template>
      <template #body>
        <v-toolbar flat>
          <v-toolbar-title v-if="showTitle">
            {{ calendarTitle }}
          </v-toolbar-title>
          <div class="buttons ml-4">
            <inn-btn text class="mr-4" color="primary" @click="focus = ''">
              HOJE
            </inn-btn>
            <inn-icon-btn
              icon="mdi-chevron-left"
              color="grey darken-2"
              @click="$refs.calendar.prev()"
            >
            </inn-icon-btn>
            <inn-icon-btn
              icon="mdi-chevron-right"
              color="grey darken-2"
              @click="$refs.calendar.next()"
            >
            </inn-icon-btn>
          </div>
           <v-spacer/>
             <button-tooltip
              fab
              icon
              small
              position="left"
              buttonIcon="mdi-menu-open"
              :tooltipText="scheduleSummaryShow ? 'Esconder Sumário' : 'Exibir Sumário'"
              @click="scheduleSummaryShow = !scheduleSummaryShow"
            />
        </v-toolbar>
        <v-row align="start">
          <v-col :cols="scheduleSummaryShow ? 9 : 12">
            <v-calendar
              ref="calendar"
              color="primary"
              v-model="focus"
              :type="viewType"
              :events="events"
              :first-interval="6"
              :interval-count="14"
              @click:date="viewType = 'day'"
              @click:more="viewType = 'day'"
              @click:event="handleEventClick"
            >
            </v-calendar>
          </v-col>
          <v-col cols="3" v-if="scheduleSummaryShow">
            <schedule-summary
              :events="events"
              @click:edit="handleSummaryEdit"
              :loading="loading"
            ></schedule-summary>
          </v-col>
        </v-row>
      </template>
    </page-card>
    <event-dialog
      v-model="eventDialogShow"
      :event="dialogEvent"
      @save-event="saveEvent"
      @cancel="handleDialogCancel"
    ></event-dialog>
    <event-menu
      ref="menu"
      v-model="eventMenuShow"
      :event="selectedEvent"
      :selected="selectedElement"
      @click:edit="handleEventEdit"
      @click:conclude="showConcludeDialog"
    ></event-menu>
    <conclude-event-dialog
      v-model="concludeDialog"
      :status="selectedStatus"
      :event="selectedEvent"
      @save="handleConcludeSave"
      @cancel="handleConcludeCancel"
    >
    </conclude-event-dialog>
  </page-container>
</template>

<script>
import EventsRepository from '@/mocks/events';
import ConcludeEventDialog from '@/components/timeline/ConcludeEventDialog.vue';
import EventDialog from './components/EventDialog.vue';
import EventMenu from './components/EventMenu.vue';
import ScheduleSummary from './components/ScheduleSummary.vue';

export default {
  name: 'Schedule',
  data() {
    return {
      loading: true,
      scheduleSummaryShow: true,
      eventDialogShow: false,
      eventMenuShow: false,
      selectedEvent: null,
      concludeDialog: false,
      selectedStatus: '',
      dialogEvent: null,
      selectedElement: null,
      focus: '',
      events: [],
      viewType: 'week',
      viewTypeOptions: [
        { value: 'day', text: 'Dia' },
        { value: 'week', text: 'Semana' },
        { value: 'month', text: 'Mês' },
      ],
      dragEvent: null,
      dragTime: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
      showTitle: false,
    };
  },
  methods: {
    saveEvent(event) {
      this.dialogEvent = null;
      const saved = this.events.find((e) => e.id == event.id);
      if (saved) {
        this.events = this.events.map((e) => (e.id == event.id ? event : e));
        this.selectedEvent = event;
        this.$refs.menu.$forceUpdate();
      } else {
        this.events.push(event);
      }
    },
    handleEventClick({ event, nativeEvent }) {
      const open = () => {
        this.selectedEvent = event;
        this.selectedElement = nativeEvent.target;
        requestAnimationFrame(() => requestAnimationFrame(() => {
            this.eventMenuShow = true;
          }));
      };

      if (this.selectedOpen) {
        this.selectedOpen = false;
        requestAnimationFrame(() => requestAnimationFrame(() => open()));
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },
    handleEventEdit() {
      this.dialogEvent = this.selectedEvent;
      this.eventDialogShow = true;
    },
    handleSummaryEdit(id) {
      this.dialogEvent = this.events.find((e) => e.id == id);
      this.eventDialogShow = true;
    },
    showConcludeDialog(id, status) {
      this.concludeEvent = this.events.find((e) => e.id == id);
      this.selectedStatus = status;
      this.concludeDialog = true;
    },
    handleConcludeSave(details) {
      this.selectedEvent.details = details;
      this.selectedEvent.status = this.selectedStatus;
      this.selectedStatus = '';
      this.$refs.menu.$forceUpdate();
    },
    handleConcludeCancel() {
      this.selectedStatus = '';
      this.concludeEvent = null;
    },
    handleDialogCancel() {
      this.dialogEvent = null;
    },
    rnd: (a, b) => Math.floor((b - a + 1) * Math.random()) + a,
    rndElement(arr) {
      return arr[this.rnd(0, arr.length - 1)];
    },
  },
  async mounted() {
    const remoteEvents = await EventsRepository.getAll();
    this.loading = false;
    this.events = remoteEvents.filter((e) => !e.isComplete);
    this.$refs.calendar.checkChange();
    this.showTitle = true;
  },
  components: {
    EventDialog,
    EventMenu,
    ScheduleSummary,
    ConcludeEventDialog,
  },
  computed: {
    calendarTitle() {
      return this.$refs.calendar ? this.$refs.calendar.title : this.$moment().format('MMMM YYYY');
    },
  },
};
</script>

<style lang="sass">
.page-content--agenda-de-cobranca
  .view-type-select
    max-width: 200px
</style>
