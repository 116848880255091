import Repository from '@/repositories/base/Repository';
import BillingHistoryFilterParams from '@/components/timeline/types/BillingHistoryFilterParams';
import UpsertBillingHistoryParams from '@/components/timeline/types/UpsertBillingHistoryParams';
import ConcludeInteractionParams from '@/components/timeline/types/ConcludeInteractionParams';
import BillingHistory from '@/domain/models/BillingHistory';

export default class EventsRepository extends Repository {
  public async getAllClientsEvents(
    customerId: string,
    moduleId: string,
    params: BillingHistoryFilterParams,
  ): Promise<BillingHistory[]> {
    const { status, data: response } = await this.datasource.get(`company-groups/${moduleId}/customers/${customerId}/billing-history`, {
        params,
    });

    if (!this.isStatusOK(status)) {
        throw new Error('Aconteceu um erro inesperado');
    }

    return response.data.map(BillingHistory.make);
  }

  public async insertEvent(
    customerId: string,
    moduleId: string,
    register: UpsertBillingHistoryParams,
  ): Promise<boolean> {
    const { status, data: response } = await this.datasource.send(`company-groups/${moduleId}/customers/${customerId}/billing-history/`, register);

     if (status == 200) {
      if (!response.error) {
        return true;
      }

      throw new Error(response.message);
    }

    throw new Error('Aconteceu algum erro inesperado');
  }

  public async updateEvent(
    customerId: string,
    moduleId: string,
    id: number,
    register: UpsertBillingHistoryParams,
  ): Promise<boolean> {
    const { status, data: response } = await this.datasource.update(`company-groups/${moduleId}/customers/${customerId}/billing-history/${id}`, register);

     if (status == 200) {
      if (!response.error) {
        return true;
      }

      throw new Error(response.message);
    }
    throw new Error('Aconteceu algum erro inesperado');
  }

  public async concludeEvent(
    customerId: string,
    moduleId: string,
    id: number,
    params: ConcludeInteractionParams,
  ): Promise<boolean> {
    const { status, data: response } = await this.datasource.update(`company-groups/${moduleId}/customers/${customerId}/billing-history/conclude/${id}`, params);

     if (status == 200) {
      if (!response.error) {
        return true;
      }

      throw new Error(response.message);
    }

    throw new Error('Aconteceu algum erro inesperado');
  }
}
