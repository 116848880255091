import Module from '@/domain/models/Module';
import Repository from './base/Repository';

export default class UserGroupModuleRepository extends Repository {
  async getAll(companyGroupId: number, userGroupId: number): Promise<Module[]> {
    const url = `/company-groups/${companyGroupId}/user-groups/${userGroupId}/modules`;
    const { status, data: response } = await this.datasource.get(url);
    if (status == 200) {
      return response.data.map(Module.make);
    }
    if (status == 401) {
      throw new Error('Você não tem permissão para essa ação');
    }
    throw new Error('Aconteceu um erro inesperado');
  }
}
