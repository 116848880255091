import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import Client from '@/services/base/Client';

export default class DataSource {
  private client: AxiosInstance;

  constructor() {
    this.client = Client;
  }

  public get(url: string = '', filters?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    return this.client.get(url, filters);
  }

  public send(url: string, record: any, filters?: AxiosRequestConfig): Promise<AxiosResponse<any>> {
    return this.client.post(url, record, filters);
  }

  public update(
    url: string,
    record: any,
    config?: AxiosRequestConfig,
  ): Promise<AxiosResponse<any>> {
    return this.client.put(url, record, config);
  }

  public destroy(url: string, options = {}): Promise<AxiosResponse<any>> {
    return this.client.delete(url, options);
  }
}
