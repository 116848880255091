import qs from 'qs';
import Repository from '@/repositories/base/Repository';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import Client from '@/domain/models/Client';
import ClientSaleOrders from '@/domain/models/ClientSaleOrders';
import DataWithPaginationInfo from '@/domain/generic/DataWithPaginationInfo';
import IWebhookBaseParams from '@/repositories/parameters/IWebhookBaseParams';
import GetClientSalesOrderFilterParams from '@/views/clients/types/GetClientSalesOrderFilterParams';
import UpdateClientInfoParams from '@/views/clients/types/UpdateClientInfoParams';

export default class ClientRepository extends Repository {
  public async getAllClients(
    moduleId : number,
    companiesToConsider: number[],
    dataTableConfig: ILaravelOrdenationTableParams,
  ): Promise<DataWithPaginationInfo<Client[]>> {
    const { status, data: response } = await this.datasource.get(`company-groups/${moduleId}/customers`, {
      headers: { 'company-ids': JSON.stringify(companiesToConsider) },
      params: dataTableConfig,
      paramsSerializer: qs.stringify,
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return {
      data: response.data.map(Client.make),
      meta: response.meta,
    };
  }

  public async getClientById(
    customerId: string,
    companyGroupId: string,
    companiesToConsider: Number[],
  ): Promise<Client> {
    const { status, data: request } = await this.datasource.get(`company-groups/${companyGroupId}/customers/${customerId}`, {
      headers: { 'company-ids': JSON.stringify(companiesToConsider) },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return Client.make(request.data);
  }

  public async getClientSells(
    customerId: string,
    moduleId: string,
    dataTableConfig: GetClientSalesOrderFilterParams,
  ): Promise<{ data: ClientSaleOrders[], total: number }> {
    const { status, data: response } = await this.datasource.get(`company-groups/${moduleId}/customers/${customerId}/sales-order`, {
      params: dataTableConfig,
      paramsSerializer: qs.stringify,
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return {
      data: response.data.map(ClientSaleOrders.make),
      total: response.totalRows,
    };
  }

  public async updateClient(
    companyId: number,
    companyGroup: string,
    params: IWebhookBaseParams<UpdateClientInfoParams>,
  ): Promise<void> {
    await this.datasource.update(`company-groups/${companyGroup}/customers`, params, {
      headers: {
        'Company-ids': companyId,
      },
    });
  }
}
