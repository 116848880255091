

















































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import CreditRulesRepository from '@/mocks/creditRules';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import SelectOptions from '@/domain/interfaces/ISelectOptions';

@Component
export default class CredtRulesList extends Vue {
    public loading: boolean = true;

    public search: string = '';
    public selectedAction: string = '';

    public rules: Array<any> = [];
    public actionsOptions: SelectOptions[] = [
      { text: 'Todos', value: 'All' },
      { text: 'Ativo', value: 'Ativo' },
      { text: 'Inativo', value: 'Inativo' },
    ];
    public headers: IVDataTableHeader[] = [
      {
        text: 'Status', value: 'status', show: true, sortable: false,
      },
      {
        text: 'Ação', value: 'action', show: true, sortable: false,
      },
      {
        text: 'Empresa/Filial', value: 'company', show: true, sortable: false,
      },
      {
        text: 'Título', value: 'title', show: true, sortable: false,
      },
      {
        text: '',
        value: 'actions',
        class: 'actions-columns',
        cellClass: 'actions-columns',
        sortable: false,
      },
    ];

    public getChipColor(type: string): string {
      switch (type) {
        case 'Ativo':
          return 'success';
        case 'Inativo':
          return 'default';
        default:
          return 'primary';
      }
    }

    public async mounted(): Promise<void> {
      this.rules = await CreditRulesRepository.getAll();
      this.loading = false;
    }

    public handleSearch(search: string): void {
      this.search = search;
    }

    public handleRowClick(id: number): void {
      this.$router.push(`/regras-de-credito/${id}`);
    }
}
