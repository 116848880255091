











































































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import SelectOptions from '@/domain/interfaces/ISelectOptions';

@Component
export default class SerasaExpansionPanel extends Vue {
  public interval: string = '';
  public analisysType: string = '';
  public criterion: string = '';
  public operator: string = '';
  public value: string = '';

  public analisysTypeOptions: SelectOptions[] = [
    { value: 'cartorio', text: 'Cartórios' },
    { value: 'protesto', text: 'Protestos' },
    { value: 'concessionarias', text: 'Concessionárias em atraso' },
    { value: 'titulos', text: 'Títulos em atraso' },
  ];
  public criterionOptions: SelectOptions[] = [
    { value: 'proporcion', text: 'Proporção' },
    { value: 'qtde', text: 'Qtde títulos' },
    { value: 'valor', text: 'Valor títulos' },
    { value: 'dias', text: 'Dias em atraso' },
  ];
  public operatorOptions: SelectOptions[] = [
    { value: 'igual', text: 'Igual a' },
    { value: 'menor', text: 'Menor que' },
    { value: 'menor-igual', text: 'Menor ou igual a' },
    { value: 'maior', text: 'Maior que' },
    { value: 'maior-igual', text: 'Maior que ou igual a' },
    { value: 'diferente', text: 'Diferente de' },
  ];

  public handleFilterRuleClear(): void {
  console.log('Limpar Serasa');
  }
}
