import fixtureCreditRules from './data/creditRules.json';

const delay = (response, time) => new Promise((resolve) => {
    setTimeout(() => {
      resolve(response);
    }, time);
  });

export default {
  async getAll() {
    return delay(fixtureCreditRules, 2000);
  },
  async find(id) {
    const rule = fixtureCreditRules.find((i) => i.id == id);
    return delay(rule, 2000);
  },
};
