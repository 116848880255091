export default class FilterParameterBillingRemittanceList {
    public constructor(
      public banks: Array<string>,
      public borderos: Array<string>,
      public companies: Array<number>,
      public endDueDate: string,
      public initialDueDate: string,
      public endEmissionDate: string,
      public initialEmissionDate: string,
      public liberation: string,
      public methods: Array<string>,
      public occurrences: Array<string>,
      public endReturnDate: string,
      public initialReturnDate: string,
      public status: string,
      public types: Array<string>,
      public customerType: string,
    ) {
    }

    public static make(data: any): FilterParameterBillingRemittanceList {
      return new FilterParameterBillingRemittanceList(
        data.banks_billing_remittance ? JSON.parse(data.banks_billing_remittance) : [],
        data.borderos_billing_remittance ? JSON.parse(data.borderos_billing_remittance) : [],
        data.companies_billing_remittance ? JSON.parse(data.companies_billing_remittance) : [],
        data.due_date_final_billing_remittance ?? '',
        data.due_date_initial_billing_remittance ?? '',
        data.issue_date_final_billing_remittance ?? '',
        data.issue_date_initial_billing_remittance ?? '',
        data.liberation_billing_remittance ?? '',
        data.methods_billing_remittance ? JSON.parse(data.methods_billing_remittance) : [],
        data.occurrences_billing_remittance ? JSON.parse(data.occurrences_billing_remittance) : [],
        data.return_date_final_billing_remittance ?? '',
        data.return_date_initial_billing_remittance ?? '',
        data.status_billing_remittance ?? '',
        data.types_billing_remittance ? JSON.parse(data.types_billing_remittance) : [],
        data.customer_type_billing_remittance ?? '',
      );
    }
}
