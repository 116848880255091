









































































































































































































































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { toCurrency } from '@/utils';
import { monthsDistanceBetweenTwoDates, formateDate } from '@/utils/date';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';
import DateInputHelper from '@/helpers/DateInputHelper';
import OrderSupport from '@/views/orders/support/OrderSupport';
import InvoiceChart from '@/components/charts/InvoiceChart.vue';
import ButtonFilterMenuOptions from '@/components/buttons/ButtonFilterMenuOptions.vue';
import SalesChart from '@/views/orders/charts/SalesChart.vue';
import OrderRepository from '@/repositories/OrderRepository';
import AccountsReceivableRepository from '@/repositories/AccountsReceivableRepository';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import ClientModule from '@/stores/modules/ClientModule';
import OrderModule from '@/stores/modules/OrderModule';
import FilterParameterOrderAnalisys from '@/domain/models/filter-parameters/FilterParameterOrderAnalisys';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import HistoricalBillingsOrder from '@/domain/models/HistoricalBillingsOrder';
import CustomerInformationCreditAnalisysInputs from '@/views/orders/types/CustomerInformationCreditAnalisysInputs';
import CreditAnalisysOrderValues from '@/views/orders/types/CreditAnalisysOrderValues';
import ClientFinancialChartValues from '@/views/clients/types/ClientFinancialChartValues';
import VMenuOptions from '@/types/VMenuOptions';
import LooseObjectType from '@/types/LooseObjectType';
import RangeDate from '@/types/RangeDate';

@Component({
  components: {
    InvoiceChart,
    SalesChart,
    ButtonFilterMenuOptions,
  },
})
export default class OrderAnalisys extends Vue {
  public loadingClientInformation: boolean = false;
  public loadingHistoricalBilling: boolean = false;
  public loadingBillingTitle: boolean = false;

  public readonly orderSupport: OrderSupport = OrderSupport;
  public readonly filterParametersRepository:
    FilterParametersRepository = new FilterParametersRepository();
  private readonly accountsReceivableRepository:
    AccountsReceivableRepository = new AccountsReceivableRepository();
  private readonly orderRepository: OrderRepository = new OrderRepository();
  private readonly clientModule: ClientModule = getModule(ClientModule);
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly orderModule: OrderModule = getModule(OrderModule);

  public definedDateFilterOption: string = '';

  public defaultDateFilterOption: VMenuOptions | null = null;
  public customerInformationInput: CustomerInformationCreditAnalisysInputs = {
    companyId: null,
    companyName: '',
    risk: '',
    clientClass: '',
    orderStatus: '',
    deadline: '',
    notes: '',
  };
  public historicalBillingChartValues: HistoricalBillingsOrder = {
    months: [],
    values: [],
  };
  public invoiceChartValue: ClientFinancialChartValues = {
    expired: 0,
    toExpire: 0,
    paidOut: 0,
    totalExpired: 0,
    totalToExpire: 0,
    totalPaidOut: 0,
  };
  public creditAnalisysOrderValues: CreditAnalisysOrderValues = {
    orderValue: null,
    higherOrderValue: null,
    creditLimit: null,
    creditLimitBalance: null,
    titleBalance: null,
    titleWithHighestValue: null,
    lastPayment: null,
    firstPurchase: null,
    lastPurchase: null,
    higherDelay: null,
    averageDelay: null,
    actualDelay: null,
  }

  public risksOptions: string[] = [
    'A',
    'B',
    'C',
    'D',
    'E',
  ];
  public classOptions: string[] = [
    'A',
    'B',
    'C',
    'E',
    'S',
  ];
  public dateFilterOptions: VMenuOptions[] = [
    { value: 'date_emission', text: 'Data de Emissão' },
    { value: 'date_actual_expiration', text: 'Vencimento Real' },
  ];
  public rangeDate: Array<string> = []
  public firstBlockGroups: Array<any> = [
    { name: 'Valor do Pedido', identifier: 'orderValue' },
    { name: 'Maior Compra', identifier: 'higherOrderValue' },
    { name: 'Limite de Crédito', identifier: 'creditLimit' },
    { name: 'Saldo do Limite de Crédito', identifier: 'creditLimitBalance' },
    { name: 'Saldo de Títulos', identifier: 'titleBalance' },
    { name: 'Título com Maior Valor', identifier: 'titleWithHighestValue' },
  ];
  public secondBlockGroups: Array<any> = [
    { name: 'Último Pagamento', identifier: 'lastPayment' },
    { name: 'Primeira Compra', identifier: 'firstPurchase' },
    { name: 'Última Compra', identifier: 'lastPurchase' },
    { name: 'Maior Atraso', identifier: 'higherDelay' },
    { name: 'Média de Atraso', identifier: 'averageDelay' },
    { name: 'Atraso Atual', identifier: 'actualDelay' },
  ];

  public formattedRangeDate: Partial<RangeDate> = {};

  public get getInfoCardColor(): string {
    if (this.$vuetify.theme.dark) {
      return '#565656';
    }

    return '#F2F3F7';
  }

  public get getTextPriceValuesColor(): string {
    if (this.$vuetify.theme.dark) {
      return '';
    }

    return '#007BDD';
  }

  public get buttonHeight(): string {
    return this.$vuetify.breakpoint.smAndDown ? '40px' : '48px';
  }

  public get formattedInvoiceChartValue(): Object {
    const {
      expired,
      toExpire,
      paidOut,
      totalExpired,
      totalToExpire,
      totalPaidOut,
    } = this.invoiceChartValue;

    return {
      labels: [
        `Vencido: R$ ${toCurrency(totalExpired, {}, true)}`,
        `A vencer: R$ ${toCurrency(totalToExpire, {}, true)}`,
        `Pago: R$ ${toCurrency(totalPaidOut, {}, true)}`,
      ],
      datasets: [
        {
          label: 'Valor',
          data: [
            expired,
            toExpire,
            paidOut,
          ],
          backgroundColor: ['#E9637B', '#9994D5', '#0EB799'],
          borderWidth: 0,
        },
      ],
    };
  }

  public get formattedHistoricalBillingChartValues(): Object {
    const { months, values } = this.historicalBillingChartValues;

    return {
      labels: months,
      datasets: [
        {
          label: 'Faturamento',
          data: values,
          fill: true,
          borderWidth: 0,
          backgroundColor: '#0EB799',
          cubicInterpolationMode: 'monotone',
          pointRadius: 4,
        },
      ],
    };
  }

  public get defaultStyleCharts(): any {
    return {
      height: '250px',
    };
  }

  public async created(): Promise<void> {
    await this.getGroupFilterParameters();

    this.setOrderInformations();
    this.setCustomerValues();
    await Promise.all([
      this.getHistoricalBillingFromOrder(),
      this.getCustomerFinancialValues(),
    ]);
  }

  public async getGroupFilterParameters(): Promise<void> {
    this.$dialog.startLoading();

    try {
      const filterParameters = await this.filterParametersRepository
        .getFilterByGroup(GroupFilterParametersEnum.ORDER_ANALISYS);
      const formattedFilters = FilterParameterOrderAnalisys.make(filterParameters);

      this.applyFiltersOnActualPage(formattedFilters);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
    } finally {
      this.$dialog.stopLoading();
    }
  }

  public applyFiltersOnActualPage(filters: FilterParameterOrderAnalisys): void {
    this.rangeDate = filters.rangeDate;

    const formattedRangeDate = DateInputHelper
      .formatRangeDate(filters.rangeDate);

    if (formattedRangeDate) {
      this.formattedRangeDate = formattedRangeDate;
    }

    this.defaultDateFilterOption = FilterParameterHelper
      .getFormattedColumnObjectOfOptionsButton(this.dateFilterOptions, filters.dateToSearch);

    if (this.defaultDateFilterOption !== null) {
      this.definedDateFilterOption = this.defaultDateFilterOption.value;
    }
  }

  public async setOrderInformations(): Promise<void> {
      this.creditAnalisysOrderValues.orderValue = toCurrency(this.orderModule.orderValue, {}, true);

      this.creditAnalisysOrderValues.higherOrderValue = toCurrency(
        this.orderModule.higherOrderValue,
        {},
        true,
      );

      this.customerInformationInput.orderStatus = OrderSupport.getStatusText(
        this.orderModule.orderStatus,
      );
  }

  public async getHistoricalBillingFromOrder(): Promise<void> {
    this.loadingHistoricalBilling = true;

    try {
      const response = await this.orderRepository.getHistoricalBillingFromOrder(
        this.authenticationModule.companyIds,
        this.clientModule.client.id,
        this.authenticationModule.user.company_group_id,
        this.rangeDate,
      );

      this.historicalBillingChartValues.months = response.months;
      this.historicalBillingChartValues.values = response.values;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar o histórico financeiro!');
    } finally {
      this.loadingHistoricalBilling = false;
    }
  }

  public async getCustomerFinancialValues(): Promise<void> {
    this.loadingBillingTitle = true;

    const filterDates: LooseObjectType = {};

    filterDates[`start_${this.definedDateFilterOption}`] = this.formattedRangeDate.initial_date;
    filterDates[`end_${this.definedDateFilterOption}`] = this.formattedRangeDate.final_date;

    const tableConfigParams: ILaravelOrdenationTableParams = {
      filter: filterDates,
      sort: '',
      page: 1,
      items_per_page: 10,
    };

    try {
      const { data } = await this.accountsReceivableRepository.getAccountReceivableValues(
        this.clientModule.client.id,
        this.authenticationModule.user.company_group_id,
        tableConfigParams,
      );

      this.invoiceChartValue.expired = data.chart.expired;
      this.invoiceChartValue.toExpire = data.chart.toExpire;
      this.invoiceChartValue.paidOut = data.chart.paidOut;
      this.invoiceChartValue.totalExpired = data.chart.totalExpired;
      this.invoiceChartValue.totalToExpire = data.chart.totalToExpire;
      this.invoiceChartValue.totalPaidOut = data.chart.totalPaidOut;

      this.creditAnalisysOrderValues.lastPayment = formateDate(data.chart.lastPayment);
      this.creditAnalisysOrderValues.creditLimitBalance = toCurrency(
        data.chart.creditLimitBalance,
        {},
        true,
      );
      this.creditAnalisysOrderValues.titleBalance = toCurrency(
        data.chart.securitiesBalance,
        {},
        true,
      );
      this.creditAnalisysOrderValues.titleWithHighestValue = toCurrency(
        data.chart.titleWithHighestValue,
        {},
        true,
      );
      this.creditAnalisysOrderValues.higherDelay = data.chart.biggestDelay;
      this.creditAnalisysOrderValues.averageDelay = data.chart.averageDelay;
      this.creditAnalisysOrderValues.actualDelay = data.chart.currentDelay;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as informações financeiras');
    } finally {
      this.loadingBillingTitle = false;
    }
  }

  public setCustomerValues(): void {
    const {
      tradeName,
      risk,
      clientClass,
      deadline,
      notes,
      companyId,
      limit,
      firstPurchase,
      lastPurchase,
    } = this.clientModule.client;

    this.customerInformationInput.companyName = tradeName;
    this.customerInformationInput.risk = risk;
    this.customerInformationInput.clientClass = clientClass;
    this.customerInformationInput.deadline = deadline;
    this.customerInformationInput.notes = notes;
    this.customerInformationInput.companyId = companyId;
    this.creditAnalisysOrderValues.creditLimit = toCurrency(limit, {}, true);

    if (firstPurchase) {
      this.creditAnalisysOrderValues.firstPurchase = formateDate(
        firstPurchase,
      );
    }

    if (lastPurchase) {
      this.creditAnalisysOrderValues.lastPurchase = formateDate(
        lastPurchase,
      );
    }
  }

  public handleInput(value: string | number, type: string): void {
    this.clientModule.setClient({
      ...this.clientModule.client,
      [type]: value,
    });
  }

  public async handleSelectDateRange(): Promise<void> {
    await this.$nextTick();

    const formattedRangeDate = DateInputHelper.formatRangeDate(this.rangeDate);

    if (formattedRangeDate === null) {
      this.$notification.warn('O range de data está inválido!');

      return;
    }

    if (
      formattedRangeDate.initial_date
      && formattedRangeDate.final_date
      && monthsDistanceBetweenTwoDates(
        formattedRangeDate.initial_date,
        formattedRangeDate.final_date,
      ) >= 6
    ) {
      this.$notification.warn('A diferença em meses entre as duas datas deve ser no máximo até 6!');

      return;
    }

    this.formattedRangeDate = formattedRangeDate;
    this.getChartsValues();
  }

  public async getChartsValues(): Promise<void> {
    try {
      this.filterParametersRepository
        .setFilter(GroupFilterParametersEnum.ORDER_ANALISYS, [
          { key: 'date_range_order_analisys_list', value: JSON.stringify(this.rangeDate) },
          { key: 'date_to_search_order_analisys', value: this.definedDateFilterOption },
      ]);
    } catch (error) {
      console.error(error);
    }

    await Promise.all([
      this.getHistoricalBillingFromOrder(),
      this.getCustomerFinancialValues(),
    ]);
  }

  public handleSelectDateFilterOption(selectedDateFilter: VMenuOptions | null): void {
    let definedDateOption = '';

    if (selectedDateFilter !== null) {
      definedDateOption = selectedDateFilter.value;
    }

    this.definedDateFilterOption = definedDateOption;

    this.handleSelectDateRange();
  }
}
