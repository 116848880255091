



























































import {
  Vue,
  Component,
  Ref,
} from 'vue-property-decorator';

import IBillingRemittanceSearch from '@/domain/interfaces/IBillingRemittanceSearch';
import IBillingRemittanceFilterData from '@/domain/interfaces/IBillingRemittanceFilter';

import BillingRemittancePaymentMethod from '@/domain/models/BillingRemittancePaymentMethod';

import BillingRemittanceRepository from '@/repositories/BillingRemittanceRepository';

import BillingRemittanceFilter from './components/Filter.vue';
import BillingRemittanceList from './components/List.vue';
import { formatErrorForNotification } from './utils';

@Component({
  components: {
    BillingRemittanceFilter,
    BillingRemittanceList,
  },
})
export default class BillingRemittancePage extends Vue {
  @Ref('billing-remittance-filter') billingRemittanceFilter!: BillingRemittanceFilter;

  readonly billingRemittanceRepository:
    BillingRemittanceRepository = new BillingRemittanceRepository();

  loading: boolean = false;

  showFilter: boolean = true;
  showList: boolean = false;

  list: Array<BillingRemittancePaymentMethod> = [];
  limit: number = 0;
  approval: boolean = true;

  currentData: IBillingRemittanceFilterData = {} as IBillingRemittanceFilterData;

  get initialDate(): string {
    return this.currentData.initial_emission_date != ''
      ? this.currentData.initial_emission_date
      : this.currentData.initial_due_date;
  }

  get endDate(): string {
    return this.currentData.end_emission_date != ''
      ? this.currentData.end_emission_date
      : this.currentData.end_due_date;
  }

  get companies(): Array<number> {
    return this.currentData.companies;
  }

  getToggleIcon(): string {
    return this.showFilter ? 'mdi-chevron-up' : 'mdi-chevron-down';
  }

  getToggleTooltip(): string {
    return this.showFilter ? 'Ocultar Parâmetros' : 'Exibir Parâmetros';
  }

  handleToggle(): void {
    this.showFilter = !this.showFilter;
  }

  handleValidate(): void {
    this.billingRemittanceFilter.validate();
  }

  async handleLoad(data: IBillingRemittanceFilterData): Promise<void> {
    try {
      this.$dialog.startLoading();
      this.loading = true;
      this.showFilter = true;
      this.showList = false;

      const params: IBillingRemittanceSearch = {
        issueDate: {
          initial: data.initial_emission_date,
          final: data.end_emission_date,
        },
        dueDate: {
          initial: data.initial_due_date,
          final: data.end_due_date,
        },
        returnDate: {
          initial: data.initial_return_date,
          final: data.end_return_date,
        },
        liberated: data.liberation ? data.liberation : 'ALL',
        documentStatus: data.status ? data.status : '',
        transactionType: data.types.length ? data.types : '',
        paymentMethod: data.methods.length ? data.methods : '',
        bankCode: data.banks.length ? data.banks : '',
        clientCode: [], // hard code in old version too
        registrationNumber: data.customers.length ? data.customers : '',
        occurencyCode: data.occurrences.length ? data.occurrences : '',
        borderoNumber: data.borderos.length ? data.borderos : ['IS_NULL'],
        customer_type: data.customerType,
      };

      const { list, limit, approval } = await this.billingRemittanceRepository
        .getRemittances(params, data.companies);

      this.list = list;
      this.limit = limit;
      this.approval = approval;
      this.currentData = data;

      if (this.list.length > 0) {
        this.showFilter = false;
        this.showList = true;
      } else {
        this.$notification.warn('Nenhum resultado encontrado.');
      }
    } catch (error: any) {
      const errorMessage = formatErrorForNotification(error);
      this.$notification.error(errorMessage);
    } finally {
      this.loading = false;
      this.$dialog.stopLoading();
    }
  }

  handleReload() {
    this.handleLoad(this.currentData);
  }
}
