import Vue from 'vue';
import Vuex from 'vuex';
import StorePlugins from '@/stores/plugins/StorePlugins';
import { API_BASE } from '../../config/api';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: StorePlugins,
  state: { API_BASE },
});
