















































































































































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';

@Component
export default class FiltersExpansionPanel extends Vue {
  public advanced: boolean = false;

  public field: string = '';
  public operator: string = '';
  public expression: string = '';
  public query: string = '';

  public fieldOptions: ISelectOptions[] = [
    { value: '1', text: 'Campo 1' },
    { value: '2', text: 'Campo 2' },
    { value: '3', text: 'Campo 3' },
  ];
  public operatorOptions: ISelectOptions[] = [
    { value: 'igual', text: 'Igual a' },
    { value: 'menor', text: 'Menor que' },
    { value: 'menor-igual', text: 'Menor ou igual a' },
    { value: 'maior', text: 'Maior que' },
    { value: 'maior-igual', text: 'Maior que ou igual a' },
    { value: 'diferente', text: 'Diferente de' },
  ];

  public handleFilterRuleClear(): void {
    console.log('Limpar Filtro');
  }
}
