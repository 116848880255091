import Repository from '@/repositories/base/Repository';
import OrderItems from '@/domain/models/OrderItems';

export default class OrderItemsRepository extends Repository {
  public async listOrderItems(
      moduleId: string,
      companiesId: Number[],
      orderInnId: string,
  ): Promise<any> {
    const { status, data: response } = await this.datasource.get(`company-groups/${moduleId}/orders/order-item/${orderInnId}`, {
      headers: { 'company-ids': JSON.stringify(companiesId) },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return response.data.map(OrderItems.make);
  }
}
