
























































































import {
  Component,
  Vue,
  Prop,
} from 'vue-property-decorator';
import ChannelInfoIdentifiers from '@/components/timeline/types/ChannelInfoIdentifiers';
import HistoryTimelineActionsOptions from '@/components/timeline/types/HistoryTimelineActionsOptions';
import BillingHistory from '@/domain/models/BillingHistory';

@Component
export default class HistoryTimelineItem extends Vue {
  public channelInfoIdentifiers: ChannelInfoIdentifiers = {
    phone: { icon: 'mdi-phone', name: 'Telefone' },
    sms: { icon: 'mdi-message-processing', name: 'SMS' },
    email: { icon: 'mdi-email', name: 'E-Mail' },
    whatsapp: { icon: 'mdi-whatsapp', name: 'Whatsapp' },
    default: { icon: 'mdi-calendar', name: 'indefinido' },
  };

  public actionsOptions: HistoryTimelineActionsOptions[] = [
    {
      text: 'Concluir com sucesso',
      actionEmit: 'CONCLUDE_SUCCESS',
      icon: 'mdi-check',
    },
    {
      text: 'Concluir com falha',
      actionEmit: 'CONCLUDE_FAIL',
      icon: 'mdi-close',
    },
    {
      text: 'Editar',
      actionEmit: 'EDIT',
      icon: 'mdi-pencil',
    },
  ];

  @Prop(BillingHistory) readonly event!: BillingHistory;

  public get isReducedWidth(): boolean {
    return this.$vuetify.breakpoint.width < 700;
  }

  public get isActive(): boolean {
    return this.event.status === 'active';
  }

  public get date(): string {
    const { date, initialRangeTime, finalRangeTime } = this.event;
    return `${date} ${initialRangeTime} - ${finalRangeTime}`;
  }

  public get getStatusColor(): string {
    switch (this.event.status) {
      case 'success':
        return 'green';
      case 'failure':
        return 'red';
      default:
        return 'blue';
    }
  }

  public takeActionEmit(actionName: string, interactionId: number): void {
    if (actionName === 'CONCLUDE_SUCCESS') {
      this.$emit('click:conclude-success', interactionId, 'success');

      return;
    }

    if (actionName === 'CONCLUDE_FAIL') {
      this.$emit('click:conclude-fail', interactionId, 'failure');

      return;
    }

    this.$emit('click:edit', interactionId);
  }
}
