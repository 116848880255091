



























































































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';
import BillingRemittanceActionType from '@/domain/enums/BillingRemittanceActionType';
import BillingRemittanceAccountReceivable from '@/domain/models/BillingRemittanceAccountReceivable';
import BankConfig from '@/domain/models/BankConfig';
import BankConfigSendType from '@/domain/models/BankConfigSendType';
import SelectOptions from '@/domain/interfaces/ISelectOptions';
import GenerateResponse from '@/domain/models/BillingRemittanceGenerate';

import BillingRemittanceRepository from '@/repositories/BillingRemittanceRepository';

import { formatErrorForNotification, formatCurrency } from '../utils';
import GenerateFeedback from './GenerateFeedback.vue';

interface DataDescription {
  'description': string,
  'levelThreeDescription': string,
  'lvlDescription': string,
  'value': string,
  'ID': string,
  'cod_bar': string,
  'payment_method': null,
  'bank': {
    'A2_BANCO': string,
    'A2_AGENCIA': string,
    'A2_ZDIGAG': string,
    'A2_NUMCON': string,
    'A2_ZDIGCTA': string,
  },
}

interface GenerateData {
  companies: Array<number>,
  initialDate: string,
  endDate: string,
}

@Component({
  components: {
    GenerateFeedback,
  },
})
export default class BillingRemittanceGenerate extends Vue {
  @Prop(Boolean) open!: boolean;

  @Prop({
    type: Array as () => Array<BillingRemittanceAccountReceivable>,
  }) items!: Array<BillingRemittanceAccountReceivable>;

  @Prop({
    type: Object as () => GenerateData,
  }) data!: GenerateData;

  @Emit()
  close() {
    this.countSelected = 0;
    this.totalSelected = 0;
    this.bank = Number(undefined);
    this.sendType = Number(undefined);
    this.banksItems = [];
    this.sendTypesItems = [];
    this.dataKeys = [];
    this.dataDescriptions = [];
    return BillingRemittanceActionType.GENERATE;
  }

  @Emit()
  reload() {
    this.close();
  }

  @Watch('open')
  changedOpen() {
    if (this.open) {
      this.loadData();
    }
  }

  readonly formatCurrency = formatCurrency;
  readonly billingRemittanceRepository = new BillingRemittanceRepository();

  loading: boolean = false;
  openFeedback: boolean = false;
  titleFeedback: string = '';
  textFeedback: string = '';
  numberFeedback: string = '';
  typeFeedback: string = '';

  dataDescriptions: Array<DataDescription> = [];
  dataKeys: Array<string> = [];

  countSelected: number = 0;
  totalSelected: number = 0;

  banks: Array<BankConfig> = [];
  banksItems: Array<SelectOptions> = [];
  sendTypesItems: Array<SelectOptions> = [];

  bank = Number(undefined);
  sendType = Number(undefined);

  get customFeedback(): boolean {
    return this.$session.get('user_config_autotoggle_remittance_confirm_message');
  }

  get firstCompanyId(): number {
    return this.items[0].companyId;
  }

  formatData(): void {
    this.items.forEach((item) => {
      this.dataDescriptions.push({
        description: item.companyName,
        levelThreeDescription: `${item.prefixCode} - ${item.documentNumber} - ${item.documentType}`,
        lvlDescription: 'REMESSACOB',
        value: this.formatCurrency(item.balanceValue),
        ID: item.sequentialNumber,
        cod_bar: item.barCode,
        payment_method: null,
        bank: {
          A2_BANCO: '',
          A2_AGENCIA: '',
          A2_ZDIGAG: '',
          A2_NUMCON: '',
          A2_ZDIGCTA: '',
        },
      });

      this.dataKeys.push(
        JSON.stringify({
          TABLE_COMPANY_CODE: item.companyCode,
          ID: item.sequentialNumber,
          ID_CUSTOMER: item.idCustomer,
          E1_FILIAL: item.branchCode,
          E1_NUM: item.documentNumber,
          E1_PARCELA: item.quoteNumber,
          E1_PREFIXO: item.prefixCode,
          E1_TIPO: item.documentType,
          E1_CLIENTE: item.clientCode,
          E1_LOJA: item.storeCode,
          ID_INNCASH: item.sequentialNumber,
          COMPANY_ID: item.companyId,
        }),
      );

      this.totalSelected += item.balanceValue;
    });

    this.countSelected = this.items.length;
  }

  async loadData(): Promise<void> {
    try {
      this.loading = true;
      this.formatData();
      await this.handleLoadBanksConfig();
    } catch (error: any) {
      const errorMessage = formatErrorForNotification(error);
      this.$notification.error(errorMessage);
    } finally {
      this.loading = false;
    }
  }

  async handleLoadBanksConfig(): Promise<void> {
    try {
      this.banks = await this.billingRemittanceRepository
        .getAllBanksConfig(this.data.companies);

      this.banksItems = this.banks.map((bank) => {
        const {
          id,
          banco,
          agencia,
          conta,
          name,
        } = bank;

        return {
          value: id,
          text: `${banco} ${agencia} ${conta} - ${name}`,
        };
      });
    } catch (error: any) {
      const errorMessage = formatErrorForNotification(error);
      this.$notification.error(errorMessage);
    }
  }

  changedBank(): void {
    this.sendTypesItems = [];
    this.sendType = Number(undefined);

    const bankSelected = this.banks
      .find((bank) => bank.id === this.bank);

    this.sendTypesItems = bankSelected
      ?.send_types.map((type) => {
        const { id, description } = type;
        return { value: id, text: description };
      }) || [];
  }

  generate(): void {
    const bankSelected = this.banks
      .find((bank) => bank.id === this.bank) || {} as BankConfig;

    const selectConfig = bankSelected.send_types
      ?.find((type) => type.id === this.sendType);

    if (!selectConfig) {
      this.$notification.warn('A remessa não foi selecionada.');
      return;
    }

    if (selectConfig.send_type === 3) {
      this.generateRemittanceByAPI(selectConfig);
    } else {
      this.generateRemittanceByCnab(selectConfig);
    }
  }

  async generateRemittanceByAPI(bankConfigSendType: BankConfigSendType): Promise<void> {
    try {
      this.$dialog.startLoading();

      const data = {
        dataKeys: JSON.stringify(this.dataKeys),
        dataDescriptions: JSON.stringify(this.dataDescriptions),
        cnabConfigId: bankConfigSendType.client_bank_id,
        bankCnabId: this.bank,
        companyId: this.firstCompanyId,
        sendType: JSON.stringify({
          id: bankConfigSendType.id,
          send_type: bankConfigSendType.send_type,
          send_type_id: bankConfigSendType.send_type_id,
          cnab_id: bankConfigSendType.cnab_id,
        }),
        initialReferenceDate: this.data.initialDate,
        finalReferenceDate: this.data.endDate,
      };

      const response: GenerateResponse = await this.billingRemittanceRepository
        .generateBordero(data);

      if (response && !response.error && response.bordero_number) {
        const number = response.bordero_number as { 'bordero_number': string };
        if (this.customFeedback) {
          this.feedback(
            'Borderô gerado com sucesso!',
            'Número do borderô:',
            number.bordero_number,
            'success',
          );
        } else {
          this.$notification.success('Borderô gerado com sucesso!');
        }
      } else {
        const errorMessage = (response && response.error && response.message)
          ? response.message
          : 'Não foi possível efetuar a geração.Verifique os títulos selecionados, talvez eles já estejam baixados, estão em borderô ou não estão liberados.';

        if (this.customFeedback) {
          this.feedback('Atenção!', errorMessage, '', 'error');
        } else {
          this.$notification.warn('Não foi possível efetuar a geração.');
        }
      }
    } catch (error: any) {
      this.$dialog.stopLoading();
      const errorMessage = formatErrorForNotification(error);
      this.$notification.error(errorMessage);
    }
  }

  async generateRemittanceByCnab(bankConfigSendType: BankConfigSendType): Promise<void> {
    try {
      this.$dialog.startLoading();

      const data = {
        dataKeys: JSON.stringify(this.dataKeys),
        dataDescriptions: JSON.stringify(this.dataDescriptions),
        cnabConfigId: bankConfigSendType.client_bank_id,
        bankCnabId: this.bank,
        companyId: this.firstCompanyId,
        sendType: JSON.stringify({
          id: bankConfigSendType.id,
          send_type: bankConfigSendType.send_type,
          send_type_id: bankConfigSendType.send_type_id,
          cnab_id: bankConfigSendType.cnab_id,
        }),
        paymentDate: null,
        initialReferenceDate: this.data.initialDate,
        finalReferenceDate: this.data.endDate,
      };

      const response: GenerateResponse = await this.billingRemittanceRepository
        .generate(data);

      if ((response && response.file) || !response.error) {
        if (response.van) {
          this.$dialog.stopLoading();
          if (this.customFeedback) {
            const number = response.bordero_number as { 'bordero_number': string };
            this.feedback(
              'Arquivo gerado com sucesso!',
              'Número do Borderô:',
              number.bordero_number,
              'success',
            );
          } else {
            this.$notification.success('Arquivo enviado para VAN com sucesso!');
            this.reload();
          }
        } else {
          const blob = new Blob([response.file], { type: 'application/octet-stream' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = response.name;
          link.click();

          this.$dialog.stopLoading();
          if (this.customFeedback) {
            const number = response.bordero_number as { 'bordero_number': string };
            this.feedback(
              'Arquivo gerado com sucesso!',
              'Número do Borderô:',
              number.bordero_number,
              'success',
            );
          } else {
            this.$notification.success('Arquivo gerado com sucesso!');
            this.reload();
          }
        }
      } else {
        this.$dialog.stopLoading();
        if (this.customFeedback) {
          this.feedback('Atenção!', 'Nenhum arquivo gerado. Verifique os títulos selecionados, talvez eles já estejam baixados, estão em borderô ou não estão liberados.', '', 'error');
        } else {
          this.$notification.error('Nenhum arquivo gerado. Verifique os títulos selecionados, talvez eles já estejam baixados, estão em borderô ou não estão liberados.');
        }
      }
    } catch (error: any) {
      this.$dialog.stopLoading();
      const errorMessage = formatErrorForNotification(error);
      this.$notification.error(errorMessage);
    }
  }

  feedback(title: string, text: string, number: string, type: string): void {
    this.titleFeedback = title;
    this.textFeedback = text;
    this.numberFeedback = number;
    this.typeFeedback = type;
    this.openFeedback = true;
  }

  closeFeedback(): void {
    this.openFeedback = false;
    this.titleFeedback = '';
    this.textFeedback = '';
    this.numberFeedback = '';
    this.typeFeedback = '';
  }
}
