import { render, staticRenderFns } from "./DrawerLink.vue?vue&type=template&id=518e2b59&scoped=true&"
import script from "./DrawerLink.vue?vue&type=script&lang=ts&"
export * from "./DrawerLink.vue?vue&type=script&lang=ts&"
import style0 from "./DrawerLink.vue?vue&type=style&index=0&id=518e2b59&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "518e2b59",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VListItem,VListItemIcon,VListItemTitle})
