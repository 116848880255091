






































































































import {
  Vue,
  Component,
  Ref,
  Prop,
  Emit,
} from 'vue-property-decorator';

import { VForm } from '@/types/VForm';

import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import Bank from '@/domain/models/Bank';
import Company from '@/domain/models/Company';
import FilterData from '@/domain/interfaces/IStatementConciliationFilter';
import ISelectOptions from '@/domain/interfaces/ISelectOptions';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import FilterParameterConciliationList from '@/domain/models/filter-parameters/FilterParameterConciliationList';

import StatusType from '@/domain/enums/StatementConciliationStatusType';

import StatementConciliationRepository from '@/repositories/StatementConciliationRepository';

import { formateDate, validateDateRange } from '@/utils/date';
import { DateRangeError } from '../utils/interfaces';

interface Rules {
  valueRequerid: any,
  arrayRequerid: any,
}

@Component({})
export default class StatementConciliationFilter extends Vue {
  @Ref('filter') readonly filter!: VForm;

  @Prop({
    type: Object as () => FilterData,
    required: true,
  }) readonly data!: FilterData;

  @Emit()
  sort(value: string) {
    return value;
  }

  @Emit()
  setFilters(filters: FilterParameterConciliationList): FilterParameterConciliationList {
    return filters;
  }

  readonly formateDate = formateDate;

  readonly StatementConciliationRepository:
    StatementConciliationRepository = new StatementConciliationRepository();

  readonly filterParametersRepository:
    FilterParametersRepository = new FilterParametersRepository();

  loading: boolean = false;
  loadingBanks: boolean = false;
  companies: Array<ISelectOptions> = [];
  banks: Array<ISelectOptions> = [];

  listBanks: Array<Bank> = [];

  status: Array<ISelectOptions> = [
    { text: 'Não Processado', value: StatusType.NOT_PROCESSED },
    { text: 'Conciliado', value: StatusType.CONCILIATED },
    { text: 'Com Divergência', value: StatusType.DIVERGENCE },
    { text: 'Não Encontrado', value: StatusType.NOT_FOUND_ERP },
    { text: 'Ignorado', value: StatusType.IGNORED },
    { text: 'Banco Não Conciliado', value: StatusType.NOT_CONCILIATED },
    { text: 'ERP Não Conciliado', value: StatusType.NOT_CONCILIATED_ERP },
    { text: 'ERP Conciliado', value: StatusType.ERP_CONCILIATED },
    { text: 'Parcialmente Conciliado', value: StatusType.PARTIALLY_CONCILIATED },
    { text: 'Em Processamento', value: StatusType.PROCESSING },
  ];

  rules: Rules = {
    valueRequerid: (value: string | number) => !!value || 'Campo obrigatório!',
    arrayRequerid: (value: Array<any>) => value.length > 0 || 'Campo obrigatório!',
  };

  errorDateRange: DateRangeError = {
    movement: false,
    process: false,
    messageStart: 'Data inicial deve ser menor ou igual a data final',
    messageEnd: 'Data final deve ser menor ou igual a data inicial',
  };

  mounted(): void {
    this.loadData();
  }

  getBankNoDataText(): string {
    const { company_id: companyId } = this.data;
    const loading = this.loadingBanks;
    let text = '';

    if (loading) {
      text = 'Carregando...';
    } else if (companyId === undefined) {
      text = 'Selecione uma empresa primeiro.';
    } else {
      text = 'Nenhum banco correspondente disponível.';
    }

    return text;
  }

  changedCompanyId(): void {
    this.data.bank_id = '';
    this.banks = [];
    this.handlerLoadBanksByCompany();
    this.filter.resetValidation();
  }

  validateAllDateRanges(data: FilterData): boolean {
    const initialMovmente = data.initial_movement_date;
    const endMovement = data.end_movement_date;
    const initialProcessing = data.initial_processing_date;
    const endProcessing = data.end_processing_date;

    const areValidMovementDates = validateDateRange(initialMovmente, endMovement);
    const areValidProcessingDates = validateDateRange(initialProcessing, endProcessing);

    if (!areValidMovementDates) {
      this.errorDateRange.movement = true;
      this.$notification.error('Intevalo de emissão inválido!');
    } else {
      this.errorDateRange.movement = false;
    }

     if (!areValidProcessingDates) {
      this.errorDateRange.process = true;
      this.$notification.error('Intevalo de processamento inválido!');
    } else {
      this.errorDateRange.process = false;
    }

    if (!areValidMovementDates && !areValidProcessingDates) {
      this.$notification.error('Intevalo de emissão e de processamento inválidos!');
    }

    return areValidMovementDates && areValidProcessingDates;
  }

  validate(): boolean {
    const validatedDateRange = this.validateAllDateRanges(this.data);
    const validatedFilter = this.filter.validate();
    if (validatedDateRange) return validatedFilter;
    return false;
  }

  async loadCompanies(): Promise<void> {
    const companies = await this.StatementConciliationRepository.getCompanies();

    this.companies = companies.map((company: Company) => {
      const { name: text, id: value } = company;
      return { text, value };
    });
  }

  async loadBanksByCompany(): Promise<void> {
    this.listBanks = await this.StatementConciliationRepository
      .getBanksByCompany(this.data.company_id);

    this.banks = this.listBanks.map((bank: Bank) => {
      const {
        code,
        agency,
        account,
        name,
        idCustomer: value,
      } = bank;
      const text = `${code} ${agency} ${account} ${name}`;
      return { text, value };
    });
  }

  public async getGroupFilterParameters(): Promise<void> {
    this.loading = true;

    try {
      const filterParameters = await this.filterParametersRepository
        .getFilterByGroup(GroupFilterParametersEnum.CONCILIATION_LIST);
      const definedFilters = FilterParameterConciliationList.make(filterParameters);

      this.setFilters(definedFilters);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
    } finally {
      this.loading = false;
    }
  }

  async loadData(): Promise<void> {
    try {
      this.loading = true;
      this.$dialog.startLoading();

      await this.loadCompanies();
      await this.getGroupFilterParameters();

      if (this.data.company_id) await this.loadBanksByCompany();
    } catch (error: any) {
      console.error(error);
      this.$notification.error('Não foi possível carregar os dados necessários.');
    } finally {
      this.loading = false;
      this.$dialog.stopLoading();
    }
  }

  async handlerLoadBanksByCompany(): Promise<void> {
    try {
      this.loadingBanks = true;
      const { company_id: companyId } = this.data;

      if (companyId) await this.loadBanksByCompany();
    } catch (error: any) {
      console.error(error);
      this.$notification.error('Não foi possível carregar os bancos.');
    } finally {
      this.loadingBanks = false;
    }
  }
}
