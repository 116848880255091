












































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { pluralize, toCurrency } from '@/utils';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import VMenuOptions from '@/types/VMenuOptions';
import ButtonFilterMenuOptions from '@/components/buttons/ButtonFilterMenuOptions.vue';

@Component({
  components: {
    ButtonFilterMenuOptions,
  },
})
export default class PedingList extends Vue {
  public pendingQuantity: number = 2;

  public search: string = '';
  public consideredColumnToSearch: string = 'search';

  public headers: IVDataTableHeader[] = [
    { text: 'Tipo', value: 'type', filterable: false },
    { text: 'Número/Código', value: 'number', filterable: false },
    { text: 'Cliente', value: 'client', filterable: false },
    { text: 'Data/Hora da Inclusão', value: 'datetime', filterable: false },
    { text: 'Canal de Vendas', value: 'channel', filterable: false },
    { text: 'Valor', value: 'value', filterable: false },
  ];
  public items: Array<any> = [
    {
      type: 'Pedido',
      number: '0000123456',
      client: 'Innova Connect Sistemas Ltda',
      city: 'Criciúma',
      datetime: '15/06/2021 08:00',
      channel: 'Tele-vendas',
      value: 8320,
    },
    {
      type: 'Pedido',
      number: '0000123456',
      client: 'Innova Connect Sistemas Ltda',
      city: 'Criciúma',
      datetime: '15/06/2021 08:00',
      channel: 'Portal do Cliente',
      value: 8320,
    },
    {
      type: 'Cliente',
      number: '24779780/0001',
      client: 'Innova Connect Sistemas Ltda',
      city: 'Criciúma',
      datetime: '15/06/2021 08:00',
      channel: '-',
      value: 0,
    },
    {
      type: 'Cliente',
      number: '24779780/0001',
      client: 'Innova Connect Sistemas Ltda',
      city: 'Criciúma',
      datetime: '15/06/2021 08:00',
      channel: '-',
      value: 0,
    },
    {
      type: 'Cliente',
      number: '24779780/0001',
      client: 'Innova Connect Sistemas Ltda',
      city: 'Criciúma',
      datetime: '15/06/2021 08:00',
      channel: '-',
      value: 0,
    },
  ];

  public get colsInput(): string {
    return this.$vuetify.breakpoint.smAndDown ? '12' : '6';
  }

  public get pluralizedPending(): string {
    return pluralize(this.pendingQuantity, 'pendência', 'pendências');
  }

  public get filtersFromInput(): VMenuOptions[] {
    return this.headers.map(({ text, value }) => ({ text, value }));
  }

  public get getPendingMessage(): string {
    const aditionalMessage = this.pendingQuantity > 0 ? `Você tem ${this.pluralizedPending}.` : 'Não há pendências.';

    return `Olá, Maria! ${aditionalMessage}`;
  }

  public get getTextTitleColor() {
    if (this.$vuetify.theme.dark) {
      return 'white--text';
    }

    return 'grey--text text--darken-4';
  }

  public getChipColor(type: string): string {
    switch (type) {
      case 'Pedido':
        return '#E9637B';
      case 'Cliente':
        return '#0EB799';
      default:
        return 'primary';
    }
  }

  public getMoneyFormat(num: number): string {
    return (num > 0 ? toCurrency(num) : '-');
  }

  public handleSelectedOptionChange(selectedOption: VMenuOptions | null): void {
    let valueToConsideredColumn = 'search';

    if (selectedOption !== null) {
      valueToConsideredColumn = selectedOption.value;
    }

    this.consideredColumnToSearch = valueToConsideredColumn;
  }

  public handleSearch(term: string, column: string): void {
    if (term != '' && column != '') {
      this.headers.forEach((h) => {
        if (h.text == column) {
          h.filterable = true;
        }
      });
      this.search = term;
    }
  }
}
