import qs from 'qs';
import Repository from '@/repositories/base/Repository';
import AccountReceivableValues from '@/repositories/types/AccountReceivableReturnValues';
import AccountReceivable from '@/domain/models/AccountReceivable';
import AccountReceivableChart from '@/domain/models/AccountReceivableChart';
import DataWithPaginationInfo from '@/domain/generic/DataWithPaginationInfo';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';

export default class AccountsRepository extends Repository {
  public async getAccountReceivableValues(
    customerId: string,
    moduleId: string,
    dataTableConfig: ILaravelOrdenationTableParams,
  ): Promise<DataWithPaginationInfo<AccountReceivableValues>> {
    const { status, data: response } = await this.datasource.get(`company-groups/${moduleId}/customers/${customerId}/accounts-receivable`, {
      params: dataTableConfig,
      paramsSerializer: qs.stringify,
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return {
      data: {
        listValues: response.data.map(AccountReceivable.make),
        chart: AccountReceivableChart.make(response.chart),
      },
      meta: response.meta,
    };
  }
}
