











































































































































































































































































  import {
    Vue,
    Component,
    Prop,
    Emit,
    Watch,
    Ref,
  } from 'vue-property-decorator';
  import { VForm } from '@/types/VForm';
  import { DataOptions } from 'vuetify';

  import ISelectOptions from '@/domain/interfaces/ISelectOptions';
  import IVDataTableHeader from '@/types/IVDataTableHeader';
  import IConciliation from '@/domain/interfaces/IConciliation';
  import ISearch from '@/domain/interfaces/IStatementConciliationSearch';

  import ERPMovement from '@/domain/models/ERPMovement';
  import BankMovement from '@/domain/models/BankMovement';
  import StatementConciliation from '@/domain/models/StatementConciliation';
  import StatementConciliationMovement from '@/domain/models/StatementConciliationMovement';

  import StatementConciliationRepository from '@/repositories/StatementConciliationRepository';

  import MovementOriginType from '@/domain/enums/StatementConciliationMovementOriginType';
  import MovementType from '@/domain/enums/StatementConciliationMovementType';
  import ConciliationType from '@/domain/enums/ConciliationType';
  import StatusType from '@/domain/enums/StatementConciliationStatusType';
  import ActionType from '@/domain/enums/StatementConciliationActionType';

  import { formateDate, validateDateRange } from '@/utils/date';

  import SearchMovementAvailabilityDate from './SearchMovementAvailabilityDate.vue';

  import { formatCurrency, formatErrorForNotification } from '../utils';

  interface Rules {
    valueRequerid: any,
    arrayRequerid: any,
    dateStart: string,
    dateEnd: string,
  }

  @Component({
    components: {
      SearchMovementAvailabilityDate,
    },
  })
  export default class StatementConciliationSearchMovement extends Vue {
    @Ref('formSearch') readonly formSearch!: VForm;

    @Prop(Boolean) readonly open!: boolean;
    @Prop(String) readonly bankId!: string;
    @Prop(Number) readonly company!: number;

    @Prop({
      type: Object as () => StatementConciliation,
    }) readonly record!: StatementConciliation;

    @Emit()
    close(): ActionType {
      this.openAvailabilityDate = false;
      return ActionType.SEARCH;
    }

    @Emit()
    reload(): boolean {
      return true;
    }

    @Watch('open')
    changedOpen(open: boolean): void {
      if (open) {
        this.movementOrigin = this.getMovementOrigin();

        const {
          date: initialDate,
          date: endDate,
        } = this.movementOrigin;

        this.data = {
          initial_date: initialDate,
          end_date: endDate,
          is_conciled: 0,
          bank_id: this.bankId,
          per_page: this.dataTableOptions.itemsPerPage,
          page: this.dataTableOptions.page,
        };

        this.handlerLoadMovements();
      } else {
        this.items = [];
        this.selectedItems = [];
        this.serverItemsLength = 0;
        this.dataTableOptions.page = 1;
        this.dataTableOptions.itemsPerPage = 10;
        this.data = {} as ISearch;
        this.errorDateRange = false;
        this.formSearch.resetValidation();
      }
    }

    @Watch('dataTableOptions')
    pagination() {
      if (!this.loading && this.open) {
        this.scrollToTableTop();
        this.handlerLoadMovements();
      }
    }

    readonly StatementConciliationRepository:
      StatementConciliationRepository = new StatementConciliationRepository();

    readonly formatCurrency = formatCurrency;
    readonly formateDate = formateDate;

    movementOrigin: StatementConciliationMovement = {} as StatementConciliationMovement;
    data: ISearch = {} as ISearch;
    selectedItems: Array<ERPMovement | BankMovement> = [];
    items: Array<ERPMovement | BankMovement> = [];

    errorDateRange: boolean = false;
    loading: boolean = false;

    openAvailabilityDate: boolean = false;
    availabilityDate: string = '';

    serverItemsLength: number = 0;

    dataTableOptions: DataOptions = {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
    };

    dataTableFooterOptions: Object = {
      'items-per-page-options': [10, 50, 100],
      'disable-items-per-page': false,
      'disable-pagination': false,
    }

    conciliedOptions: Array<ISelectOptions> = [
      { text: 'Não', value: 0 },
      { text: 'Sim', value: 1 },
    ];

    typeOptions: Array<ISelectOptions> = [
      { text: 'Pagamento', value: MovementType.PAYMENT },
      { text: 'Recebimento', value: MovementType.RECEIPT },
    ];

    rules: Rules = {
      valueRequerid: (value: string | number) => !!value || 'Campo obrigatório!',
      arrayRequerid: (value: Array<any>) => value.length > 0 || 'Campo obrigatório!',
      dateStart: 'Data inicial deve ser menor ou igual a data final.',
      dateEnd: 'Data final deve ser menor ou igual a data inicial.',
    };

    headersERP:Array<IVDataTableHeader> = [
      { text: 'Data', value: 'date' },
      { text: 'Título', value: 'title' },
      { text: 'Nº Doc.', value: 'document' },
      { text: 'Tipo', value: 'type' },
      { text: 'Natureza', value: 'nature' },
      { text: 'Histórico', value: 'history' },
      { text: 'Valor', value: 'value' },
      { text: 'Ação', value: 'data-table-select' },
    ];

    headersBank:Array<IVDataTableHeader> = [
      { text: 'Data', value: 'date' },
      { text: 'Nº Doc.', value: 'document' },
      { text: 'Tipo', value: 'type' },
      { text: 'Natureza', value: 'nature' },
      { text: 'Histórico', value: 'history' },
      { text: 'Valor', value: 'value' },
      { text: 'Ação', value: 'data-table-select' },
    ];

    get document(): string {
      return this.movementOrigin.document || '';
    }

    get date(): string {
      return this.movementOrigin.date || '';
    }

    get history(): string {
      return this.movementOrigin.history || '';
    }

    get value(): number {
      const { value } = this.movementOrigin;
      return parseFloat(value);
    }

    get total(): number {
      return this.selectedItems.reduce((total, item: any) => total + parseFloat(item.value), 0);
    }

    get diff(): number {
      return this.value - this.total;
    }

    get token(): string {
      return this.$session.get('token');
    }

    get group(): number {
      return this.$session.get('company_group_id');
    }

    get originIsERP(): boolean {
      const { status } = this.record;
      return status === StatusType.NOT_CONCILIATED_ERP || status === StatusType.ERP_CONCILIATED;
    }

    getMovementOrigin(): StatementConciliationMovement {
      const origin = this.originIsERP ? MovementOriginType.ERP : MovementOriginType.BANK;
      const movement = this.record.movements.find((movement) => movement.origin === origin)
      || {} as StatementConciliationMovement;
      return movement;
    }

    getTitle(movement: ERPMovement): string {
			let title = '';

      if (movement.number) {
        title = movement.title;
      } else if (movement.document) {
        title = movement.document;
      }

			return title;
		}

    getDescriptionERPMovement(type: string, num: string) {
			let description = '';

      switch (type) {
        case 'P':
          description = num ? 'Pagamento' : 'Mov. Bancário Pagar';
          break;
        case 'R':
          description = num ? 'Recebimento' : 'Mov. Bancário Receber';
          break;
        default:
          description = '';
      }

			return description;
		}

    getDescriptionBankMovement(type: string): string {
      let description = '';

      switch (type) {
        case 'C':
          description = 'Crédito';
          break;
        case 'D':
          description = 'Débito';
          break;
        default:
          description = this.getDescriptionERPMovement(type, '');
      }

      return description;
    }

    getTitleSelectedItem(item: any): string {
      let title = '';

      if (this.originIsERP) {
        title = `
          ${item.document || item.id} - 
          ${this.formateDate(item.date)} - 
          ${this.formatCurrency(item.value)}
        `;
      } else {
        title = `
          ${item.document || item.number} - 
          ${this.formateDate(item.date)} - 
          ${this.formatCurrency(item.value)}
        `;
      }

      return title;
    }

    removeSelectedItem(item: ERPMovement | BankMovement): void {
      const index = this.selectedItems.indexOf(item);
      this.selectedItems.splice(index, 1);
    }

    validateDateRange(data: ISearch): boolean {
      const initial = data.initial_date;
      const end = data.end_date;

      const isValidDate = validateDateRange(initial, end);

      this.errorDateRange = !isValidDate;

      if (!isValidDate) this.$notification.error('Intervalo de datas inválido.');

      return isValidDate;
    }

    scrollToTableTop(): void {
      const doc = document.getElementById('search-table') || {} as HTMLElement;
      doc.scrollIntoView({ behavior: 'smooth' });
    }

    closeAvailabilityDate(): void {
      this.openAvailabilityDate = false;

      this.hideDuplicateOverflow();

      this.availabilityDate = this.record.date || '';
    }

     handlerFilter(): void {
      const validated = this.validateDateRange(this.data)
        ? this.formSearch.validate()
        : false;

      if (validated) {
        this.serverItemsLength = 0;
        this.dataTableOptions.page = 1;
        this.handlerLoadMovements();
      }
    }

    handlerSave(): void {
      if (this.selectedItems.length <= 0) {
				this.$notification.error('Nenhuma movimentação selecionada!');
        this.hideDuplicateOverflow();
        return;
			}

      const divergentDates = this.selectedItems
        .filter((item) => item.date !== this.movementOrigin.date);

      this.availabilityDate = this.record.date;

      if (divergentDates.length) {
        this.openAvailabilityDate = true;
      } else {
        this.save();
      }
    }

    async loadERPMovements(filters: ISearch): Promise<void> {
      const { data, meta } = await this.StatementConciliationRepository
        .getERPMovements(this.group, this.company, filters);

      this.serverItemsLength = meta;
      this.items = data;
    }

    async loadBankMovements(filters: ISearch): Promise<void> {
      const { data, meta } = await this.StatementConciliationRepository
        .getBankMovements(this.group, this.company, filters);

      this.serverItemsLength = meta;
      this.items = data;
    }

    async handlerLoadMovements(): Promise<void> {
      try {
        this.loading = true;
        this.dataTableFooterOptions = {
          ...this.dataTableFooterOptions,
          'disable-items-per-page': true,
          'disable-pagination': true,
        };

        const { value } = this.data;

        const filters: ISearch = {
          ...this.data,
          value: value == '0' ? undefined : value,
          per_page: this.dataTableOptions.itemsPerPage,
          page: this.dataTableOptions.page,
        };

        if (this.originIsERP) {
          await this.loadBankMovements(filters);
        } else {
          await this.loadERPMovements(filters);
        }
      } catch (error: any) {
        const message = formatErrorForNotification(error);
        this.$notification.error(message);
        this.hideDuplicateOverflow();
      } finally {
        this.dataTableFooterOptions = {
          ...this.dataTableFooterOptions,
          'disable-items-per-page': false,
          'disable-pagination': false,
        };
        this.loading = false;
      }
    }

    async save(availabilityDate?: string): Promise<void> {
      try {
        this.openAvailabilityDate = false;
        this.$dialog.startLoading();

        if (availabilityDate) {
          this.$nextTick(() => {
            const doc = document.querySelector('html') || {} as HTMLElement;
            doc.classList.add('overflow-y-hidden');
          });

          this.availabilityDate = availabilityDate;
        }
        const dispositionDate = this.availabilityDate;
        const { id: mainMovementId } = this.record;

        const conciliationType = this.originIsERP
          ? ConciliationType.ERP_WITH_MANY_BANKS
          : ConciliationType.BANK_WITH_MANY_ERPS;

        const bankMovements: Array<number> = this.originIsERP
          ? this.selectedItems.map((item) => +item.id)
          : [];

        const erpMovements: Array<string> = !this.originIsERP
          ? this.selectedItems.map((erpMovements) => `${erpMovements.id}`)
          : [];

        const conciliationData: IConciliation = {
          type: conciliationType,
          main_movement_id: mainMovementId,
          bank_movements: bankMovements,
          erp_movements: erpMovements,
          disposition_date: dispositionDate,
          bank_id: this.bankId,
        };

        const success = await this.StatementConciliationRepository
          .conciliation(this.group, this.company, conciliationData);

        if (success) {
          this.$notification.success('Conciliação salva com sucesso.');
          this.close();
          this.reload();
        }
      } catch (error: any) {
        this.$dialog.stopLoading();
        const message = formatErrorForNotification(error);
        this.$notification.error(message);
        this.hideDuplicateOverflow();
      }
    }

    hideDuplicateOverflow() {
      this.$nextTick(() => {
        const doc = document.querySelector('html') || {} as HTMLElement;
        doc.classList.add('overflow-y-hidden');
      });
    }
  }
