enum BillingRemittanceAccountReceivableSituationType {
  CANCELED = 'CANCELED',
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED',
  NOT_SENT = 'NOT_SENT',
  PAID = 'PAID',
  PAID_WITHOUT_GENERATE_DOCUMENT = 'PAID_WITHOUT_GENERATE_DOCUMENT',
  REJECTED = 'REJECTED',
  SENT = 'SENT',
  SUCCESS = 'SUCCESS',
  SUCCESS_WITH_OCCURRENCES = 'SUCCESS_WITH_OCCURRENCES',
  UNDEFINED = 'UNDEFINED',
}

export default BillingRemittanceAccountReceivableSituationType;
