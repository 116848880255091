














































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import IFieldChoises from '@/types/IFieldChoises';
import SelectOptions from '@/domain/interfaces/ISelectOptions';

@Component
export default class GeneralExpansionPanel extends Vue {
  public title: string = '';
  public description: string = '';
  public company: string = '';
  public action: string = 'auto';

  public companyOptions: SelectOptions[] = [
    { value: '1', text: 'Empresa 1' },
    { value: '2', text: 'Empresa 2' },
    { value: '3', text: 'Empresa 3' },
  ];
  public actionOptions: IFieldChoises[] = [
    { value: 'auto', label: 'Liberação Automática' },
    { value: 'manual', label: 'Liberação Manual' },
    { value: 'bloqueio', label: 'Bloquear' },
  ];
}
