import DocumentFormattation from '@/domain/formattation/DocumentFormattation';

export default class Client {
    constructor(
        public id: string,
        public status: string,
        public companyName: string,
        public tradeName: string,
        public cnpj: string,
        public address: string,
        public complement: string,
        public city: string,
        public neighbourhood: string,
        public state: string,
        public phone: string,
        public invoiceMail: string,
        public financialMail1: string,
        public financialMail2: string,
        public sector: string,
        public district: string,
        public group: string,
        public subGroup: string,
        public deadline: string,
        public limit: number|null,
        public balanceDue: number|null,
        public bank: string,
        public risk: string,
        public clientClass: string,
        public firstPurchase: string,
        public lastPurchase: string,
        public notes: string,
        public companyId: number | null,
    ) {}

    static make(data: any): Client {
        return new Client(
            data.id_customer,
            'Pendente',
            data.name,
            data.trade_name,
            data.document,
            data.address,
            data.complement,
            data.city,
            data.neighbourhood,
            data.state,
            data.phone,
            data.invoiceMail,
            data.financialMail1,
            data.financialMail2,
            data.sector,
            data.district,
            data.group,
            data.subGroup,
            data.deadline,
            data.limit,
            data.balanceDue,
            data.bank,
            data.risk,
            data.clientClass,
            data.firstPurchase,
            data.lastPurchase,
            data.notes,
            data.companyId,
        );
    }
}
