export default class Customer {
  constructor(
    public id: string,
    public name: string,
    public code: string,
    public document: string,
  ) {}

  static make(data: any): Customer {
    return new Customer(
      data.id,
      data.name,
      data.code,
      data.document,
    );
  }
}
