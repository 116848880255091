import Repository from '@/repositories/base/Repository';
import CustomerOrdersListTableParameters from '@/views/orders/types/CustomerOrdersListTableParameters';
import Orders from '@/domain/models/Orders';
import DataTableWithLength from '@/domain/generic/DataTableWithLength';
import CreditAnalisysOrderValues from '@/domain/models/CreditAnalisysOrderValues';
import HistoricalBillingsOrder from '@/domain/models/HistoricalBillingsOrder';

export default class OrderRepository extends Repository {
  public async listAll(
    companiesId: Number[],
    moduleId: string,
    filters: CustomerOrdersListTableParameters,
  ): Promise<DataTableWithLength<Orders[]>> {
    const { status, data: response } = await this.datasource.get(`company-groups/${moduleId}/orders`, {
      params: filters,
      headers: { 'company-ids': JSON.stringify(companiesId) },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return {
      data: response.data.map(Orders.make),
      total: response.totalRows,
    };
  }

  public async getCreditAnalisysOrderFinancialValuesByOrderInnId(
    companiesId: Number[],
    moduleId: string,
    orderInnId: string,
  ): Promise<CreditAnalisysOrderValues> {
    const { status, data: response } = await this.datasource.get(`company-groups/${moduleId}/orders/credit-analisys/financial/${orderInnId}`, {
      headers: { 'company-ids': JSON.stringify(companiesId) },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return CreditAnalisysOrderValues.make(response.data);
  }

  public async getHistoricalBillingFromOrder(
    companiesId: Number[],
    customerId: string,
    moduleId: string,
    dateRange: string[],
  ): Promise<HistoricalBillingsOrder> {
    const { status, data: response } = await this.datasource.get(`company-groups/${moduleId}/orders/credit-analisys/${customerId}/historical-billing`, {
      params: { dateRange },
      headers: { 'company-ids': JSON.stringify(companiesId) },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }

    return HistoricalBillingsOrder.make(response.data);
  }

  public async releaseOrderOnERP(
    moduleId: string,
    companiesId: Number[],
    orderId: string,
    customerId: string,
  ): Promise<any> {
    const { status, data: response } = await this.datasource.send(`company-groups/${moduleId}/orders/release/${customerId}`, {
      orderId,
    }, {
      headers: { 'company-ids': JSON.stringify(companiesId) },
    });

    if (!this.isStatusOK(status)) {
      throw new Error('Aconteceu um erro inesperado');
    }
  }
}
