














































































































































































import {
  Vue,
  Component,
  Prop,
  Emit,
  Watch,
} from 'vue-property-decorator';

import BillingRemittancePaymentMethod from '@/domain/models/BillingRemittancePaymentMethod';
import BillingRemittanceAccountReceivable from '@/domain/models/BillingRemittanceAccountReceivable';
import SituationType from '@/domain/enums/BillingRemittanceAccountReceivableSituationType';
import ActionType from '@/domain/enums/BillingRemittanceActionType';

import IVDataTableHeader from '@/types/IVDataTableHeader';

import { formateDate } from '@/utils/date';

import BillingRemittanceListStatus from './ListStatus.vue';
import BillingRemittanceListAction from './ListAction.vue';
import BillingRemittanceDetails from './Details.vue';
import BillingRemittanceOccurrences from './Occurrences.vue';
import BillingRemittanceRemove from './Remove.vue';
import BillingRemittanceGenerate from './Generate.vue';
import { formatCurrency } from '../utils';

interface ActionData {
  option: string,
  item: BillingRemittanceAccountReceivable,
}

interface GenerateData {
  companies: Array<number>,
  initialDate: string,
  endDate: string,
}

@Component({
  components: {
    BillingRemittanceListStatus,
    BillingRemittanceListAction,
    BillingRemittanceDetails,
    BillingRemittanceOccurrences,
    BillingRemittanceRemove,
    BillingRemittanceGenerate,
  },
})
export default class BillingRemittanceList extends Vue {
  @Prop({
    type: Array as () => Array<BillingRemittancePaymentMethod>,
  }) list!: Array<BillingRemittancePaymentMethod>;

  @Prop({
    type: Object as () => GenerateData,
  }) data!: GenerateData;

  @Prop({
    type: Boolean,
  }) approval!: boolean;

  @Prop({
    type: Number,
  }) limit!: number;

  @Emit()
  reload(): boolean {
    this.selectedItems = [];
    this.selectedItemsTotal = 0;
    this.selectedItemsTotalValue = 0;
    return true;
  }

  @Watch('list')
  changedItems() {
    this.selectedItems = [];
    this.selectedItemsTotal = 0;
    this.selectedItemsTotalValue = 0;
  }
  readonly formatCurrency = formatCurrency;
  readonly formateDate = formateDate;

  loading: boolean = false;

  selectedItems: Array<Array<BillingRemittanceAccountReceivable>> = [];
  selectedItemsTotal: number = 0;
  selectedItemsTotalValue: number = 0;

  openDetails: boolean = false;
  openOccurrences: boolean = false;
  openRemove: boolean = false;
  openGenerate: boolean = false;

  itemDetails: BillingRemittanceAccountReceivable = {} as BillingRemittanceAccountReceivable;
  itemOccurrences: BillingRemittanceAccountReceivable = {} as BillingRemittanceAccountReceivable;
  itemRemove: BillingRemittanceAccountReceivable = {} as BillingRemittanceAccountReceivable;
  itemsGenerate: Array<BillingRemittanceAccountReceivable> = [];

  headers: Array<IVDataTableHeader> = [
    { text: '', value: 'accountReceivable' },
    { text: '', value: 'balanceValue' },
    { text: 'Vencimento', value: 'fluxDate' },
    { text: 'Portador', value: 'bankCode' },
    { text: 'Status', value: 'bankingSituationDescription' },
    { text: 'Ação', value: 'action' },
  ];

  get totalItems(): number {
    return this.list
      .reduce((prev, method) => prev + method.items.length, 0);
  }

  get totalItemsValue(): number {
    return this.list
      .reduce((prev, method) => prev + method.items
        .reduce((itemPrev, item) => itemPrev + item.balanceValue, 0), 0);
  }

  totalItemsSelectedValueOfMethod(methodIndex: number): number {
    return this.selectedItems[methodIndex]
      ?.reduce((prev, item) => prev + item.balanceValue, 0) || 0;
  }

  handleSelectAllToggle(
    props: { items: Array<BillingRemittanceAccountReceivable>, value: boolean },
    methodIndex: number,
  ): void {
    if (props.value) {
      this.selectedItems[methodIndex] = [];
      this.changedSelectedItems();

      this.list[methodIndex].items.forEach((item) => {
        if (!this.isItemDisabled(item)) {
          this.selectedItems[methodIndex].push(item);
          this.changedSelectedItems();
        }
      });
    } else {
      this.selectedItems[methodIndex] = [];
      this.changedSelectedItems();
    }
  }

  handleSelectItemToggle(
    props: { item: BillingRemittanceAccountReceivable, value: boolean },
    methodIndex: number,
  ): void {
    if (props.value) {
      if (this.selectedItems[methodIndex]?.length) {
        this.selectedItems[methodIndex].push(props.item);
      } else {
        this.selectedItems[methodIndex] = [props.item];
      }
    } else {
      const index = this.selectedItems[methodIndex]
        ?.findIndex((item) => item.keyId == props.item.keyId);

      if (index > -1) this.selectedItems[methodIndex].splice(index, 1);
    }
  }

  isItemDisabled(item: BillingRemittanceAccountReceivable): boolean {
    const DISABLED_SITUATIONS = [
      SituationType.SUCCESS,
      SituationType.SUCCESS_WITH_OCCURRENCES,
      SituationType.SENT,
      SituationType.CANCELED,
      SituationType.PAID,
    ];

    if (
      DISABLED_SITUATIONS.indexOf(item.bankingSituationDescription) > -1
      || parseFloat(item.borderoNumber) > 0
    ) {
      return true;
    }

    if (Number.isNaN(Number(item.borderoNumber)) && item.borderoNumber !== '') {
      return true;
    }

    if (this.approval && item.isLiberated === false) {
      return true;
    }

    if (item.documentType == 'RA' || item.documentType == 'NCC') {
      return true;
    }

    if (this.selectedItemsTotal == this.limit && this.limit > 0) {
      this.$notification.warn(`Limite máximo alcançado!\n\n A quantidade estabelecida pelo ERP é de ${this.limit} itens por remessa.`);
      return true;
    }

    return false;
  }

  isItemSelected(item: BillingRemittanceAccountReceivable, methodIndex: number): boolean {
    return this.selectedItems[methodIndex]?.includes(item) || false;
  }

  changedSelectedItems(): void {
    this.selectedItemsTotal = this.selectedItems
      ?.reduce((prev, items) => prev + items.length, 0) || 0;

    this.selectedItemsTotalValue = this.selectedItems
      .reduce((prev, method) => prev + method
        .reduce((itemPrev, item) => itemPrev + item.balanceValue, 0), 0);
  }

  handleAction(data: ActionData): void {
    const { option, item } = data;

    switch (option) {
      case ActionType.DETAILS: {
        this.openDetails = true;
        this.itemDetails = item;
        break;
      }
      case ActionType.OCCURRENCES: {
        this.openOccurrences = true;
        this.itemOccurrences = item;
        break;
      }
      case ActionType.PAYMENT_SLIP: {
        window.open(item.documentUrl, '_blank');
        break;
      }
      case ActionType.REMOVE: {
        if (!item.cnab_remi_generated_id) {
          this.$notification.warn('Título em borderô pelo ERP, não pode ser removido pelo InnCash.');
          break;
        }

        this.openRemove = true;
        this.itemRemove = item;
        break;
      }
      default: {
        this.$notification.warn('Ação inválida!');
      }
    }
  }

  handleClose(action: ActionType): void {
    switch (action) {
      case ActionType.DETAILS: {
        this.openDetails = false;
        this.itemDetails = {} as BillingRemittanceAccountReceivable;
        break;
      }
      case ActionType.GENERATE: {
        this.openGenerate = false;
        this.itemsGenerate = [];
        break;
      }
      case ActionType.OCCURRENCES: {
        this.openOccurrences = false;
        this.itemOccurrences = {} as BillingRemittanceAccountReceivable;
        break;
      }
      case ActionType.REMOVE: {
        this.openRemove = false;
        this.itemRemove = {} as BillingRemittanceAccountReceivable;
        break;
      }
      default: {
        this.openDetails = false;
        this.openGenerate = false;
        this.openOccurrences = false;
        this.openRemove = false;
        this.itemDetails = {} as BillingRemittanceAccountReceivable;
        this.itemOccurrences = {} as BillingRemittanceAccountReceivable;
        this.itemRemove = {} as BillingRemittanceAccountReceivable;
        this.itemsGenerate = [];
      }
    }
  }

  handleOpenGenerate(): void {
    if (this.limit > 0 && this.selectedItemsTotal > this.limit) {
      this.$notification
        .warn(`Limite máximo alcançado!\n\n A quantidade estabelecida pelo ERP é de ${this.limit} itens por remessa.`);
    } else if (this.selectedItemsTotal < 1) {
      this.$notification.warn('Nenhum título selecionado!');
    } else {
      this.selectedItems.forEach((items) => {
        this.itemsGenerate.push(...items);
      });

      const code = this.itemsGenerate[0].bankCode;

      const everySameCode = this.itemsGenerate
        .every((item) => item.bankCode === code);

      if (everySameCode) {
        this.openGenerate = true;
      } else {
        this.itemsGenerate = [];
        this.$notification.warn('Títulos de diferentes portadores selecionados!');
      }
    }
  }
}
