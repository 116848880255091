<template>
	<v-row>
		<v-col id="params">
			<breadcrumbs page-title="Envio de Extrato Bancário"/>
			<vue-dropzone ref="myVueDropzone" id="conciliationupload" :options="dropzoneOptions" :use-custom-slot=true vdropzone-duplicate-file=true v-on:vdropzone-error="onError" v-on:vdropzone-success="onSuccess">
				<div class="dropzone-custom-content">
					<img src="/assets/img/1031498-128.png" />
					<br>
					<br>
					<h3 class="dropzone-custom-title">Solte os arquivos aqui</h3>
					<div class="subtitle">...ou clique para selecionar um arquivo.</div>
				</div>
			</vue-dropzone>
		</v-col>
	</v-row>
</template>

<script>
	import vue2Dropzone from 'vue2-dropzone';
	import { API_BASE } from '@config/api'	
 
    export default {
		components: {
			vueDropzone: vue2Dropzone,
		},		
        data () {
            return {
				dropzoneOptions: {
					url: ()=> API_BASE + 'conciliation/fileimport/new',
					headers: {'token': this.$session.get('token')},
					dictRemoveFile: 'Remover',
					dictInvalidFileType: 'Tipo do arquivo inválido!',
					autoProcessQueue: true,
					duplicateCheck: true, 
					acceptedFiles: '.RET,.TXT,.CSV',
					parallelUploads: 1
				}
            }
        },
        mounted: function () {

        },
        methods: {
			onError: function(file, message, xhr){
				showNotification("Atenção!", message.message, 'danger');
			},
			onSuccess: async function(file, message, xhr){
				if (!message.error) {
					await this.$http.get(API_BASE+'conciliation/action/proccessOneFile/'+message.message, {headers: {'token': this.$session.get('token')}}).then(
						(result) =>{
							showNotification('Atenção','Arquivo processado com sucesso!', 'success');
							unblockPage();
						},
						(error) => {
							showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
							unblockPage();
						}
					)
				}
			}
        }
    }
</script>