






































































































import {
 Component,
 Vue,
 Prop,
 Watch,
} from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import { toCurrency } from '@/utils';
import TableUtils from '@/utils/tableUtils';
import DynamicColumnMenu from '@/components/tables/DynamicColumnMenu.vue';
import ButtonFilterMenuOptions from '@/components/buttons/ButtonFilterMenuOptions.vue';
import ClientRepository from '@/repositories/ClientRepository';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';
import Client from '@/domain/models/Client';
import FilterParameterClientList from '@/domain/models/filter-parameters/FilterParameterClientList';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import OnRequestTableOptions from '@/types/OnRequestTableOptions';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import ColumnToShow from '@/types/ColumnToShow';
import VMenuOptions from '@/types/VMenuOptions';
import LooseObjectType from '@/types/LooseObjectType';

@Component({
  components: {
    DynamicColumnMenu,
    ButtonFilterMenuOptions,
  },
})
export default class ClientList extends Vue {
  public loading: boolean = false;

  public search: string = '';
  public consideredColumnToSearch: string = 'search';

  public totalItems: number = 0;

  public dataTableOptions: DataOptions = {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
  }
  public footerOptions: Object = {
    'items-per-page-options': [5, 10, 15, 50],
  }
  public headerKeysObject: LooseObjectType<string> = {
    status: 'data_table_client_list_show_column_status',
    companyName: 'data_table_client_list_show_column_company_name',
    tradeName: 'data_table_client_list_show_column_trade_name',
    cnpj: 'data_table_client_list_show_column_cnpj',
    address: 'data_table_client_list_show_column_address',
    city: 'data_table_client_list_show_column_city',
    neighbourhood: 'data_table_client_list_show_column_neighbourhood',
    state: 'data_table_client_list_show_column_state',
    country: 'data_table_client_list_show_column_country',
  }
  public defaultColumnToSearch: VMenuOptions | null = null;

  public clients: Client[] = [];
  public filtersFromInput: VMenuOptions[] = [];
  public formattedHeadersToDynamicColumn: ColumnToShow[] = [];
  public availableHeaders: IVDataTableHeader[] = [
    {
      text: 'Situação',
      value: 'status',
      show: true,
    },
    {
      text: 'Razão Social',
      value: 'companyName',
      show: true,
    },
    {
      text: 'Nome Fantasia',
      value: 'tradeName',
      show: true,
    },
    {
      text: 'CNPJ',
      value: 'cnpj',
      show: true,
    },
    {
      text: 'Endereço',
      value: 'address',
      show: true,
    },
    {
      text: 'Cidade',
      value: 'city',
      show: true,
    },
    {
      text: 'Bairro',
      value: 'neighbourhood',
      show: false,
    },
    {
      text: 'Estado',
      value: 'state',
      show: false,
    },
    {
      text: 'País',
      value: 'country',
      show: false,
    },
  ];
  public readonly filterParametersRepository:
    FilterParametersRepository = new FilterParametersRepository();

  @Prop(Object) readonly searchOptions!: Object;

  @Watch('dataTableOptions')
  onSortDirectionDataTableChange() {
    this.getAllClients();
  }

  public get headers(): IVDataTableHeader[] {
    return this.availableHeaders
      .filter((h) => h.show)
      .concat({
        text: '',
        value: 'actions',
        class: 'action-columns',
        cellClass: 'action-columns',
        sortable: false,
      })
      .concat({
        text: 'column',
        value: 'column',
        class: 'dynamic-columns',
        cellClass: 'dynamic-columns',
        sortable: false,
      });
  }

  public get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  public get companyIds(): number[] {
    return this.$store.getters['authentication/companyIds'];
  }

  public get companyGrouId(): number {
    return this.$store.state.authentication.user.company_group_id;
  }

  public get colsInput(): string {
    return this.$vuetify.breakpoint.smAndDown ? '12' : '6';
  }

  public async created(): Promise<void> {
    this.filtersFromInput = this.availableHeaders.map(({ text, value }) => ({ text, value }));
    await this.getGroupFilterParameters();
    await this.getAllClients();
  }

  public handleColumnChange(newColumnPresets: IVDataTableHeader[]): void {
    this.availableHeaders = newColumnPresets;
  }

  public handleClickRow(selectedClient: Client): void {
    this.$router.push(`/clientes/${selectedClient.id}`);
  }

  public async getAllClients(onRequestTableOptions: OnRequestTableOptions = {}): Promise<void> {
    if (this.loading) {
      return;
    }

    if (onRequestTableOptions.resetToFirst) {
      this.dataTableOptions.page = 1;
    }

    this.loading = true;

    const { sortDesc, sortBy } = this.dataTableOptions;
    const tableConfigParams: ILaravelOrdenationTableParams = {
      filter: { [this.consideredColumnToSearch]: this.search },
      sort: TableUtils.tableOrdenationFormatterToLaravel(sortDesc[0], sortBy[0]),
      page: this.dataTableOptions.page,
      items_per_page: this.dataTableOptions.itemsPerPage,
    };

    try {
      this.filterParametersRepository.setFilter(GroupFilterParametersEnum.CLIENT_LIST, [
        { key: 'column_to_search_client_list', value: this.consideredColumnToSearch },
        { key: 'sort_table_client_list', value: tableConfigParams.sort },
        { key: 'rows_per_page_client_list', value: this.dataTableOptions.itemsPerPage },
        { key: 'actual_page_client_list', value: tableConfigParams.page },
      ]);

      const { data, meta } = await new ClientRepository()
        .getAllClients(this.companyGrouId, this.companyIds, tableConfigParams);

      this.clients = data;
      this.totalItems = meta.total;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os clientes!');
    } finally {
      this.loading = false;
    }
  }

  public async getGroupFilterParameters(): Promise<void> {
    this.loading = true;

    try {
      const filterParameters = await this.filterParametersRepository
        .getFilterByGroup(GroupFilterParametersEnum.CLIENT_LIST);
      const formattedFilters = FilterParameterClientList.make(filterParameters);

      this.applyFiltersOnActualPage(formattedFilters);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
    } finally {
      this.loading = false;
    }
  }

  public applyFiltersOnActualPage(filters: FilterParameterClientList): void {
    this.dataTableOptions.sortBy = filters.tableSort;
    this.dataTableOptions.sortDesc = filters.tableDirection;
    this.dataTableOptions.itemsPerPage = filters.rowsPerPage;
    this.dataTableOptions.page = filters.actualPage;

    this.consideredColumnToSearch = filters.columnToSearch;
    this.defaultColumnToSearch = FilterParameterHelper
      .getFormattedColumnObjectOfOptionsButton(this.filtersFromInput, filters.columnToSearch);

    this.availableHeaders = FilterParameterHelper
      .defineColumnsToShowOnSpecificHeader(this.availableHeaders, filters.columnsToShow);
  }

  public getChipColor(status: string | undefined): string {
    switch (status) {
      case 'Pendente':
        return '#E9637B';
      case 'OK':
        return '#0EB799';
      default:
        return 'primary';
    }
  }

  public getMoneyFormat(num: number): boolean {
    return num > 0 ? toCurrency(num) : '-';
  }

  public handleSearch(search: string): void {
    this.search = search;

    this.getAllClients({
      resetToFirst: true,
    });
  }

  public handleSelectedOptionChange(selectedOption: VMenuOptions | null): void {
    let definedColumnToSearch = 'search';

    if (selectedOption !== null) {
      definedColumnToSearch = selectedOption.value;
    }

    this.consideredColumnToSearch = definedColumnToSearch;

    if (!this.search) {
      return;
    }

    this.getAllClients({
      resetToFirst: true,
    });
  }
}
