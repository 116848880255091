<template>
  <v-row>
    <v-col>
      <v-card
        class="elevation-0"
        v-bind="$attrs"
      >
        <v-card-title>
          <v-container fluid>
            <slot name="title">
            </slot>
          </v-container>
        </v-card-title>

        <v-divider
          v-show="dividerOn"
          class="mt-0"
        />

        <v-card-text
          class="mt-n5 pa-6"
          v-show="!showList"
        >
          <slot name="body">
          </slot>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'PageCard',
  props: {
    title: String,
    dividerOn: Boolean,
    showList: Boolean,
  },
};
</script>

<style>
</style>
