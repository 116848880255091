import DocumentFormattation from '@/domain/formattation/DocumentFormattation';
import moment from 'moment';

export default class AccountReceivable {
    constructor(
        public id: string,
        public number: string,
        public order: string,
        public emissionDate: string,
        public issueDate: string,
        public document: string,
        public officialDueDate: string,
        public delayDays: number,
        public value: number,
        public balance: number,
        public status: string,
    ) {}

    static make(data: any): AccountReceivable {
        return new AccountReceivable(
            data.id,
            data.number,
            data.order_number,
            moment(data.emission_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            moment(data.due_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            DocumentFormattation.chooseTheValidDocumentFormattation(data.document),
            moment(data.real_due_date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            data.days_late,
            parseFloat(data.value),
            data.balance,
            data.status,
        );
    }
}
