<script>
import { Doughnut, mixins } from 'vue-chartjs';
import { toCurrency, toNFormat, arraySum } from '@/utils';

const { reactiveProp } = mixins;

export default {
  name: 'InvoiceChart',
  extends: Doughnut,
  mixins: [reactiveProp],
  data() {
    return {
      fontLabelColor: '',
      fontInfoColor: '',
      definedOptions: {},
    };
  },
  created() {
    this.setColors();
  },
  watch: {
    isDarkMode() {
      this.resetChart();
    },
  },
  computed: {
    isDarkMode() {
      return this.$vuetify.theme.dark;
    },
  },
  mounted() {
    const vueContext = this;

    this.addPlugin({
      id: 'doughnut-center-title',
      beforeDraw(chart) {
        var { width } = chart.chart;
        var { height } = chart.chart;
        var { ctx } = chart.chart;

        ctx.restore();
        var fontSize = (height / 114).toFixed(2);
        ctx.font = `${fontSize}em sans-serif`;
        ctx.textBaseline = 'middle';

        var text = toNFormat(arraySum(chart.data.datasets[0].data));
        var textX = Math.round((width - ctx.measureText(text).width) / 2);
        var textY = height / 2 - chart.legend.height / 2;

        ctx.fillStyle = vueContext.fontInfoColor;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    });

    this.definedOptions = this.generateOptions();

    this.renderChart(this.chartData, this.definedOptions);
  },
  methods: {
    generateOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        cutoutPercentage: 80,
        tooltips: {
          callbacks: {
            label(t, d) {
              const xLabel = d.datasets[t.datasetIndex].label;
              const yLabel = toCurrency(d.datasets[t.datasetIndex].data[t.index]);
              return `${xLabel}: ${yLabel}`;
            },
          },
        },
        legend: {
          position: 'bottom',
          labels: {
            fontColor: this.fontLabelColor,
            usePointStyle: true,
          },
        },
        title: {
          display: true,
          text: '8.5K',
          position: 'center',
        },
      };
    },
    setColors() {
      let fontLabelColor = '#7D7D7E';
      let fontInfoColor = '#007BDD';

      if (this.isDarkMode) {
        fontLabelColor = '#FFFFFF';
        fontInfoColor = '#FFFFFF';
      }

      this.fontLabelColor = fontLabelColor;
      this.fontInfoColor = fontInfoColor;
    },
    resetChart() {
      // eslint-disable-next-line no-underscore-dangle
      const chartOptions = this.$data._chart;

      this.setColors();
      this.definedOptions = this.generateOptions();

      chartOptions.options = this.definedOptions;
      chartOptions.update();
    },
  },
};
</script>
