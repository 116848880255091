<template>
	<v-row>
		<v-col>
			<breadcrumbs page-title="Configurações Bancárias"/>
			<page-card dividerOn>
				<template #title>
					<v-row>
						<v-col cols="12" lg="4" md="4" sm="12" xs="12">
							<v-select 
								v-model="search.filterType"
								filled
								hide-details
								label="Filtrar por status"
								:items="filterStatusItems"/>
						</v-col>
						<v-col cols="12" lg="6" md="5" sm="12" xs="12">
							<v-text-field
								filled
								hide-details
								prepend-inner-icon="mdi-magnify"
								label="Pesquisar"
								v-model="search.filterBank"/>
						</v-col>
						<v-col cols="12" lg="2" md="3" sm="12" xs="12">
							<v-btn
								@click="add"
								class="font-weight-bold"
								x-large
								block
								depressed
								color="primary"
							>
								ADICIONAR
							</v-btn>
						</v-col>
					</v-row>
				</template>
				<template #body>
					<div v-if="length">
						<table class="table table-striped m-table">
							<thead>
							<tr>
								<th>Tipo</th>
								<th class="text-center">Banco</th>
								<th class="text-center">Agência</th>
								<th class="text-center">Conta</th>
								<th class="text-center">Código Convênio</th>
								<th class="text-center">Apelido CEF</th>
								<th class="text-center">Conta Conectividade</th>
								<th class="text-right"></th>
							</tr>
							</thead>
							<tbody>
								<tr v-for="(bankconfig, index) in listData" :key="index">
									<td style="vertical-align: middle;">
										<v-chip
												v-show='bankconfig.tipo == "PAGAR"'
												dense
												small
												color="error"
												>
											Pagar
											</v-chip>
											<v-chip
												v-show='bankconfig.tipo == "RECEBER"'
												dense
												small
												color="green"
												text-color="white">
												Receber
											</v-chip>
											<v-chip
												v-show='bankconfig.tipo != "PAGAR" && bankconfig.tipo != "RECEBER"'
												dense
												small
												color="green"
												text-color="white">
												{{ bankconfig.tipo }}
											</v-chip>
									</td>
									<td class="text-center" style="vertical-align: middle;">{{ bankconfig.banco }}</td>
									<td class="text-center" style="vertical-align: middle;">{{ bankconfig.agencia }}</td>
									<td class="text-center" style="vertical-align: middle;">{{ bankconfig.conta }}</td>
									<td class="text-center" style="vertical-align: middle;">{{ bankconfig.convenio }}</td>
									<td class="text-center" style="vertical-align: middle;">{{ bankconfig.apelido_cef }}</td>
									<td class="text-center" style="vertical-align: middle;">{{ bankconfig.numcon_conectividade }}</td>
									<td class="text-right">
										<button-tooltip
											fab
											icon
											small
											buttonIcon="mdi-pencil"
											tooltipText="Editar"
											@click="edit(bankconfig)"
										/>
										<button-tooltip
											fab
											icon
											small
											buttonIcon="mdi-delete"
											tooltipText="Remover"
											@click="remove(bankconfig)"
										/>
									</td>
								</tr> 
							</tbody>
						</table>
					</div>
					<div v-else class="m-section">
						<div class="m-section__content">
							<h4 class="m-text--bold text-center mt-5" v-show="!loadError">Nenhum dado encontrado</h4>
							<h4 class="m-text--bold text-center mt-5" v-show="loadError">Algo saiu errado</h4>
						</div>
					</div>
				</template>
			</page-card>
		</v-col>
	</v-row>
</template>
<script>
	import _ from 'lodash';
	import {API_BASE} from '@config/api';

	export default {
		data () {
			return {
				bankconfigs: [],
				loadError: false,
				length: 0,
				search: {
						filterBank: '',
						filterType: ''
				},
				filterStatusItems: [
					{
						text: "Todos os tipos",
						value: "",
					},
					{
						text: "Pagar",
						value: "PAGAR",
					},
					{
						text: "Receber",
						value: "RECEBER",
					},
				]
			}
		},

		computed: {
			listData() {
				let dataRet = this.bankconfigs;

				if (this.search.filterType) {
					dataRet = _.filter(dataRet, data => data.tipo == this.search.filterType)
				}

				if (this.search.filterBank.toLowerCase()) {
        dataRet = _.filter(
          dataRet,
          list => list.banco.toLowerCase().indexOf(this.search.filterBank.toLowerCase()) >= 0
          || list.agencia.toString().toLowerCase().indexOf(this.search.filterBank.toLowerCase()) >= 0 
					|| list.conta.toString().toLowerCase().indexOf(this.search.filterBank.toLowerCase()) >= 0
					|| list.convenio.toString().toLowerCase().indexOf(this.search.filterBank.toLowerCase()) >= 0
					|| list.numcon_conectividade.toString().toLowerCase().indexOf(this.search.filterBank.toLowerCase()) >= 0
        );
      }

				return dataRet
			}
		},

		mounted: function () {
				this.loadConfigs();
		},

		methods: {
			loadConfigs: function(){
				blockPage('Carregando os dados...');

				this.$http.get(API_BASE + 'bankconfig/listAll', {headers: {'token': this.$session.get('token')}}).then(
					(result) => {
						this.bankconfigs = result.body.data
						this.length = this.bankconfigs.length
						if (result.body.data.length == 0) this.bankconfigs = false;

						unblockPage();
					},
					(error) => {
						if(error.status != 404){
							this.loadError = true;
							showNotification('Atenção','Opa! Parece que algo saiu errado, tente recarregar a página', 'danger');
						}
						unblockPage();
					}
				)
			},
			remove: function(bankconfig) {
				Swal.fire({
					title: 'Atenção!',
					text: "Deseja realmente excluir?",
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#34bfa3',
					cancelButtonColor: '#5867dd',
					cancelButtonText: 'Cancelar',
					confirmButtonText: 'Sim',
					focusCancel: true
					}).then((result) => {
						if (result.value) {
							this.$http.delete(this.$store.state.API_BASE+'bankconfig/delete/'+bankconfig.id , {headers: {'token': this.$session.get('token')}}).then(
								(result) => {
									showNotification('Sucesso','Registro removido com sucesso', 'success');
									this.bankconfigs.splice(this.bankconfigs.indexOf(bankconfig), 1);
									this.length--;
								},
								(error) => {
										showNotification('Atenção','Erro ao tentar excluir', 'danger');
								}
							);
						}
				})
			},
			add() {
				this.$router.push('/bankconfig/new');
			},	
			edit(bankconfig) {
				const params = {
					edit: bankconfig.id,
				};
				this.$router.push({name: 'savebankconfig',params});
			},
		},
			
	}
</script>