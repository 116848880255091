























































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ClientModule from '@/stores/modules/ClientModule';
import InputRulesHelper from '@/helpers/InputRulesHelper';

@Component
export default class ClientContacts extends Vue {
  public clientModule: ClientModule = getModule(ClientModule);

  public readonly inputRules: InputRulesHelper = new InputRulesHelper();

  public get clientContactsValidated() {
    return this.clientModule.clientContactsValidated;
  }

  public set clientContactsValidated(isValidated: boolean) {
    this.clientModule.setClientInfoValidated({
      isValidated,
      clientScreen: 'clientContactsValidated',
    });
  }

  public handleInput(value: string | number, type: string): void {
    this.clientModule.setClient({
      ...this.clientModule.client,
      [type]: value,
    });
  }
}
