





























































































import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import LoginLayout from '@/components/auth/LoginLayout.vue';

@Component({
  components: {
    LoginLayout,
  },
})

export default class LoginForm extends Vue {
  public user: any = {
    email: '',
    password: '',
  };
  public rules: any = {
    required: [(value: string) => !!value || 'Campo obrigatório.'],
    email: [(v: string) => /.+@.+\..+/.test(v) || 'Email inválido.'],
  };
  public design: any = {
    loading: false,
  };
  public valid: boolean = false;
  public primaryColor: string = '#1C4FA5';

  @Prop(String) readonly logoUrl!: string;
  @Prop(Boolean) readonly loggingIn!: boolean;
  @Prop(Boolean) readonly disableSignup!: boolean;
  @Prop(Boolean) readonly disableForgot!: boolean;
  @Prop(Boolean) readonly textSecondary!: boolean;

  @Watch('loggingIn')
    isLoggingIn(value:any): void {
      this.design.loading = value;
    }
  public signup(): any {
    this.$emit('signup');
  }
  public login(): any {
    const { email, password } = this.user;
    this.$emit('login', { email, password });
  }
  public forgotPassword(): any {
    this.$emit('forgotPassword');
  }
}
