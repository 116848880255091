
















import {
  Vue,
  Component,
  Prop,
} from 'vue-property-decorator';
import StatementConciliationStatusType from '@/domain/enums/StatementConciliationStatusType';

interface StatusObject {
  code: number,
  text: string,
  icon: string,
  color: string,
}

@Component({})
export default class StatementConciliationListStatus extends Vue {
  @Prop(Number) readonly statusCode!: number;

  statusList: Array<StatusObject> = [
    {
      code: StatementConciliationStatusType.NOT_PROCESSED,
      text: 'Registro bancário não processado.',
      icon: 'mdi-history',
      color: 'orange',
    },
    {
      code: StatementConciliationStatusType.CONCILIATED,
      text: 'Registro bancário conciliado com sucesso.',
      icon: 'mdi-check-circle-outline',
      color: 'green',
    },
    {
      code: StatementConciliationStatusType.DIVERGENCE,
      text: 'Registro bancário com divergências no ERP.',
      icon: 'mdi-alert-circle-outline',
      color: 'orange',
    },
    {
      code: StatementConciliationStatusType.NOT_FOUND_ERP,
      text: 'Registro bancário não encontrado no ERP.',
      icon: 'mdi-close-circle-outline',
      color: 'red',
    },
    {
      code: StatementConciliationStatusType.IGNORED,
      text: 'Registro bancário ignorado.',
      icon: 'mdi-eye-off-outline',
      color: 'grey',
    },
    {
      code: StatementConciliationStatusType.NOT_CONCILIATED,
      text: 'Registro bancário não conciliado.',
      icon: 'mdi-minus-circle-outline',
      color: 'red',
    },
    {
      code: StatementConciliationStatusType.NOT_CONCILIATED_ERP,
      text: 'Registro ERP não conciliado.',
      icon: 'mdi-cancel',
      color: 'red',
    },
    {
      code: StatementConciliationStatusType.ERP_CONCILIATED,
      text: 'Registro ERP conciliado.',
      icon: 'mdi-checkbox-marked-circle-outline',
      color: 'green',
    },
    {
      code: StatementConciliationStatusType.PARTIALLY_CONCILIATED,
      text: 'Registro parcialmente conciliado.',
      icon: 'mdi-check-circle-outline',
      color: 'orange',
    },
    {
      code: StatementConciliationStatusType.PROCESSING,
      text: 'Registro em processamento.',
      icon: 'mdi-progress-alert',
      color: 'orange',
    },
    {
      code: StatementConciliationStatusType.PENDING_WITH_ERP,
      text: 'Integração do registro pendente.',
      icon: 'mdi-progress-alert',
      color: 'orange',
    },
    {
      code: StatementConciliationStatusType.FAILED_WITH_ERP,
      text: 'Falha na integração do registro.',
      icon: 'mdi-alert-circle-outline',
      color: 'red',
    },
  ];

  get status(): StatusObject {
    return this.statusList.find((item) => (item.code == this.statusCode)) || {} as StatusObject;
  }
}
