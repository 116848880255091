






































































































































































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import SelectOptions from '@/domain/interfaces/ISelectOptions';

@Component
export default class ErpExpansionPanel extends Vue {
  public interval: string = '';
  public analisysType: string = '';
  public criterion: string = '';
  public operator: string = '';
  public value: string = '';
  public query: string = '';

  public advanced: boolean = false;

  public analisysTypeOptions: SelectOptions[] = [
    { value: 'titulos', text: 'Títulos em atraso' },
    { value: 'pedidos', text: 'Pedidos em aberto' },
    { value: 'faturamentos', text: 'Faturamentos já realizados' },
    { value: 'media', text: 'Média de atraso pagamento' },
    { value: 'valor', text: 'Valor do pedido' },
  ];
  public criterionOptions: SelectOptions[] = [
    { value: 'proporcion', text: 'Proporção' },
    { value: 'qtde', text: 'Qtde títulos/pedidos' },
    { value: 'valor', text: 'Valor títulos/pedidos' },
    { value: 'dias', text: 'Dias em atraso' },
  ];
  public operatorOptions: SelectOptions[] = [
    { value: 'igual', text: 'Igual a' },
    { value: 'menor', text: 'Menor que' },
    { value: 'menor-igual', text: 'Menor ou igual a' },
    { value: 'maior', text: 'Maior que' },
    { value: 'maior-igual', text: 'Maior que ou igual a' },
    { value: 'diferente', text: 'Diferente de' },
  ];

  public handleFilterRuleClear(): void {
    console.log('Limpar ERP');
  }
}
