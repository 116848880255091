<template>
  <v-dialog class="new-event" v-model="model" max-width="800px">
    <v-form @submit.prevent="handleSubmit" ref="form" lazy-validation>
      <v-card>
        <v-card-title>{{ title }}</v-card-title>
        <v-card-text class="pt-4">
          <inn-date-field v-model="date" required should-be-greater label="Data"></inn-date-field>
          <v-row>
            <v-col cols="6">
              <inn-time-field
                ref="startTime"
                v-model="startTime"
                label="Hora Início"
                required
                :should-be-greater-date="date"
              ></inn-time-field>
            </v-col>
            <v-col cols="6">
              <inn-time-field
                ref="finishTime"
                v-model="finishTime"
                label="Hora Final"
                required
                :should-be-greater-date="date"
                :should-be-greater-time="startTime"
              ></inn-time-field>
            </v-col>
          </v-row>
          <inn-select
            v-model="clientSelected"
            :items="clientsOptions"
            placeholder="Selecione um cliente"
            label="Empresa/Filial"
            required
          >
          </inn-select>
          <inn-select
            v-model="channelSelected"
            :items="channelOptions"
            placeholder="Selecione um canal"
            label="Canal para cobrança"
            required
          >
          </inn-select>
          <inn-text-field
            v-model="contactName"
            placeholder="Ex: Maria da Silva"
            label="Nome de contato"
            required
          >
          </inn-text-field>
          <inn-textarea v-model="details" label="Observações" required></inn-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <inn-btn color="red darken-1" text @click="handleClose">Cancelar</inn-btn>
          <inn-btn type="submit" color="blue darken-1" text>Salvar</inn-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import ClientInteraction from '@/domain/models/ClientInteraction';

export default {
  name: 'EventDialog',
  props: {
    event: ClientInteraction,
    value: Boolean,
  },
  watch: {
    value(newVal) {
      // When opened, check if is necessary to fill inputs
      if (newVal) this.maybeFillInputs();

      this.model = newVal;
    },
    model(newModel) {
      this.$emit('input', newModel);
    },
  },
  computed: {
    title() {
      return this.event ? 'Editar evento' : 'Novo agendamento';
    },
  },
  data() {
    return {
      id: '',
      model: this.value,
      date: this.$moment().format('YYYY-MM-DD'),
      startTime: '',
      finishTime: '',
      clientSelected: '',
      clientsOptions: [
        { value: 'Cliente 1', label: 'Cliente 1' },
        { value: 'Cliente 2', label: 'Cliente 2' },
        { value: 'Cliente 3', label: 'Cliente 3' },
        { value: 'Innova Connect', label: 'Innova Connect' },
      ],
      channelSelected: '',
      channelOptions: [
        { value: 'Telefone', label: 'Telefone' },
        { value: 'SMS', label: 'SMS' },
        { value: 'E-mail', label: 'E-mail' },
        { value: 'WhatsApp', label: 'WhatsApp' },
      ],
      contactName: '',
      details: '',
      required: [(v) => !!v || 'Esse campo é obrigatório'],
    };
  },
  methods: {
    handleSubmit() {
      if (this.$refs.form.validate()) {
        const day = this.$moment(this.date).format('YYYY-MM-DD');
        const startDate = `${day}T${this.startTime}:00`;
        const finishDate = `${day}T${this.finishTime}:59`;

        const newEvent = new ClientInteraction(
          this.id,
          startDate,
          finishDate,
          this.details,
          this.channelSelected,
          this.clientSelected,
          this.contactName,
          this.event ? this.event.status : '',
        );

        this.$emit('save-event', newEvent);
        this.reset();
        this.model = false;
      }
    },
    maybeFillInputs() {
      this.id = this.event?.id ?? crypto.randomUUID();
      if (this.event) {
        this.date = this.$moment(this.event.start).format('YYYY-MM-DD');
        this.startTime = this.$moment(this.event.start).format('HH:mm');
        this.finishTime = this.$moment(this.event.end).format('HH:mm');
        this.clientSelected = this.event.clientName;
        this.contactName = this.event.contactName;
        this.channelSelected = this.event.channel;
        this.details = this.event.details;
      }
    },
    handleClose() {
      this.$emit('cancel');
      this.reset();
      this.model = false;
    },
    reset() {
      this.id = '';
      this.date = this.$moment().format('YYYY-MM-DD');
      this.startTime = '';
      this.finishTime = '';
      this.clientSelected = '';
      this.channelSelected = '';
      this.contactName = '';
      this.details = '';
    },
  },
};
</script>

<style></style>
