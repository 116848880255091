






























































































































































































import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { DataOptions } from 'vuetify';
import { pluralize, toCurrency } from '@/utils';
import TableUtils from '@/utils/tableUtils';
import AccountsReceivableRepository from '@/repositories/AccountsReceivableRepository';
import ILaravelOrdenationTableParams from '@/repositories/parameters/ILaravelOrdenationTableParams';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import AccountReceivable from '@/domain/models/AccountReceivable';
import FilterParameterAccountReceivableClient from '@/domain/models/filter-parameters/FilterParameterAccountReceivableClient';
import InvoiceChart from '@/components/charts/InvoiceChart.vue';
import ButtonFilterMenuOptions from '@/components/buttons/ButtonFilterMenuOptions.vue';
import DynamicColumnMenu from '@/components/tables/DynamicColumnMenu.vue';
import FilterParameterHelper from '@/helpers/FilterParameterHelper';
import TotalCalculator from '@/views/clients/components/TotalCalculator.vue';
import ClientFinancialChartValues from '@/views/clients/types/ClientFinancialChartValues';
import LooseObjectType from '@/types/LooseObjectType';
import VMenuOptions from '@/types/VMenuOptions';
import OnRequestTableOptions from '@/types/OnRequestTableOptions';
import IVDataTableHeader from '@/types/IVDataTableHeader';

@Component({
  components: {
    InvoiceChart,
    TotalCalculator,
    ButtonFilterMenuOptions,
    DynamicColumnMenu,
  },
})
export default class ClientFinancial extends Vue {
  public totalItems: number = 0;

  public dataTableOptions: DataOptions = {
      page: 1,
      itemsPerPage: 10,
      sortBy: [],
      sortDesc: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
  }
  public footerOptions = {
    'items-per-page-options': [5, 10, 15, 50],
  }

  public loadingTable: boolean = false;
  public groupFiltersHasBeenSet: boolean = false;

  public averageTime: number = 0;
  public averageDelay: number = 0;

  public search: string = '';
  public definedDateFilterOption: string = '';

  public filtersToConsider: LooseObjectType = {};
  public headerKeysObject: LooseObjectType<string> = {
    number: 'data_table_account_receivable_show_column_number',
    order: 'data_table_account_receivable_show_column_order',
    emissionDate: 'data_table_account_receivable_show_column_emission_date',
    issueDate: 'data_table_account_receivable_show_column_issue_date',
    document: 'data_table_account_receivable_show_column_document',
    officialDueDate: 'data_table_account_receivable_show_column_official_due_date',
    delayDays: 'data_table_account_receivable_show_column_delay_days',
    value: 'data_table_account_receivable_show_column_value',
    balance: 'data_table_account_receivable_show_column_balance',
    status: 'data_table_account_receivable_show_column_status',
  }
  public defaultDateFilterOption: VMenuOptions | null = null;
  public chartValues: ClientFinancialChartValues = {
    expired: 0,
    toExpire: 0,
    paidOut: 0,
    totalExpired: 0,
    totalToExpire: 0,
    totalPaidOut: 0,
  };

  public readonly filterParametersRepository:
    FilterParametersRepository = new FilterParametersRepository();

  public rangeDate: Array<string> = []
  public items: AccountReceivable[] = [];
  public selected: AccountReceivable[] = [];
  public dateFilterOptions: VMenuOptions[] = [
    { value: 'date_emission', text: 'Data de Emissão' },
    { value: 'date_actual_expiration', text: 'Vencimento Real' },
  ];
  public availableHeaders: IVDataTableHeader[] = [
    { text: 'Número', value: 'number', show: true },
    { text: 'Pedido', value: 'order', show: true },
    { text: 'Dt. Emissão', value: 'emissionDate', show: true },
    { text: 'Vencimento', value: 'issueDate', show: true },
    { text: 'CNPJ', value: 'document', show: true },
    { text: 'Vencimento Real', value: 'officialDueDate', show: true },
    {
      text:
      'Dias Atraso',
      value: 'delayDays',
      show: true,
      sortable: false,
    },
    { text: 'Valor', value: 'value', show: true },
    { text: 'Saldo', value: 'balance', show: true },
    {
      text: 'Status',
      value: 'status',
      show: true,
      sortable: false,
    },
  ];

  @Watch('dataTableOptions')
  onSortDirectionDataTableChange() {
    if (!this.groupFiltersHasBeenSet) {
      return;
    }

    this.getFinancialValues();
  }

  public get headers(): IVDataTableHeader[] {
    return this.availableHeaders
      .filter((h) => h.show)
      .concat({
        text: '',
        value: 'barCode',
        class: 'barcode-columns',
        cellClass: 'barcode-columns',
        sortable: false,
      })
      .concat({
        text: '',
        value: 'actions',
        class: 'actions-columns',
        cellClass: 'actions-columns',
        sortable: false,
      });
  }

  public get customerId(): string {
    return this.$route.params.id;
  }

  public get companyGroupId(): string {
    return this.$store.state.authentication.user.company_group_id;
  }

  public get getInfoCardColor(): string {
    if (this.$vuetify.theme.dark) {
      return '#565656';
    }

    return '#F2F3F7';
  }

  public get getTextPriceValuesColor(): string {
    if (this.$vuetify.theme.dark) {
      return '';
    }

    return '#007BDD';
  }

  public get formattedChartValues(): Object {
    const {
      expired,
      toExpire,
      paidOut,
      totalExpired,
      totalToExpire,
      totalPaidOut,
    } = this.chartValues;

    return {
      labels: [`Vencido: R$ ${totalExpired}`, `A vencer: R$ ${totalToExpire}`, `Pago: R$ ${totalPaidOut}`],
      datasets: [
        {
          label: 'Valor',
          data: [
            expired,
            toExpire,
            paidOut,
          ],
          backgroundColor: ['#E9637B', '#9994D5', '#0EB799'],
          borderWidth: 0,
        },
      ],
    };
  }

  public async created(): Promise<void> {
    await this.getGroupFilterParameters();
    await this.getFinancialValues({ resetToFirst: false });
  }

  public async getGroupFilterParameters(): Promise<void> {
    this.loadingTable = true;

    try {
      const filterParameters = await this.filterParametersRepository
        .getFilterByGroup(GroupFilterParametersEnum.ACCOUNT_RECEIVABLE_CLIENT_LIST);
      const formattedFilters = FilterParameterAccountReceivableClient.make(filterParameters);

      this.applyFiltersOnActualPage(formattedFilters);
      this.groupFiltersHasBeenSet = true;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os filtros dessa tela!');
    } finally {
      this.loadingTable = false;
    }
  }

  public applyFiltersOnActualPage(filters: FilterParameterAccountReceivableClient): void {
    this.dataTableOptions.sortBy = filters.tableSort;
    this.dataTableOptions.sortDesc = filters.tableDirection;
    this.dataTableOptions.itemsPerPage = filters.rowsPerPage;
    this.dataTableOptions.page = filters.actualPage;

    let definedOption = FilterParameterHelper
      .getFormattedColumnObjectOfOptionsButton(this.dateFilterOptions, filters.dateTypeToSearch);

    if (definedOption === null) {
      [definedOption] = this.dateFilterOptions;
    }

    this.defaultDateFilterOption = definedOption;
    this.definedDateFilterOption = definedOption.value;
    this.rangeDate = filters.dateToSearch;

    this.availableHeaders = FilterParameterHelper
      .defineColumnsToShowOnSpecificHeader(this.availableHeaders, filters.columnsToShow);
  }

  public async getFinancialValues(
    onRequestTableOptions: OnRequestTableOptions = {},
  ): Promise<void> {
    if (this.loadingTable) {
      return;
    }

    this.loadingTable = true;

    if (onRequestTableOptions.resetToFirst) {
      this.dataTableOptions.page = 1;
    }

    const { sortDesc, sortBy } = this.dataTableOptions;
    const tableConfigParams: ILaravelOrdenationTableParams = {
      filter: this.filtersToConsider,
      sort: TableUtils.tableOrdenationFormatterToLaravel(sortDesc[0], sortBy[0]),
      page: this.dataTableOptions.page,
      items_per_page: this.dataTableOptions.itemsPerPage,
    };

    try {
      this.filterParametersRepository
        .setFilter(GroupFilterParametersEnum.ACCOUNT_RECEIVABLE_CLIENT_LIST, [
          { key: 'date_type_to_search_account_receivable_list', value: this.definedDateFilterOption },
          { key: 'date_to_search_account_receivable_list', value: JSON.stringify(this.rangeDate) },
          { key: 'actual_page_account_receivable_list', value: this.dataTableOptions.page },
          { key: 'rows_per_page_account_receivable_list', value: this.dataTableOptions.itemsPerPage },
          { key: 'sort_table_account_receivable_list', value: tableConfigParams.sort },
        ]);

      const { data, meta } = await new AccountsReceivableRepository().getAccountReceivableValues(
        this.customerId,
        this.companyGroupId,
        tableConfigParams,
      );

      this.items = data.listValues;

      this.chartValues = { ...data.chart };
      this.averageTime = data.chart.averageMaturity;
      this.averageDelay = data.chart.averageDelay;
      this.totalItems = meta.total;
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar as informações financeiras');
    } finally {
      this.loadingTable = false;
    }
  }

  public defineFiltersToFinancialTable(): void {
    const definedFilters: LooseObjectType = {};

    if (this.search) {
      definedFilters.number = this.search;
    }

    if (this.rangeDate[0]) {
       ({ 0: definedFilters[`start_${this.definedDateFilterOption}`] } = this.rangeDate);
    }

    if (this.rangeDate[1]) {
      ({ 1: definedFilters[`end_${this.definedDateFilterOption}`] } = this.rangeDate);
    }

    this.filtersToConsider = definedFilters;
    this.getFinancialValues({ resetToFirst: true });
  }

  public handleSearch(search: string): void {
    this.search = search;

    this.defineFiltersToFinancialTable();
  }

  public async handleSelectDateRange(): Promise<void> {
    await this.$nextTick();

    this.defineFiltersToFinancialTable();
  }

  public handleSelectDateFilterOption(selectedDateFilter: VMenuOptions | null): void {
    let definedDateOption = '';

    if (selectedDateFilter !== null) {
      definedDateOption = selectedDateFilter.value;
    }

    this.definedDateFilterOption = definedDateOption;

    this.defineFiltersToFinancialTable();
  }

  public getAverageTime(): boolean | string {
    return pluralize(this.averageTime, 'dia', 'dias');
  }

  public getAverageDelay(): boolean | string {
    return pluralize(this.averageDelay, 'dia', 'dias');
  }

  public handleColumnChange(newColumnPresets: IVDataTableHeader[]): void {
    this.availableHeaders = newColumnPresets;
  }

  public getChipColor(status: string): string {
    switch (status) {
      case 'A Vencer':
        return 'purple';
      case 'Vencido':
        return 'red';
      case 'Pago':
        return 'success';
      default:
        return 'green';
    }
  }

  public getMoneyFormat(num: number): string {
    return num > 0 ? toCurrency(num) : '-';
  }
}
