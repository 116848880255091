import VuexPersistence from 'vuex-persist';

const authorizationStorage = new VuexPersistence({
    key: 'authentication',
    storage: window.sessionStorage,
    modules: ['authentication'],
});

export default [
    authorizationStorage.plugin,
];
