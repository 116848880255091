





































































import {
 Component,
 Vue,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import ClientModule from '@/stores/modules/ClientModule';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import NavigationTabs from '@/components/navigation/tabs/NavigationTabs.vue';
import ClientRepository from '@/repositories/ClientRepository';
import NavigationTabsType from '@/types/NavigationTabsType';
import UpdateClientInfoParams from '@/views/clients/types/UpdateClientInfoParams';
import IWebhookBaseParams from '@/repositories/parameters/IWebhookBaseParams';

@Component({
  components: {
    NavigationTabs,
  },
})
export default class Client extends Vue {
  public selectedTab: number = 0;

  public tabs: NavigationTabsType[] = [
      { name: 'Informações gerais', path: `/clientes/${this.clientId}/` },
      { name: 'Contatos', path: `/clientes/${this.clientId}/contatos` },
      {
        name: 'Inf. Complementares',
        path: `/clientes/${this.clientId}/informacoes-complementares`,
      },
      {
        name: 'Ficha financeira',
        path: `/clientes/${this.clientId}/ficha-financeira`,
      },
      {
        name: 'Vendas',
        path: `/clientes/${this.clientId}/vendas`,
      },
      {
        name: 'Histórico de Cobrança',
        path: `/clientes/${this.clientId}/historico-de-cobranca`,
      },
  ];
  public readonly clientModule: ClientModule = getModule(ClientModule);
  public readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  public readonly clientRepository: ClientRepository = new ClientRepository();

  get companyIds(): Number[] {
    return this.authenticationModule.companyIds;
  }

  get companyGroupId(): string {
    return this.authenticationModule.user.company_group_id;
  }

  public get clientId(): string {
    return this.$route.params.id;
  }

  created() {
    this.selectedTab = this.defineTabByRoute(this.$route.name);
    this.getClientById();
  }

  public defineTabByRoute(routeName: string | null | undefined): number {
    switch (routeName) {
      case 'ClientGeneral':
        return 0;
      case 'ClientContacts':
        return 1;
      case 'ClientInformation':
        return 2;
      case 'ClientFinancial':
        return 3;
      case 'ClientSells':
        return 4;
      case 'ClientHistory':
        return 5;
      default:
        return 0;
    }
  }

  public async getClientById(): Promise<void> {
    try {
      const client = await this.clientRepository
        .getClientById(this.clientId, this.companyGroupId, this.companyIds);

      this.clientModule.setClient(client);
      this.clientModule.setClientLoading(false);
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar o cliente selecionado!');
    }
  }

  public async saveChanges(): Promise<void> {
    if (
      !this.clientModule.clientGeneralValidated
      || !this.clientModule.clientContactsValidated
      || !this.clientModule.clientInformationValidated
    ) {
      this.$notification.warn('Há valores inválidos em um dos campos de dados do cliente!');

      return;
    }

    if (this.clientModule.client.companyId === null) {
      this.$notification.error('Este cliente não possui o id da empresa que pertence!');

      return;
    }

    this.$dialog.startLoading();

    const clientParameters: IWebhookBaseParams<UpdateClientInfoParams> = {
        id: this.clientId,
        company_id: this.clientModule.client.companyId,
        data: {
          phone: this.clientModule.client.phone,
          invoiceMail: this.clientModule.client.invoiceMail,
          financialMail1: this.clientModule.client.financialMail1,
          financialMail2: this.clientModule.client.financialMail2,
          group: this.clientModule.client.group,
          subGroup: this.clientModule.client.subGroup,
          deadline: this.clientModule.client.deadline,
          limit: this.clientModule.client.limit,
          risk: this.clientModule.client.risk,
          clientClass: this.clientModule.client.clientClass,
          notes: this.clientModule.client.notes,
        },
    };

    try {
      await this.clientRepository.updateClient(
        this.clientModule.client.companyId,
        this.companyGroupId,
        clientParameters,
      );

      this.$notification.success('Alterações do cliente salvas com sucesso!');
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar o cliente selecionado!');
    } finally {
      this.$dialog.stopLoading();
    }
  }
}
