import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import store from '@/stores';
import IOrderStatusOptions from '@/views/orders/interfaces/IOrderStatusOptions';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'order',
  store,
})
export default class OrderModule extends VuexModule {
  public orderValue: number = 0;
  public higherOrderValue: number = 0;
  public orderStatus: keyof IOrderStatusOptions = 1;

  @Mutation
  public setOrderValue(value: number): void {
    this.orderValue = value;
  }

  @Mutation
  public setHigherOrderValue(value: number): void {
    this.higherOrderValue = value;
  }

  @Mutation
  public setOrderStatus(status: keyof IOrderStatusOptions): void {
    this.orderStatus = status;
  }
}
