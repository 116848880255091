import DdaFiles from '@oldinncash/views/ddaupload/ddafiles.vue';
import ApiBankingConfigList from '@/views/apibankingconfig/List.vue';
import ApiBankingConfigSave from '@/views/apibankingconfig/Save.vue';
import BillingRemittance from '@/views/billingRemittance/List.vue';
import AcquittanceReason from '@/views/acquittanceReason/List.vue';
import AcquittanceReasonSave from '@/views/acquittanceReason/Save.vue';
import ApiBankingCredential from '@/views/apiBankingCredential/List.vue';
import ApiBankingCredentialSave from '@/views/apiBankingCredential/Save.vue';

import PendingList from '@/views/pending_list/PendingList.vue';

import ClientList from '@/views/clients/ClientList.vue';
import Client from '@/views/clients/Client.vue';
import ClientGeneral from '@/views/clients/client/ClientGeneral.vue';
import ClientContacts from '@/views/clients/client/ClientContacts.vue';
import ClientInformation from '@/views/clients/client/ClientInformation.vue';
import ClientFinancial from '@/views/clients/client/ClientFinancial.vue';
import ClientSells from '@/views/clients/client/ClientSells.vue';
import ClientHistory from '@/views/clients/client/ClientHistory.vue';

import OrderList from '@/views/orders/OrderList.vue';
import Order from '@/views/orders/Order.vue';
import OrderAnalisys from '@/views/orders/order/OrderAnalisys.vue';
import OrderItems from '@/views/orders/order/OrderItems.vue';
import OrderHistory from '@/views/orders/order/OrderHistory.vue';

import CreditRulesList from '@/views/credit_rules/CreditRulesList.vue';
import CreditRuleCreate from '@/views/credit_rules/CreditRuleCreate.vue';
import CreditRuleEdit from '@/views/credit_rules/CreditRuleEdit.vue';

import Modules from '@/views/modules/Modules.vue';

import Schedule from '@/views/schedule/Schedule.vue';

import UserGroupList from '@/views/user_groups/UserGroupList.vue';
import UserGroupEdit from '@/views/user_groups/UserGroupEdit.vue';
import UserGroupCreate from '@/views/user_groups/UserGroupCreate.vue';
import UserGroupModules from '@/views/user_groups/UserGroupModules.vue';

import PayrollUpload from '@/views/payroll/PayrollUpload.vue';
import PayrollHistory from '@/views/payroll/PayrollHistory.vue';

import UserList from '@/views/users/UserList.vue';
import UserCreate from '@/views/users/UserCreate.vue';
import UserEdit from '@/views/users/UserEdit.vue';

import BillingMethodList from '@/views/billingMethod/BillingMethodList.vue';
import BillingMethodSave from '@/views/billingMethod/BillingMethodSave.vue';

import StatementConciliationList from '@/views/statementConciliation/StatementConciliationList.vue';
import StatementConciliationHistory from '@/views/statementConciliation/StatementConciliationHistory.vue';

import BillingRemittanceList from '@/views/billingRemittance/BillingRemittanceList.vue';
import OrderCustomer from '@/views/orders/order/OrderCustomer.vue';
import OrderCustomerSells from '@/views/orders/order/OrderCustomerSells.vue';

export default [
  {
    path: '/apibankingconfig',
    name: 'APIConfigurations',
    component: ApiBankingConfigList,
  },
  {
    path: '/apibankingconfig/:service/type/:type/company/:company',
    name: 'APIConfigurationsEdit',
    component: ApiBankingConfigSave,
  },
  {
    path: '/apibankingconfig/:service/',
    name: 'APIConfigurationsSave',
    component: ApiBankingConfigSave,
  },
  {
    path: '/billingremittance/',
    name: 'BillingRemittance',
    component: BillingRemittance,
  },
  {
    path: '/ddafiles',
    name: 'DDAFiles',
    component: DdaFiles,
  },
  {
    path: '/pendencias',
    component: PendingList,
    name: 'PendingList',
  },
  {
    path: '/clientes',
    component: ClientList,
    name: 'ClientList',
  },
  {
    path: '/clientes/:id',
    component: Client,
    children: [
      {
        path: '',
        component: ClientGeneral,
        name: 'ClientGeneral',
      },
      {
        path: 'contatos',
        component: ClientContacts,
        name: 'ClientContacts',
      },
      {
        path: 'informacoes-complementares',
        component: ClientInformation,
        name: 'ClientInformation',
      },
      {
        path: 'ficha-financeira',
        component: ClientFinancial,
        name: 'ClientFinancial',
      },
      {
        path: 'vendas',
        component: ClientSells,
        name: 'ClientSells',
      },
      {
        path: 'historico-de-cobranca',
        component: ClientHistory,
        name: 'ClientHistory',
      },
    ],
  },
  {
    path: '/pedidos',
    component: OrderList,
    name: 'OrderList',
  },
  {
    path: '/pedidos/:orderId/:customerId',
    component: Order,
    children: [
      {
        path: '',
        component: OrderAnalisys,
        name: 'OrderAnalisys',
      },
      {
        path: 'cliente',
        component: OrderCustomer,
        name: 'OrderCustomer',
      },
      {
        path: 'itens',
        component: OrderItems,
        name: 'OrderItems',
      },
      {
        path: 'vendas',
        component: OrderCustomerSells,
        name: 'OrderCustomerSells',
      },
      {
        path: 'historico-cobranca',
        component: OrderHistory,
        name: 'OrderHistory',
      },
    ],
  },
  {
    path: '/regras-de-credito',
    component: CreditRulesList,
    name: 'CreditRulesList',
  },
  {
    path: '/regras-de-credito/novo',
    component: CreditRuleCreate,
    name: 'CreditRuleCreate',
  },
  {
    path: '/regras-de-credito/:ruleId(\\d+)',
    component: CreditRuleEdit,
    name: 'CreditRuleEdit',
  },
  {
    path: '/modulos',
    component: Modules,
    name: 'Modules',
  },
  {
    path: '/agenda',
    component: Schedule,
    name: 'Schedule',
  },
  {
    path: '/grupos-de-usuario',
    component: UserGroupList,
    name: 'UserGroupList',
  },
  {
    path: '/grupos-de-usuario/novo',
    component: UserGroupCreate,
    name: 'UserGroupCreate',
  },
  {
    path: '/grupos-de-usuario/:groupId',
    component: UserGroupEdit,
    name: 'UserGroupEdit',
  },
  {
    path: '/grupos-de-usuario/:groupId/modulos/:moduleId(\\d+)',
    component: UserGroupModules,
    name: 'UserGroupModules',
  },
  {
    path: '/folhas-de-pagamento/enviar',
    name: 'PayrollUpload',
    component: PayrollUpload,
  },
  {
    path: '/folhas-de-pagamento/historico',
    name: 'PayrollHistory',
    component: PayrollHistory,
  },
	{
		path: '/acquittancereason',
		name: 'AcquittanceReason',
		component: AcquittanceReason,

	},
	{
		path: '/acquittancereason/save',
		name: 'AcquittanceReasonSave',
		component: AcquittanceReasonSave,
	},
	{
		path: '/acquittancereason/save/:id',
		name: 'AcquittanceReasonUpdate',
		component: AcquittanceReasonSave,
	},

	{
		path: '/apibankingcredential',
		name: 'ApiBankingCredential',
		component: ApiBankingCredential,

	},
	{
		path: '/apibankingcredential/save',
		name: 'ApiBankingCredentialSave',
		component: ApiBankingCredentialSave,
	},
	{
		path: '/apibankingcredential/save/:id',
		name: 'ApiBankingCredentialUpdate',
		component: ApiBankingCredentialSave,
	},
  {
    path: '/usuarios',
    name: 'UserList',
    component: UserList,
  },
  {
    path: '/usuarios/novo',
    name: 'UserCreate',
    component: UserCreate,
  },
  {
    path: '/usuarios/:id',
    name: 'UserEdit',
    component: UserEdit,
  },
  {
    path: '/formas-de-recebimento',
    name: 'BillingMethodList',
    component: BillingMethodList,
  },
  {
    path: '/formas-de-recebimento/novo',
    name: 'BillingMethodCreate',
    component: BillingMethodSave,
  },
  {
    path: '/formas-de-recebimento/:id',
    name: 'BillingMethodUpdate',
    component: BillingMethodSave,
  },
  {
    path: '/conciliacoes-de-extrato',
    name: 'StatementConciliationList',
    component: StatementConciliationList,
  },
  {
    path: '/conciliacoes-de-extrato/historico',
    name: 'StatementConciliationHistory',
    component: StatementConciliationHistory,
  },
  {
    path: '/remessas-de-cobranca',
    name: 'BillingRemittanceList',
    component: BillingRemittanceList,
  },
];
