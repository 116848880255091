































































































































































































































import {
  Vue,
  Component,
  Ref,
  Watch,
  Emit,
} from 'vue-property-decorator';
import { VForm } from '@/types/VForm';
import { dateNow, validateDateRange } from '@/utils/date';

import SelectOptions from '@/domain/interfaces/ISelectOptions';
import IBillingRemittanceFilterData from '@/domain/interfaces/IBillingRemittanceFilter';

import BillingRemittanceAccountReceivableSituationType from '@/domain/enums/BillingRemittanceAccountReceivableSituationType';
import CustomerType from '@/domain/enums/CustomerType';

import FilterParameterBillingRemittanceList from '@/domain/models/filter-parameters/FilterParameterBillingRemittanceList';

import BillingRemittanceRepository from '@/repositories/BillingRemittanceRepository';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';

import { formatErrorForNotification } from '../utils';

@Component({})
export default class BillingRemittanceFilter extends Vue {
  @Ref('filter') readonly filter!: VForm;

  @Watch('customersSearch')
  changedCustomersSearch(search: string) {
    if (search && search.length >= 3) {
      this.debounceCustomers(search);
    } else {
      clearTimeout(this.customersTimer);
    }
  }

  @Emit('validate')
  emitValidate(): IBillingRemittanceFilterData {
    return this.data;
  }

  readonly billingRemittanceRepository:
    BillingRemittanceRepository = new BillingRemittanceRepository();

  readonly filterParametersRepository:
    FilterParametersRepository = new FilterParametersRepository();

  data: IBillingRemittanceFilterData = {} as IBillingRemittanceFilterData;

  loading: boolean = false;

  banksItems: Array<SelectOptions> = [{ text: 'Em Branco', value: 'IS_NULL' }];
  typesItems: Array<SelectOptions> = [];
  methodsItems: Array<SelectOptions> = [];
  companiesItems: Array<SelectOptions> = [];

  customersItems: Array<SelectOptions> = [];
  customersTimer!: ReturnType<typeof setTimeout>;
  customersSearch: string = '';
  customersLoading: boolean = false;

  liberationsItems: Array<SelectOptions> = [
    { value: 'ALL', text: 'Todos' },
    { value: 'NOT_LIBERATED', text: 'Não Liberados' },
    { value: 'LIBERATED', text: 'Liberados' },
  ];

  statusItems: Array<SelectOptions> = [
    { value: '', text: 'Todos' },
    { value: BillingRemittanceAccountReceivableSituationType.SENT, text: 'Enviados' },
    { value: BillingRemittanceAccountReceivableSituationType.NOT_SENT, text: 'Não Enviados' },
    { value: BillingRemittanceAccountReceivableSituationType.REJECTED, text: 'Rejeitados' },
  ];

  customerTypes: Array<SelectOptions> = [
    { value: '', text: 'Todos' },
    { value: CustomerType.NATURAL, text: 'Pessoa Física' },
    { value: CustomerType.LEGAL, text: 'Pessoa Jurídica' },
  ];

  errorDateRange = {
    emission: false,
    return: false,
    due: false,
    messageStart: 'Data inicial deve ser menor ou igual a data final',
    messageEnd: 'Data final deve ser menor ou igual a data inicial',
  };

  get customersNoDataText(): string {
    let noDataText = 'Digite 3 ou mais caracteres.';

    if ((this.customersSearch?.length >= 3) || (this.customersItems.length > 0)) {
      noDataText = this.customersLoading
        ? 'Carregando clientes...'
        : 'Nenhum cliente correspondente encontrado.';
    }

    return noDataText;
  }

  get groupId(): number {
    return this.$session.get('company_group_id');
  }

  mounted() {
    this.handleLoadData();
  }

  companiesChanged(): void {
    this.data.customers = [];
    this.customersItems = [];
  }

  customerTypeChanged(): void {
    this.data.customers = [];
    this.customersItems = [];
  }

  async handleLoadData(): Promise<void> {
    try {
      this.$dialog.startLoading();
      this.loading = true;

      await Promise.all([
        this.loadCompanies(),
        this.loadMethods(),
        this.loadTypes(),
        this.loadBanks(),
        this.loadFilterParameters(),
      ]);
    } catch (error: any) {
      const errorMessage = formatErrorForNotification(error);
      this.$notification.error(errorMessage);
    } finally {
      this.loading = false;
      this.$dialog.stopLoading();
    }
  }

  async loadCompanies(): Promise<void> {
    this.companiesItems = await this.billingRemittanceRepository
      .getCompanies();
  }

  async loadMethods(): Promise<void> {
    this.methodsItems = await this.billingRemittanceRepository
      .getMethods(this.groupId);
  }

  async loadTypes(): Promise<void> {
    this.typesItems = await this.billingRemittanceRepository
      .getTypes(this.groupId);
  }

  async loadBanks(): Promise<void> {
    const response = await this.billingRemittanceRepository
      .getBanks(this.groupId);

    this.banksItems.push(...response);
  }

  async loadCustomers(companies: Array<number>, search: string, type?: string): Promise<void> {
    const customers = await this.billingRemittanceRepository
      .getCustomers(this.groupId, companies, search, type);

    this.customersItems.push(...customers);
  }

  async handleLoadCustomers(search: string): Promise<void> {
    try {
      this.customersLoading = true;
      const { companies, customerType: type } = this.data;

      await this.loadCustomers(companies, search, type);
    } catch (error: any) {
      const errorMessage = formatErrorForNotification(error);
      this.$notification.error(errorMessage);
    } finally {
      this.customersLoading = false;
    }
  }

  debounceCustomers(search: string): void {
    clearTimeout(this.customersTimer);
    this.customersTimer = setTimeout(() => {
      this.handleLoadCustomers(search);
    }, 500);
  }

  validateAllDateRanges(): boolean {
    const {
      initial_emission_date: initialEmission,
      end_emission_date: endEmission,
      initial_due_date: initialDue,
      end_due_date: endDue,
      initial_return_date: initialReturn,
      end_return_date: endReturn,
    } = this.data;

    const haveReturnDate = initialReturn?.length || endReturn?.length;

    const isEmissionDateValid = validateDateRange(initialEmission, endEmission);
    const isDueDateValid = validateDateRange(initialDue, endDue);
    const isReturnDateValid = haveReturnDate
    ? validateDateRange(initialReturn, endReturn)
    : true;

    if (!isEmissionDateValid) {
      this.errorDateRange.emission = true;
      this.$notification.error('Intevalo de emissão inválido!');
    } else {
      this.errorDateRange.emission = false;
    }

    if (!isDueDateValid) {
      this.errorDateRange.due = true;
      this.$notification.error('Intevalo de vencimento inválido!');
    } else {
      this.errorDateRange.due = false;
    }

    if (!isReturnDateValid) {
      this.errorDateRange.return = true;
      this.$notification.error('Intevalo de retorno inválido!');
    } else {
      this.errorDateRange.return = false;
    }

    if (haveReturnDate) {
      if (!isEmissionDateValid && !isDueDateValid && !isReturnDateValid) {
        this.$notification.error('Intevalos inválidos!');
      }
    } else if (!isEmissionDateValid && !isDueDateValid) {
      this.$notification.error('Intevalos inválidos!');
    }

    return isEmissionDateValid && isDueDateValid && isReturnDateValid;
  }

  validate(): void {
    const isValidDateRange = this.validateAllDateRanges();
    const isValidFilter = this.filter.validate();
    if (isValidDateRange && isValidFilter) {
      this.emitValidate();
      this.filterParametersRepository
        .setFilter(GroupFilterParametersEnum.BILLING_REMITTANCE, [
          { key: 'issue_date_initial_billing_remittance', value: this.data.initial_emission_date },
          { key: 'issue_date_final_billing_remittance', value: this.data.end_emission_date },
          { key: 'due_date_initial_billing_remittance', value: this.data.initial_due_date },
          { key: 'due_date_final_billing_remittance', value: this.data.end_due_date },
          { key: 'return_date_initial_billing_remittance', value: this.data.initial_return_date },
          { key: 'return_date_final_billing_remittance', value: this.data.end_return_date },
          { key: 'liberation_billing_remittance', value: this.data.liberation },
          { key: 'status_billing_remittance', value: this.data.status },
          { key: 'types_billing_remittance', value: JSON.stringify(this.data.types) },
          { key: 'methods_billing_remittance', value: JSON.stringify(this.data.methods) },
          { key: 'banks_billing_remittance', value: JSON.stringify(this.data.banks) },
          { key: 'companies_billing_remittance', value: JSON.stringify(this.data.companies) },
          { key: 'occurrences_billing_remittance', value: JSON.stringify(this.data.occurrences) },
          { key: 'borderos_billing_remittance', value: JSON.stringify(this.data.borderos) },
          { key: 'customer_type_billing_remittance', value: this.data.customerType },
        ]);
    }
  }

  async loadFilterParameters(): Promise<void> {
    const response = await this.filterParametersRepository
      .getFilterByGroup(GroupFilterParametersEnum.BILLING_REMITTANCE);

    const filterParameters = FilterParameterBillingRemittanceList.make(response);

    this.data.banks = filterParameters.banks;
    this.data.borderos = filterParameters.borderos;
    this.data.companies = filterParameters.companies;
    this.data.methods = filterParameters.methods;
    this.data.occurrences = filterParameters.occurrences;
    this.data.status = filterParameters.status;
    this.data.types = filterParameters.types;
    this.data.customerType = filterParameters.customerType;

    this.data.end_return_date = filterParameters.endReturnDate;
    this.data.initial_return_date = filterParameters.initialReturnDate;

    this.data.liberation = filterParameters.liberation
      ? filterParameters.liberation
      : 'ALL';

    this.data.end_due_date = filterParameters.endDueDate
      ? filterParameters.endDueDate
      : dateNow();

    this.data.initial_due_date = filterParameters.initialDueDate
      ? filterParameters.initialDueDate
      : dateNow();

    this.data.end_emission_date = filterParameters.endEmissionDate
      ? filterParameters.endEmissionDate
      : dateNow();

    this.data.initial_emission_date = filterParameters.initialEmissionDate
      ? filterParameters.initialEmissionDate
      : dateNow();

    this.data.customers = [];
  }
}
