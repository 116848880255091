import VueRouter from 'vue-router';

import { APP_BASE_URL } from '@config/info';
import OldInncashLayout from '@oldinncash/views/app/app.vue';
import store from '@/stores';

import NewInncashLayout from '@/components/navigation/NewInncashLayout.vue';

import AppView from '@/components/navigation/AppView.vue';
import Login from '@/views/login/Login.vue';
import TokenHandler from '@/views/tokenHandler/TokenHandler.vue';

import oldInncashRoutes from './old_inncash.routes';
import newInncashRoutes from './new_inncash.routes';

const moduleGuard = (to, from, next) => {
	const isModuleSelected = store.state.authentication.currentModule != null;
	if (isModuleSelected || to.name == 'Modules') {
		next();
	} else {
		next({ name: 'Modules' });
	}
};

const routes = [
	{
		path: '/',
		component: AppView,
		children: [
			{
				path: '/',
				name: 'home',
				component: OldInncashLayout,
				children: oldInncashRoutes,
			},
			{
				path: '/',
				name: 'new',
				component: NewInncashLayout,
				children: newInncashRoutes,
			},
		],
		beforeEnter: moduleGuard,
	},
	{ path: '/login', name: 'login', component: Login },
	{ path: '/logintoken/:auto/:thirdtoken', name: 'logintoken', component: Login },
	{ path: '/:token', name: 'TokenHandler', component: TokenHandler },
];

const router = new VueRouter({
	mode: 'history',
	base: APP_BASE_URL,
	routes,
});

export default router;
