import BankConfigSendType from '@/domain/models/BankConfigSendType';

export default class BankConfig {
  constructor(
    public agencia: string,
    public agencia_conectividade: string,
    public apelido_cef: string,
    public api_id: any,
    public banco: string,
    public banco_conectividade: string,
    public branch_erp_id: any,
    public cnab_id: any,
    public cnpj_convenio: any,
    public company_group: number,
    public conta: string,
    public convenio: string,
    public created_at: string,
    public digage_conectividade: string,
    public digconta_conectividade: string,
    public id: number,
    public id_cnab_remi_config: any,
    public late_payment_fee: any,
    public late_payment_interest_day: any,
    public name: string,
    public numcon_conectividade: string,
    public pix_key: string,
    public send_type: any,
    public send_types: Array<BankConfigSendType>,
    public tipo: string,
    public updated_at: string,
    public van_id: any,
  ) { }

  static make(data: any): BankConfig {
    const sendTypes = data.send_types.map(BankConfigSendType.make);
    return new BankConfig(
      data.agencia,
      data.agencia_conectividade,
      data.apelido_cef,
      data.api_id,
      data.banco,
      data.banco_conectividade,
      data.branch_erp_id,
      data.cnab_id,
      data.cnpj_convenio,
      data.company_group,
      data.conta,
      data.convenio,
      data.created_at,
      data.digage_conectividade,
      data.digconta_conectividade,
      data.id,
      data.id_cnab_remi_config,
      data.late_payment_fee,
      data.late_payment_interest_day,
      data.name,
      data.numcon_conectividade,
      data.pix_key,
      data.send_type,
      sendTypes,
      data.tipo,
      data.updated_at,
      data.van_id,
    );
  }
}
