import RangeDate from '@/types/RangeDate';
import { validateDateRange } from '@/utils/date';

export default class DateInputHelper {
  public static formatRangeDate(dateField: string[]): Partial<RangeDate> | null {
    const formattedDate: Partial<RangeDate> = {};

    if (dateField[0]) {
       ({ 0: formattedDate.initial_date } = dateField);
    }

    if (dateField[1]) {
      ({ 1: formattedDate.final_date } = dateField);
    }

    if (
      formattedDate.initial_date
      && formattedDate.final_date
      && !validateDateRange(formattedDate.initial_date, formattedDate.final_date)
    ) {
      return null;
    }

    return { ...formattedDate };
  }
}
