



































import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { formateDate, dateGreaterOrEqualToday } from '@/utils/date';

@Component
export default class InnDateField extends Vue {
  @Prop({
    type: String,
    default: '',
  }) readonly value!: string;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly required!: boolean;

  @Prop({
    type: Boolean,
    default: false,
  }) readonly shouldBeGreater!: boolean;

  @Prop({
    type: String,
    default: '',
  }) readonly nudgeTop!: string;

  @Watch('value')
  onValueChanged(newValue: string) {
    this.model = newValue;
  }

  @Watch('model')
  onModelChanged(newModel: string) {
    this.$emit('input', newModel);
  }

  menu: boolean = false;
  model: string = this.value;

  public get selected() {
    if (this.model) return formateDate(`${this.model}`);
    return this.model;
  }

  public set selected(v : string) {
    this.model = v;
  }

  get rules() {
    const rules = [];

    if (this.required) rules.push((v: string) => !!v || 'Esse campo é obrigatório');

    if (this.shouldBeGreater) {
      rules.push((v: string) => dateGreaterOrEqualToday(v) || 'Escolha uma data atual ou futura');
    }

    return rules;
  }
}
