import { Module, VuexModule, Mutation } from 'vuex-module-decorators';
import store from '@/stores';
import Client from '@/domain/models/Client';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'client',
  store,
})
export default class ClientModule extends VuexModule {
  public clientLoading: boolean = true;

  public clientGeneralValidated: boolean = true;
  public clientContactsValidated: boolean = true;
  public clientInformationValidated: boolean = true;

  public client: Client = {
    id: '',
    companyName: '',
    tradeName: '',
    cnpj: '',
    address: '',
    complement: '',
    city: '',
    neighbourhood: '',
    state: '',
    status: '',
    phone: '',
    invoiceMail: '',
    financialMail1: '',
    financialMail2: '',
    sector: '',
    district: '',
    group: '',
    subGroup: '',
    deadline: '',
    limit: null,
    balanceDue: null,
    bank: '',
    risk: '',
    clientClass: '',
    firstPurchase: '',
    lastPurchase: '',
    notes: '',
    companyId: null,
  };

  @Mutation
  public setClient(client: Client): void {
    this.client = client;
  }

  @Mutation
  public setClientLoading(state: boolean): void {
    this.clientLoading = state;
  }

  @Mutation
  public setClientInfoValidated(params: { isValidated: boolean; clientScreen: 'clientContactsValidated' | 'clientInformationValidated', }): void {
    this[params.clientScreen] = params.isValidated;
  }
}
