var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-container',[_c('breadcrumbs',{attrs:{"page-title":"Motivo de Baixa"}}),_c('page-card',{attrs:{"dividerOn":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","lg":"2","md":"3","sm":"6","xs":"12"}},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"x-large":"","block":"","depressed":"","color":"primary"},on:{"click":_vm.add}},[_vm._v(" ADICIONAR ")])],1)],1)]},proxy:true},{key:"body",fn:function(){return [_c('v-data-table',{staticClass:"elevation-0 pa-3",attrs:{"headers":_vm.headers,"loading":_vm.loadAcquittanceTable,"items":_vm.acquittanceReasonTable,"hide-default-footer":"","disable-pagination":"","group-by":"name"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"12"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? "mdi-chevron-up" : "mdi-chevron-down")+" ")]),_c('span',[_vm._v(" "+_vm._s(group))])],1)]}},{key:"item.acquittanceDetailStatus",fn:function(ref){
var item = ref.item;
return [(item.acquittanceDetailStatus === 'I')?_c('v-chip',{staticClass:"ma-1",attrs:{"small":""}},[_vm._v(" Inativo ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"small":"","color":"green","text-color":"white"}},[_vm._v(" Ativo ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('button-tooltip',{attrs:{"fab":"","icon":"","small":"","buttonIcon":"mdi-pencil","tooltipText":"Editar"},on:{"click":function($event){return _vm.edit(item)}}})]}}],null,true)})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }