export default class InputRules {
  public required(message: string = '') {
    return (v: unknown) => !!v || (message || 'Esse campo é obrigatório');
  }

  public higherThen(size: number, isNumber: boolean = false, message: string = '') {
    return (v: string) => this.getCorrectValueLengthBasedOnType(v, isNumber) > size || (message || `O valor deve ser maior que ${size}`);
  }

  public higherOrEqualThen(size: number, isNumber: boolean = false, message: string = '') {
    return (v: string) => this.getCorrectValueLengthBasedOnType(v, isNumber) >= size || (message || `O valor deve ser maior ou igual que ${size}`);
  }

  public lowerThen(size: number, isNumber: boolean = false, message: string = '') {
    return (v: string) => this.getCorrectValueLengthBasedOnType(v, isNumber) < size || (message || `O valor deve ser menor que ${size}`);
  }

  public lowerOrEqualThen(size: number, isNumber: boolean = false, message: string = '') {
    return (v: string) => this.getCorrectValueLengthBasedOnType(v, isNumber) <= size || (message || `O valor deve ser menor ou igual que ${size}`);
  }

  public betweenThen(initialRange: number, finalRange: number, message: string = '') {
    return (v: number) => (v >= initialRange && v <= finalRange) || (message || `O valor deve estar entre ${initialRange} e ${finalRange}`);
  }

  public isEmail(message: string = '') {
    return (v: string | undefined | null) => {
      if (typeof v === 'undefined' || v === null) {
        return true;
      }

      return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(v)
      || (message || 'Este campo não segue as regras de um email!');
    };
  }

  public isNumber(message: string = '') {
    return (v: string) => {
      if (typeof v === 'undefined' || v === null) {
        return true;
      }

      return (!Number.isNaN(parseFloat(v)) && typeof parseFloat(v) === 'number') || (message || 'O valor deve ser numérico!');
    };
  }

  private getCorrectValueLengthBasedOnType(
    value: string | undefined | null,
    isNumber: boolean,
  ): number {
    if (value === undefined || value === null) {
      return 0;
    }

    if (isNumber) {
      return parseInt(value, 10);
    }

    return value.length;
  }
}
