import { formateDate } from '@/utils/date';
import DocumentFormattation from '@/domain/formattation/DocumentFormattation';

export default class Orders {
    constructor(
        public situation: string,
        public companyName: string,
        public document: string,
        public inclusionDate: string,
        public innId: string,
        public customerInnId: string,
        public number: string,
        public salesChannel: string,
        public status: number,
    ) {}

    public static make(data: any): Orders {
        return new Orders(
            'Bloqueados no Crédito',
            data.companyName,
            DocumentFormattation.chooseTheValidDocumentFormattation(data.document),
            formateDate(data.inclusionDate),
            data.innId,
            data.customerInnId,
            data.number,
            data.salesChannel,
            data.status === null ? 1 : parseInt(data.status, 10),
        );
    }
}
