<template>
  <div>
    <div v-show="!showWriteOff">
      <div id="params" class="m-portlet m-portlet--mobile">
        <div class="m-portlet__head">
          <div class="m-portlet__head-caption">
            <div class="m-portlet__head-title">
              <h3 id="teste" class="m-portlet__head-text">Parâmetros</h3>
            </div>
          </div>
          <div class="m-portlet__head-tools">
            <button
              id="btn-generate"
              type="button"
              v-on:click="toHistory()"
              class="btn mr-2 m-btn--air btn-info"
            >
              Histórico
            </button>
            <button
              id="btn-generate"
              type="button"
              v-on:click="handleLoadClick"
              class="btn m-btn--air btn-success"
            >
              Visualizar
            </button>
            <v-btn icon depressed class="ml-4" @click="toggleParams()">
              <v-icon
                x-large
                class="text--disabled chevron"
                :class="!paramsVisibility ? 'toggle-chevron' : ''"
                >mdi-chevron-up</v-icon
              >
            </v-btn>
          </div>
        </div>
        <div class="m-portlet__body" v-show="paramsVisibility">
          <!--begin: Search Form -->
          <div
            class="
              m-form m-form--label-align-right
              m--margin-top-20 m--margin-bottom-30
            "
          >
            <div class="row align-items-center">
              <div class="col-sm-3">
                <div class="form-group">
                  <date-select
                    :value="searchData.dateStart"
                    label="Vencimento Inicial"
                    id="dateStart"
                    @change="setSearchData({ dateStart: $event })"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <date-select
                    :value="searchData.dateEnd"
                    label="Vencimento Final"
                    id="dateEnd"
                    @change="setSearchData({ dateEnd: $event })"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <date-select
                    :value="searchData.dateProStart"
                    label="Processamento Inicial"
                    id="dateProStart"
                    @change="setSearchData({ dateProStart: $event })"
                  />
                </div>
              </div>
              <div class="col-sm-3">
                <div class="form-group">
                  <date-select
                    :value="searchData.dateProEnd"
                    label="Processamento Final"
                    id="dateProEnd"
                    @change="setSearchData({ dateProEnd: $event })"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Empresa</label>
                  <select
                    class="form-control select2 hide"
                    field-name="Empresa"
                    id="company_id"
                    name="company_id"
                    style="width: 100%"
                  >
                    <option value="">Selecione...</option>
                    <option
                      v-for="company in listAllCompanys"
                      :value="company.company_id"
                      v-bind:key="company.id"
                    >
                      {{ company.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Status</label>
                  <select
                    class="form-control select2 hide"
                    field-name="Status"
                    id="status_id"
                    name="status_id"
                    style="width: 100%"
                    multiple="multiple"
                  >
                    <option
                      v-for="status in listStatus"
                      :value="status.id"
                      v-bind:key="status.id"
                    >
                      {{ status.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Fornecedor (CNPJ)</label>
                  <select
                    class="form-control select2 hide"
                    field-name="Fornecedor"
                    id="provider_id"
                    name="provider_id"
                    style="width: 100%"
                    multiple="multiple"
                  >
                    <option
                      v-for="provider in providers"
                      :value="provider.a2_cgc"
                      v-bind:key="provider.id"
                    >
                      {{
                        provider.a2_nome +
                        " (" +
                        formatCnpj(provider.a2_cgc) +
                        ")"
                      }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="listDdaRecords">
        <div id="listDdaRecords" class="m-portlet m-portlet--mobile">
          <div class="m-portlet__head">
            <div class="m-portlet__head-tools">
              <button
                id="btn-generate"
                type="button"
                v-on:click="save()"
                class="btn m-btn--air btn-success"
              >
                Salvar
              </button>
            </div>
          </div>
          <div class="m-portlet__body" style="font-size: 12px">
            <div class="table-responsive">
              <table id="table-conciliation" class="table table-conciliation">
                <thead class="table-head-resume">
                  <tr>
                    <th class="text-center"></th>
                    <th class="text-center"></th>
                    <th class="text-center">CNPJ</th>
                    <th class="text-center">Razão Social</th>
                    <th class="text-center">Título/Parc</th>
                    <th class="text-center">Valor</th>
                    <th class="text-center">Emissão</th>
                    <th class="text-center">Vencimento</th>
                    <th class="text-center">Conciliar</th>
                    <th class="text-center"></th>
                  </tr>
                </thead>
                <tbody
                  v-for="(ddarecord, index) in listDdaRecords"
                  v-bind:key="ddarecord.id"
                  v-bind:class="[index % 2 == 0 ? '' : 'zebra']"
                >
                  <tr>
                    <td
                      class="padding-zero text-center"
                      style="vertical-align: middle"
                      :rowspan="ddarecord.dda_record_conta_pagar.length + 1"
                    >
                      <span
                        v-if="ddarecord.status == 0"
                        v-popover:tooltip="
                          'Registro DDA aguardando sincronização com ERP.'
                        "
                      >
                        <i class="la la-history text-gold"></i>
                      </span>
                      <span
                        v-if="ddarecord.status == 1"
                        v-popover:tooltip="'Registro DDA já conciliado.'"
                      >
                        <i class="la la-check-circle-o text-green"></i>
                      </span>
                      <span
                        v-if="ddarecord.status == 2"
                        v-popover:tooltip="
                          'Registro DDA com divergências no ERP.'
                        "
                      >
                        <i class="la la-warning text-gold"></i>
                      </span>
                      <span
                        v-if="ddarecord.status == 3"
                        v-popover:tooltip="
                          'Registro DDA não encontrado no ERP.'
                        "
                      >
                        <i class="la la-times-circle text-red"></i>
                      </span>
                      <span
                        v-if="ddarecord.status == 4"
                        v-popover:tooltip="
                          'Título no ERP com código de barras já informado.'
                        "
                      >
                        <i class="la la-barcode text-red"></i>
                      </span>
                    </td>

                    <td style="vertical-align: middle" class="text-center">
                      DDA
                    </td>
                    <td style="vertical-align: middle" class="text-left">
                      {{ formatCnpj(ddarecord.cnpj_fornecedor) }}
                    </td>
                    <td style="vertical-align: middle" class="text-left">
                      {{ formatRazao(ddarecord.razao_fornecedor) }}
                    </td>
                    <td style="vertical-align: middle" class="text-center">
                      {{ ddarecord.titulo }}
                    </td>
                    <td style="vertical-align: middle" class="text-right">
                      {{ formatNumber(ddarecord.valor) }}
                    </td>
                    <td style="vertical-align: middle" class="text-center">
                      {{ ddarecord.data_emissao | moment("DD/MM/YYYY") }}
                    </td>
                    <td style="vertical-align: middle" class="text-center">
                      {{ ddarecord.data_vencto | moment("DD/MM/YYYY") }}
                    </td>
                    <td style="vertical-align: middle" class="text-center"></td>
                    <td
                      class="padding-zero text-center"
                      style="vertical-align: middle"
                      :rowspan="ddarecord.dda_record_conta_pagar.length + 1"
                    >
                      <div class="dropdown" v-if="companyGroupId == 16 || companyGroupId == 12 || companyGroupId == 8 || companyGroupId == 17">
                        <i
                          class="fa fa-bars dropdown-menu-icon"
                          id="dropdownMenuButton"
                          v-if="(ddarecord.status !== 1 && ddarecord.status !== 9) || ( ddarecord.dda_record_conta_pagar.length > 0 && ddarecord.dda_record_conta_pagar[0].origin === 'INN' && ddarecord.status === 1)"
                          data-toggle="dropdown"
                          style="
                            float: right;
                            cursor: pointer;
                            padding-right: 2px;
                            margin-right: 25px;
                            color: #575962;
                          "
                        ></i>
                        <div 
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            v-if="ddarecord.status !== 1 && ddarecord.status !== 9"
                            class="dropdown-item"
                            @click="openWriteOff(ddarecord)"
                            style="padding: 0.3rem 1.2rem; cursor: pointer"
                            >Gerar fatura</a
                          >
                          <a
                            v-if="(ddarecord.status === 1 && ddarecord.dda_record_conta_pagar.length > 0 && ddarecord.dda_record_conta_pagar[0].origin === 'INN')"
                            class="dropdown-item"
                            style="padding: 0.3rem 1.2rem; cursor: pointer"
                            @click="deleteInvoice(ddarecord)"
                            >Excluir Fatura</a
                          >
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-for="ddarecordcontapagar in orderDdaRecordContaPagar(
                      ddarecord.dda_record_conta_pagar
                    )"
                    v-bind:key="ddarecordcontapagar.id"
                    v-bind:class="[index % 2 == 0 ? '' : 'zebra']"
                  >
                    <td style="vertical-align: middle" class="text-center">
                      {{ ddarecordcontapagar.origin }}
                    </td>
                    <td style="vertical-align: middle" class="text-left">
                      <span
                        v-bind:class="[
                          ddarecord.status == 2 &&
                          ddarecord.cnpj_fornecedor !=
                            ddarecordcontapagar.a2_cgc
                            ? 'text-red'
                            : '',
                        ]"
                      >
                        {{ formatCnpj(ddarecordcontapagar.a2_cgc) }}
                      </span>
                    </td>
                    <td style="vertical-align: middle" class="text-left">
                      <span v-popover:tooltip="ddarecordcontapagar.a2_nome">
                        {{ formatRazao(ddarecordcontapagar.a2_nome) }}
                      </span>
                    </td>
                    <td style="vertical-align: middle" class="text-center">
                      <span
                        v-popover:tooltip="
                          getPopoverTitulo(ddarecordcontapagar)
                        "
                        v-bind:class="[
                          ddarecord.status == 2 &&
                          ddarecord.titulo != ddarecordcontapagar.e2_num
                            ? 'text-red'
                            : '',
                        ]"
                      >
                        {{
                          ddarecordcontapagar.e2_num +
                          (ddarecordcontapagar.e2_parcela
                            ? " / " + ddarecordcontapagar.e2_parcela
                            : "")
                        }}
                      </span>
                    </td>
                    <td style="vertical-align: middle" class="text-right">
                      <span
                        v-bind:class="[
                          ddarecord.status == 2 &&
                          ddarecord.valor != ddarecordcontapagar.e2_saldo
                            ? 'text-red'
                            : '',
                        ]"
                      >
                        {{ formatNumber(ddarecordcontapagar.e2_saldo) }}
                      </span>
                    </td>
                    <td style="vertical-align: middle" class="text-center">
                      <span
                        v-bind:class="[
                          ddarecord.status == 2 &&
                          ddarecord.data_emissao !=
                            ddarecordcontapagar.e2_emissao
                            ? 'text-red'
                            : '',
                        ]"
                      >
                        {{
                          ddarecordcontapagar.e2_emissao | moment("DD/MM/YYYY")
                        }}
                      </span>
                    </td>
                    <td style="vertical-align: middle" class="text-center">
                      <span
                        v-bind:class="[
                          ddarecord.status == 2 &&
                          ddarecord.data_vencto !=
                            ddarecordcontapagar.e2_vencrea
                            ? 'text-red'
                            : '',
                        ]"
                      >
                        {{
                          ddarecordcontapagar.e2_vencrea | moment("DD/MM/YYYY")
                        }}
                      </span>
                    </td>
                    <td style="vertical-align: middle" class="text-center">
                      <div
                        class="m-checkbox-inline"
                        v-if="[2, 4].includes(ddarecord.status)"
                      >
                        <label class="m-checkbox m-checkbox--success">
                          <input
                            type="checkbox"
                            v-model="ddarecordcontapagar.checked"
                            v-on:click="
                              checkConciliation(ddarecord, ddarecordcontapagar)
                            "
                          />
                          <span></span>
                        </label>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <tooltip />
          </div>
        </div>
      </template>
    </div>
    <scrolling-hide-component :show="showWriteOff" :scrollTo="scrollTo">
      <search-income-record
				:type="'payment'"
        :record="selectedConciliation"
        @close="onCLoseWriteOff"
        @conciliation:search="sendAcquitanceRequest"
      />
    </scrolling-hide-component>
  </div>
</template>

<script>
import _ from "lodash";
import ScrollingHideComponent from "../../../src/components/defaults/ScrollingHideComponent.vue";
import SearchIncomeRecord from "../statementconciliation/searchIncomeRecord/SearchIncomeRecord.vue";
import DateSelect from "../../../src/components/fields/DateSelect.vue";
import ArtificialInvoices from "@/services/artificial-invoices.service";

import {
  validateImporting,
  validatePaymentRemittance,
  ValidationError,
} from "@/utils/processes_validation.js";

export default {
  data() {
    return {
      paramsVisibility: true,
      companys: [],
      providers: [],
      listStatus: [],
      listDdaRecords: null,
      listDdaRecordsSave: [],
      searchData: {
        dateStart: null,
        dateEnd: null,
        dateProStart: null,
        dateProEnd: null,
      },

      selectedConciliation: null,
      scrollTo: 0,
      showWriteOff: false,
    };
  },
  components: {
    ScrollingHideComponent,
    SearchIncomeRecord,
    DateSelect,
  },
  computed: {
  
    listAllCompanys() {
      let dataRet = this.companys;

      dataRet = _.filter(dataRet, (data) => data.status == 1);

      return dataRet;
    },
  },
  mounted: function () {
    this.companyGroupId = this.$session.get('company_group_id');
    blockPage("Carregando os dados...");

    $("#company_id").select2({ placeholder: "Selecione uma Empresa" });

    $("#provider_id").select2({
      placeholder: "Selecione os fornecedores ou deixe em branco para todos",
      language: "pt-BR",
      dropdownParent: $("#params"),
      ajax: {
        url: this.$store.state.API_BASE + "provider/listAll",
        dataType: "json",
        width: "resolve",
        type: "GET",
        delay: 1500,
        headers: {
          token: this.$session.get("token"),
        },
        data: function (param) {
          return {
            search: param.term || "",
            company_id: $("#company_id").val() || "",
            limit: 100,
          };
        },
        processResults: (result) => {
          let resultado = [];
          for (let i = 0; i < result.data.length; i++) {
            resultado.push({
              id: result.data[i].a2_cgc,
              text:
                result.data[i].a2_nome +
                " (" +
                this.formatCnpj(result.data[i].a2_cgc) +
                ")",
            });
          }
          return {
            results: resultado,
          };
        },
        cache: true,
      },
    });

    $("#status_id").select2({ placeholder: "Selecione" });

    numeral.language("pt-br");

    this.loadCompanys();
  },
  methods: {
    toggleParams() {
      this.paramsVisibility = !this.paramsVisibility;
    },
    hiddenParams() {
      this.paramsVisibility = false;
    },
    setSearchData(newData) {
      this.searchData = { ...this.searchData, ...newData };
    },
    parseDate(date) {
      if (!date) {
        return false;
      }
      return moment(date, "DD/MM/YYYY").toDate();
    },
    loadCompanys: function () {
      blockPage("Carregando os dados...");

      this.$http
        .get(this.$store.state.API_BASE + "dda/config/listAll", {
          headers: { token: this.$session.get("token") },
        })
        .then(
          (result) => {
            this.companys = result.body.data;

            this.loadParameter();
          },
          (error) => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    loadProviders: function (provider_id) {
      if (!provider_id || provider_id == "ALL") {
        unblockPage();
        return;
      }

      blockPage("Carregando os dados...");

      this.$http
        .get(this.$store.state.API_BASE + "provider/listAll", {
          headers: {
            token: this.$session.get("token"),
          },
          params: {
            search: "",
            company_id: $("#company_id").val() || "",
            limit: 0,
            cgcs: provider_id
          },
        })
        .then(
          (result) => {
            this.providers = result.body.data;

            setTimeout(() => {
              $("#provider_id").val(provider_id);
              $("#provider_id").trigger("change");
            }, 0);

            unblockPage();
          },
          (error) => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },

    openWriteOff(conciliationrecord) {
      this.scrollTo = window.scrollY;
      // this.selectedConciliation = conciliationrecord;
      this.selectedConciliation = {
        values: [conciliationrecord],
        conciliation_record_movimento_bancario: []
      };
      this.showWriteOff = true;
    },

    onCLoseWriteOff(data) {
      this.selectedConciliation = { ...this.selectedConciliation, ...data };
      this.showWriteOff = false;

      this.handleLoadClick();
    },

    async deleteInvoice(cp) {
      try {
        Swal.fire({
          icon: "question",
          html: "<h3>Deseja excluir a fatura?</h3>",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
          customClass: {
            confirmButton: "receipt-swal-btn",
            cancelButton: "receipt-swal-btn",
          },
          cancelButtonColor: "rgb(48, 133, 214)",
          showCancelButton: true,
          allowOutsideClick: true,
        }).then(async ({ value }) => {
          if (value) {
            blockPage("Excluindo Fatura....");
            const artificialInvoicesService = new ArtificialInvoices();

            await artificialInvoicesService.delete(cp.dda_record_conta_pagar[0].artificial_invoice_id);

            this.handleLoadClick();
          }
        });
      } catch (error) {
        unblockPage();

        showNotification(
          "Atenção",
          "Oops! Ocorreu um erro ao excluir a fatura.",
          "danger"
        );
      }
    },

    loadParameter: function () {
      blockPage("Carregando os dados...");

      this.listStatus = [
        { id: 0, name: "Pendente" },
        { id: 1, name: "Processado" },
        { id: 2, name: "Divergência" },
        { id: 4, name: "Conflito Código de Barras" },
        { id: 3, name: "Não Encontrado" },
      ];

      this.$http
        .get(
          this.$store.state.API_BASE + "fieldparameter/getDataDdaConciliation",
          { headers: { token: this.$session.get("token") } }
        )
        .then(
          (result) => {
            blockPage("Carregando os dados...");

            const date = moment().format("DD/MM/YYYY");

            this.setSearchData({
              dateStart: result.body.data.date_initial || date,
              dateEnd: result.body.data.date_finish || date,
              dateProStart: result.body.data.date_pro_initial || date,
              dateProEnd: result.body.data.date_pro_finish || date,
            });

            if (result.body.data.company_id) {
              $("#company_id").val(result.body.data.company_id);
              $("#company_id").trigger("change");
            }

            let status_id = result.body.data.status_id || [
              "0",
              "1",
              "2",
              "3",
              "4",
            ];

            $("#status_id").val(status_id);
            $("#status_id").trigger("change");

            this.loadProviders(result.body.data.provider_id);
          },
          (error) => {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
            unblockPage();
          }
        );
    },
    handleLoadClick() {
      if (!this.validateLoad()) return false;

      checkImportBeforeSearch(this)
        .then(validateImporting)
        .then((data) =>
          validatePaymentRemittance(
            data,
            this.searchData.dateStart,
            this.searchData.dateEnd
          )
        )
        .then(this.loadDdaRecord)
        .catch((reason) => {
          if (reason instanceof ValidationError) {
            showNotification("Atenção", reason.message, "warning");
          } else {
            showNotification(
              "Atenção",
              "Opa! Parece que algo saiu errado, tente recarregar a página",
              "danger"
            );
          }
          unblockPage();
        });
    },
    validateLoad() {
      let company_id = $("#company_id").val();
      if (!company_id) {
        showNotification("Atenção", "A empresa deve ser informada", "warning");
        return false;
      }

      if ($.trim(this.searchData.dateStart) == "") {
        showNotification(
          "Atenção",
          "A data inicial deve ser informada",
          "warning"
        );
        return false;
      }

      if ($.trim(this.searchData.dateEnd) == "") {
        showNotification(
          "Atenção",
          "A data final deve ser informada",
          "warning"
        );
        return false;
      }

      if ($.trim(this.searchData.dateProStart) == "") {
        showNotification(
          "Atenção",
          "A data de processamento inicial deve ser informada",
          "warning"
        );
        return false;
      }

      if ($.trim(this.searchData.dateProEnd) == "") {
        showNotification(
          "Atenção",
          "A data de processamento final deve ser informada",
          "warning"
        );
        return false;
      }

      const momentDateStart = moment(this.searchData.dateStart, "DD/MM/YYYY");
      const momentDateEnd = moment(this.searchData.dateEnd, "DD/MM/YYYY");
      if (momentDateStart.diff(momentDateEnd) > 0) {
        showNotification(
          "Atenção",
          "A data inicial não pode ser menor que a final",
          "warning"
        );
        return false;
      }

      const momentDateProStart = moment(
        this.searchData.dateProStart,
        "DD/MM/YYYY"
      );
      const momentDateProEnd = moment(this.searchData.dateProEnd, "DD/MM/YYYY");
      if (momentDateProStart.diff(momentDateProEnd) > 0) {
        showNotification(
          "Atenção",
          "A data de processamento inicial não pode ser menor que a final",
          "warning"
        );
        return false;
      }
      return true;
    },
    loadDdaRecord: function () {
      let company_id = $("#company_id").val();
      this.conciliationIsRunninng = null;
      let status =
        $("#status_id").val().length > 0 ? $("#status_id").val() : "ALL";

      let provider_id = [];

      if ($("#provider_id").val().length > 0) {
        provider_id = $("#provider_id").val();
      } else {
        provider_id = "ALL";
      }

      blockPage("Carregando os dados...");

      this.listDdaRecords = null;

      $.ajax({
        method: "GET",
        url: this.$store.state.API_BASE + "dda/record",
        headers: {
          token: this.$session.get("token"),
        },
        data: {
          dateStart: this.searchData.dateStart,
          dateEnd: this.searchData.dateEnd,
          dateProStart: this.searchData.dateProStart,
          dateProEnd: this.searchData.dateProEnd,
          company_id: company_id,
          status_id: status,
          provider_id: provider_id,
        },
      })
        .done((result) => {
          this.conciliationIsRunninng = result.process_running;
          if (result.error) {
            showNotification("Atenção", result.message, "danger");
          } else {
            if (result.total_records > 0) {
              this.listDdaRecords = result.data;
              if (this.$session.get("user_config_autotoggle_paramns")) {
                this.hiddenParams();
              }

              setTimeout(() => {
                $("table").floatThead({
                  position: "absolute",
                  top: 48,
                });
              }, 1000);
            } else {
              showNotification("Atenção", "Sem dados para listar", "warning");
            }
          }

          unblockPage();
        })
        .fail((error) => {
          showNotification(
            "Atenção",
            "Opss!!! Não foi possível carregar os dados",
            "danger"
          );
          unblockPage();
        });
    },
    orderDdaRecordContaPagar(list) {
      return _.orderBy(list, "e2_vencrea");
    },
    checkConciliation(ddarecord, ddarecordcontapagar) {
      let newValue = !ddarecordcontapagar.checked;

      //Desmarcando todos
      ddarecord.dda_record_conta_pagar.forEach(
        (item) => (item.checked = false)
      );

      //Marcando apenas o clicado agora
      ddarecordcontapagar.checked = newValue;

      //Para atualizar a tela
      this.$forceUpdate();
    },
    formatNumber(value) {
      value = value || 0;
      return numeral(value).format("0,0.00");
    },
    formatCnpj(value) {
      value = value || "";
      return value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5"
      );
    },
    formatRazao(value) {
      value = value || "";
      return value.substring(0, 30) + (value.length > 30 ? "..." : "");
    },
    getPopoverTitulo(cp) {
      //e2_filial - cp.e2_prefixo - cp.e2_num - cp.e2_parcela - cp.e2_tipo

      let formatted;

      formatted = cp.e2_filial;

      formatted += cp.e2_prefixo ? " - " + cp.e2_prefixo : "";

      formatted += " - " + cp.e2_num;

      formatted += cp.e2_parcela ? " - " + cp.e2_parcela : "";

      formatted += cp.e2_tipo ? " - " + cp.e2_tipo : "";

      return formatted;
    },
    save() {
      let arraySave = [];

      //Percorre todos os registros de DDA
      this.listDdaRecords.forEach((record) => {
        //Caso o DDA não tenha nenhum titulo do ERP relacionado, já retorna
        if (!record.dda_record_conta_pagar) return;

        //Buscar o titulo marcado para conciliar daquele DDA
        let record_conta_pagar_checked = record.dda_record_conta_pagar.find(
          (erp) => erp.checked
        );

        //Se não tem titulo marcado, já retorna.
        if (!record_conta_pagar_checked) return;

        //Prepara objeto para enviar para a API.
        arraySave.push({
          id: record.id,
          financials: [
            {
              e2_filial: record_conta_pagar_checked.e2_filial,
              e2_prefixo: record_conta_pagar_checked.e2_prefixo,
              e2_num: record_conta_pagar_checked.e2_num,
              e2_parcela: record_conta_pagar_checked.e2_parcela,
              e2_tipo: record_conta_pagar_checked.e2_tipo,
              e2_fornece: record_conta_pagar_checked.e2_fornece,
              e2_loja: record_conta_pagar_checked.e2_loja,
              company_id: record_conta_pagar_checked.company_id,
              e2_codbar: record_conta_pagar_checked.e2_codbar,
              id_customer: record_conta_pagar_checked.id_customer,
              branch_token: record_conta_pagar_checked.branch_token,
              source_table: record_conta_pagar_checked.source_table,
              company_token: record_conta_pagar_checked.company_token,
              TABLE_COMPANY_CODE: record_conta_pagar_checked.TABLE_COMPANY_CODE,
            },
          ],
        });
      });

      $.ajax({
        method: "POST",
        url: this.$store.state.API_BASE + "dda/conciliation",
        headers: {
          token: this.$session.get("token"),
        },
        data: {
          arrayData: JSON.stringify(arraySave),
        },
      })
        .done((result) => {
          if (result.error == false) {
            showNotification(
              "Sucesso",
              "Dados conciliados com sucesso!",
              "success"
            );
            this.loadDdaRecord();
          } else {
            showNotification("Atenção", result.error, "danger");
            return false;
          }
        })
        .fail((error) => {
          console.log(error);
          showNotification("Opss!", "Não foi possível salvar dados!", "danger");
        });
    },
    sendAcquitanceRequest(data) {
      this.onCLoseWriteOff();
      this.$http
        .post(`${this.$store.state.API_BASE}conciliation/acquittance/`, data, {
          headers: { token: this.$session.get("token") },
        })
        .then(
          (result) => {
            this.handleConciliationFinish(result.body.data);
          },
          (err) => console.error(err)
        );
    },
    toHistory() {
      this.$router.push({ path: "ddahistory" });
    },
  },
};
</script>

<style scoped>
.zebra {
  background: #f0f4f7;
}

.padding-zero {
  padding-right: 0px;
  padding-left: 0;
}

.header-listDdaRecords {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 4px;
  background-color: #498cc5;
  color: white;
  font-weight: bold;
}

[class*=" la-"],
[class^="la-"] {
  font-size: 21px;
}

.m-checkbox > span,
.m-radio > span {
  top: 0px;
  left: 5px;
}

.m-checkbox-inline .m-checkbox,
.m-checkbox-inline .m-radio,
.m-radio-inline .m-checkbox,
.m-radio-inline .m-radio {
  margin-bottom: 13px;
}

.table td,
.table th {
  border-top: transparent;
}

.cursor-pointer {
  cursor: pointer;
}
</style>