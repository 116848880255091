import { formateDate } from '@/utils/date';

export default class ClientSaleOrders {
    constructor(
        public invoiceSerie: string,
        public invoiceEmission: string,
        public order: string,
        public orderInclusionDate: string,
        public channel: string,
        public status: string,
        public value: number,
    ) {}

    public static make(data: any): ClientSaleOrders {
        return new ClientSaleOrders(
            `${data.invoice}/${data.serie}`,
            formateDate(data.invoiceEmission),
            data.number,
            formateDate(data.orderInclusionDate),
            data.origin,
            data.status,
            data.total,
        );
    }
}
