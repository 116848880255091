import ClientInteraction from '@/domain/models/ClientInteraction';
import fixtureEvents from './data/events.json';

const delay = (response, time) => new Promise((resolve) => {
    setTimeout(() => {
      resolve(response);
    }, time);
  });

const parseClientInteraction = (e) => new ClientInteraction(
    e.id,
    e.start,
    e.end,
    e.details,
    e.channel,
    e.clientName,
    e.contactName,
    e.status,
  );

export default {
  async getAll() {
    const events = fixtureEvents.map(parseClientInteraction);
    return delay(events, 2000);
  },
  async getClientEvents(clientId) {
    const events = fixtureEvents.filter((c) => c.client == clientId).map(parseClientInteraction);
    return delay(events, 2000);
  },
};
