import moment from 'moment';

export default class BillingHistory {
    constructor(
        public id: number,
        public clientName: string,
        public date: string,
        public initialRangeTime: string,
        public finalRangeTime: string,
        public channel: 'phone' | 'sms' | 'email' | 'whatsapp',
        public observation: string,
        public concludeObservation: string,
        public status: string,
    ) {}

    static make(data: any): BillingHistory {
        return new BillingHistory(
            data.id,
            data.clientName,
            moment(data.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
            moment(data.initialRangeTime, 'HH:mm:ss').format('HH:mm'),
            moment(data.finalRangeTime, 'HH:mm:ss').format('HH:mm'),
            data.channel || 'default',
            data.observation,
            data.concludeObservation,
            data.status,
        );
    }
}
