import Company from '@/domain/models/Company';
import Repository from './base/Repository';

export default class CompanyRepository extends Repository {
  async getAllActivie() : Promise<Company[]> {
    const { status, data: response } = await this.datasource.get('/company/listAllActivie');
    if (status == 200) {
      return response.data.map(Company.make);
    }
    throw new Error('Aconteceu um erro inesperado');
  }
}
