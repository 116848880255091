























































import {
  Vue,
  Component,
  Prop,
  Emit,
} from 'vue-property-decorator';

@Component({})
export default class BillingREmittanceGenerateFeedback extends Vue {
  @Prop(Boolean) readonly open!: boolean;
  @Prop(String) readonly title!: string;
  @Prop(String) readonly text!: string;
  @Prop(String) readonly number!: string;
  @Prop(String) readonly type!: string;

  @Emit()
  reload(): boolean {
    this.close();
    return true;
  }

  @Emit()
  close(): boolean {
    return true;
  }

  next(): void {
    if (this.type === 'success') {
      this.reload();
    } else {
      this.close();
    }
  }

  async clipboardCopy(value: string): Promise<void> {
    try {
      await navigator.clipboard.writeText(value);
      this.$notification.success('Número do borderô copiado com sucesso!');
    } catch (error) {
      console.error(error);
      this.$notification.error('Não foi possível copiar o número, tente manualmente.');
    }
  }
}
