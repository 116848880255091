var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-timeline-item',{attrs:{"icon":_vm.channelInfoIdentifiers[_vm.event.channel].icon,"color":_vm.getStatusColor,"large":""}},[_c('v-card',[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.date)+" ")]),_c('v-spacer'),(!_vm.isReducedWidth)?_c('div',_vm._l((_vm.actionsOptions),function(ref){
var text = ref.text;
var actionEmit = ref.actionEmit;
var icon = ref.icon;
return _c('v-tooltip',{key:actionEmit,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isActive || actionEmit === 'EDIT')?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.takeActionEmit(actionEmit, _vm.event.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(icon))])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(text))])])}),1):_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}])},[_c('v-list',_vm._l((_vm.actionsOptions),function(ref){
var text = ref.text;
var actionEmit = ref.actionEmit;
return _c('v-list-item',{key:actionEmit,class:!(_vm.isActive || actionEmit === 'EDIT') ? 'grey--text text--lighten-1' : '',attrs:{"disabled":!(_vm.isActive || actionEmit === 'EDIT')},on:{"click":function($event){return _vm.takeActionEmit(actionEmit, _vm.event.id)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(text)+" ")])],1)}),1)],1)],1),_c('v-card-text',[_c('p',{staticClass:"mb-1"},[_c('strong',[_vm._v("Nome do responsável: ")]),_vm._v(" "+_vm._s(_vm.event.clientName)+" ")]),_c('p',{staticClass:"mb-1"},[_c('strong',[_vm._v("Canal para contato: ")]),_vm._v(" "+_vm._s(_vm.channelInfoIdentifiers[_vm.event.channel].name)+" ")]),_c('p',{staticClass:"mb-1"},[_c('strong',[_vm._v("Descrição: ")]),_vm._v(" "+_vm._s(_vm.event.observation)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }