var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"pa-12"},[_c('v-col',{staticClass:"pa-0"},[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"icon":"","fab":"","small":""},on:{"click":function($event){return _vm.$router.push('/dashboard')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-home ")])],1)]}}])},[_c('span',[_vm._v("Voltar para Inicial")])]),_c('h4',{staticClass:"text-h6 font-weight-bold ml-4 mt-2"},[_vm._v(" Acesso PIX ")]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",attrs:{"color":"info"},on:{"click":function($event){return _vm.$router.push('/apibankingcredential/save')}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-plus ")]),_vm._v(" Adicionar ")],1)],1),_c('v-divider',{staticClass:"mt-0"}),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","headers":_vm.headers,"loading":_vm.loading,"items":_vm.apiBankingCredentialTable,"group-by":"companyGroupName"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var isOpen = ref.isOpen;
var toggle = ref.toggle;
return [_c('th',{attrs:{"colspan":"12"}},[_c('v-icon',{on:{"click":toggle}},[_vm._v(" "+_vm._s(isOpen ? "mdi-chevron-up" : "mdi-chevron-down")+" ")]),_c('span',[_vm._v(" "+_vm._s(group))])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status === 'I')?_c('v-chip',{staticClass:"ma-1"},[_vm._v(" Inativo ")]):_c('v-chip',{staticClass:"ma-2",attrs:{"color":"green","text-color":"white"}},[_vm._v(" Ativo ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right d-flex"},[_c('v-tooltip',{staticClass:"text-right",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$router.push(("apibankingcredential/save/" + (item.userId)))}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-square-edit-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }