


























import {
  Component,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { arraySumProperty, toCurrency } from '@/utils';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import OrderItemsRepository from '@/repositories/OrderItemsRepository';
import AuthenticationModule from '@/stores/modules/AuthenticationModule';
import OrderItemsModel from '@/domain/models/OrderItems';

@Component
export default class OrderItems extends Vue {
  private readonly authenticationModule: AuthenticationModule = getModule(AuthenticationModule);
  private readonly orderItemsRepository: OrderItemsRepository = new OrderItemsRepository();

  public loading: boolean = false;

  public total: number = 0;

  public orderItems: OrderItems[] = [];
  public headers: IVDataTableHeader[] = [
    { text: 'Nome do Produto', value: 'name', sortable: false },
    { text: 'Quantidade', value: 'quantity', sortable: false },
    { text: 'Valor Unit', value: 'unitValue', sortable: false },
    { text: 'Valor ST', value: 'stValue', sortable: false },
    { text: 'Total ST', value: 'totalStValue', sortable: false },
    { text: 'Valor Total', value: 'totalValue', sortable: false },
  ];

  @Watch('dataTableOptions')
  onSortDirectionDataTableChange() {
    this.getOrderItems();
  }

  public created(): void {
    this.getOrderItems();
  }

  public async getOrderItems(): Promise<void> {
    if (this.loading) {
      return;
    }

    this.loading = true;

    try {
      const orderItems = await this.orderItemsRepository.listOrderItems(
        this.authenticationModule.user.company_group_id,
        this.authenticationModule.companyIds,
        this.$route.params.orderId,
      );

      this.orderItems = orderItems.map((orderItem: OrderItemsModel) => ({
        ...orderItem,
        unitValue: toCurrency(orderItem.unitValue),
        stValue: toCurrency(orderItem.stValue),
        totalStValue: toCurrency(orderItem.totalStValue),
        totalValue: toCurrency(orderItem.totalValue),
      }));

      this.total = toCurrency(arraySumProperty(orderItems, 'totalValue'));
    } catch (error) {
      this.$notification.error('Houve um problema ao requisitar os items do pedido!');
    } finally {
      this.loading = false;
    }
  }
}
