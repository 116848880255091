









































import {
  Component,
  Vue,
} from 'vue-property-decorator';
import RuleExpansionPanels from './components/RuleExpansionPanels.vue';

@Component({
  components: {
    RuleExpansionPanels,
  },
})
export default class CreditRuleEdit extends Vue {
  public title: string = 'Título da Regra';

  cancel() {
    this.$router.go(-1);
  }
}
