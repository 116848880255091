<template>
  <page-container>
    <v-row>
      <v-col>
        <breadcrumbs page-title="Histórico da Folha de Pagamento" returnPage/>
        <v-card class="elevation-0">
          <v-card-title>
            <v-col cols="12" lg="3" md="6" sm="12" xs="12">
              <span class="font"></span>
              <inn-date-field
                v-model="initialDate"
                dense
                label="Processamento inicial"
              ></inn-date-field>
            </v-col>
            <v-col cols="12" lg="3" md="6" sm="12" xs="12">
              <inn-date-field
                v-model="finalDate"
                dense label="Processamento final">
              </inn-date-field>
            </v-col>
            <v-col cols="12" lg="4" md="9" sm="12" xs="12">
              <inn-select
                v-model="companies"
                :items="serializedCompanies"
                placeholder="Selecione uma empresa"
                hide-details
              >
              </inn-select>
            </v-col>
            <v-spacer/>
            <v-col cols="12" lg="2" md="3" sm="12" xs="12">
              <v-btn
                @click="onSubmit"
                large
                block
                depressed
                :loading="loading"
                color="success"
              >
                VISUALIZAR
              </v-btn>
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-data-table class="mt-6" :items="items" :headers="headers">
              <template #[`item.processingDate`]="{item}">
                {{ $moment(item.processingDate).format('DD/MM/YYYY') }}
              </template>
              <template #[`item.download`]="{item}">
                <inn-icon-btn icon="mdi-download" @click="handleDownload(item.id)" />
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </page-container>
</template>

<script>
// eslint-disable-next-line
import PayrollService from "@/services/payroll.service";
// eslint-disable-next-line
import CompanyService from "@/services/company.service";
// eslint-disable-next-line
import Breadcrumbs from '@/components/defaults/Breadcrumbs.vue';
// eslint-disable-next-line
import PageContainer from "@/components/defaults/PageContainer.vue";

export default {
  name: 'PayrollHistory',
  components: {
    Breadcrumbs,
    PageContainer,
  },
  data() {
    return {
      initialDate: this.$moment().format('YYYY-MM-DD'),
      finalDate: this.$moment().format('YYYY-MM-DD'),
      companies: '',
      loading: false,
      headers: [
        { text: 'Data', value: 'processingDate', filterable: false },
        { text: 'Usuário', value: 'userName', filterable: false },
        { text: 'Nome do Arquivo', value: 'fileName', filterable: false },
        { text: 'Baixar arquivo', value: 'download', filterable: false },
      ],
      items: [],
      options: [],

    };
  },

  async created() {
      await this.loadCompanies();
  },

  computed: {
    serializedCompanies() {
      return this.options.map((element) => ({ value: element.id, label: element.name }));
    },
  },

  methods: {
    async onSubmit() {
      try {
        this.loading = true;
        const payrollService = new PayrollService();
        const result = await payrollService.listAll().send({
          initialDate: this.initialDate,
          finalDate: this.finalDate,
          companyId: this.companies,
        });

        if (result.error == false) {
          this.items = result.data;

          if (result.data.length == 0) {
          this.$notification.warn('Nenhum registro localizado');
          this.loading = false;
          }
        } else {
          this.$notification.error(`${result.message}`);
          this.loading = false;
        }
      } catch (error) {
        this.$helpers.defaultCatchError(error);
        this.loading = false;
      }
    },

    async loadCompanies() {
      const companyService = new CompanyService();
      const response = await companyService.index();
      this.options = response.data;
    },

    async handleDownload(id) {
        const payrollService = new PayrollService();
        const result = await payrollService.download().show(id);

        if (result.error == true) {
          this.$notification.error(`${result.message}`);
          return false;
        }

        var blob = new Blob([result.file], { type: 'application/octet-stream' });
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = result.name;
        link.click();
        return true;
      },
    },
  };
</script>

<style></style>
