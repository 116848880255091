
















































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import IUserCreateParams from '@/repositories/parameters/IUserCreateParams';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import UserGroup from '@/domain/models/UserGroup';
import PageAction from '@/types/PageAction';
import CompanyGroup from '@/domain/models/CompanyGroup';
import InputRulesHelper from '@/helpers/InputRulesHelper';
import IFieldChoises from '@/types/IFieldChoises';

@Component
export default class RegisterTab extends mixins<ModelManagementMixin<IUserCreateParams>>(
  ModelManagementMixin,
) {
  public readonly inputRules: InputRulesHelper = new InputRulesHelper();

  @Prop() readonly userGroups!: UserGroup[];
  @Prop() readonly companyGroups!: CompanyGroup[];
  @Prop() readonly showAdminInfo!: boolean;
  @Prop() readonly pageAction!: PageAction;

  get isCreatePage() {
    return this.pageAction == PageAction.CREATE;
  }

  get userGroupOptions(): IFieldChoises[] {
    return this.userGroups.map((group: UserGroup) => ({
      value: group.id.toString(),
      label: group.name,
    }));
  }

  get companyGroupOptions(): IFieldChoises[] {
    return this.companyGroups.map((group: CompanyGroup) => ({
      value: group.id.toString(),
      label: group.description,
    }));
  }
}
