import {
  Module,
  VuexModule,
  Mutation,
  Action,
} from 'vuex-module-decorators';
import VueInstance from '@/main';
import store from '@/stores';
import ModuleModel from '@/domain/models/Module';
import Permission from '@/domain/models/Permission';
import UserGroupModuleRepository from '@/repositories/UserGroupModuleRepository';
import UserGroupPermissionRepository from '@/repositories/UserGroupPermissionRepository';
import IUserCompanies from '@/domain/interfaces/IUserCompanies';
import IUser from '@/domain/interfaces/IUser';

@Module({
  dynamic: true,
  namespaced: true,
  store,
  name: 'authentication',
  preserveState: sessionStorage.getItem('authentication') !== null,
})
export default class AuthenticationModule extends VuexModule {
  user : IUser = {
    user_id: 0,
    token: '',
    name: '',
    email: '',
    status: '',
    user_group_id: '',
    user_group_name: '',
    user_type: '',
    company_group_id: '',
    company_group_name: '',
    user_gravatar: '',
    company_group_image: '',
  };
  availableModules : ModuleModel[] = [];
  currentModule : ModuleModel|null = null;
  permissions : Permission[] = [];
  companies: IUserCompanies[] = [];

  // TODO Change to user model and compare with UserType
  get isOwner() : boolean {
    return this.user.user_type == 'O';
  }

  get companyIds() : Number[] {
    return this.companies.map((company) => company.company_id);
  }

  @Mutation
  changeUser(newUser : IUser) {
    this.user = newUser;
  }

  @Mutation
  changeCompanies(companies: IUserCompanies[]) {
    this.companies = companies;
  }

  @Mutation
  resetUser() {
    // TODO remove when $session is entirely refactored
    VueInstance.$session.destroy();
    this.user = {
      user_id: 0,
      token: '',
      name: '',
      email: '',
      status: '',
      user_group_id: '',
      user_group_name: '',
      user_type: '',
      company_group_id: '',
      company_group_name: '',
      user_gravatar: '',
      company_group_image: '',
    };
    this.availableModules = [];
    this.currentModule = null;
    this.permissions = [];
    this.companies = [];
  }

  @Mutation
  setAvailableModules(modules : ModuleModel[]) {
    this.availableModules = modules;
  }

  @Mutation
  setCurrentModule(module : ModuleModel) {
    this.currentModule = module;
  }

  @Mutation
  setCurrentPermissions(permissions : Permission[]) {
    this.permissions = permissions;
  }

  @Action({ rawError: true })
  async loadAvailableModules() : Promise<ModuleModel[]> {
    const companyGroupId = Number(this.user.company_group_id);
    const userGroupId = Number(this.user.user_group_id);
    const allModules = await new UserGroupModuleRepository().getAll(companyGroupId, userGroupId);
    const result = allModules.filter((mod) => mod.isActive && mod.isPurchased);
    this.context.commit('setAvailableModules', result);
    if (result.length == 1) {
      this.context.commit('setCurrentModule', result[0]);
    }
    return result;
  }

  @Action({ rawError: true })
  async loadModulePermissions(moduleInfo : ModuleModel) : Promise<void> {
    const userGroupId = Number(this.user.user_group_id);
    const allPermissions = await new UserGroupPermissionRepository()
      .getAll(userGroupId, moduleInfo.id);
    this.context.commit('setCurrentPermissions', allPermissions);
  }
}
