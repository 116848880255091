<template>
  <aside class="events-summary">
    <div class="current-day d-flex align-center">
      <span class="day">{{ todayMoment.date() }}</span>
      <div class="d-flex flex-column align-start ml-2">
        <span class="month">{{ todayMoment.format('MMMM') }}</span>
        <span class="year">{{ todayMoment.year() }}</span>
      </div>
    </div>
    <div class="loading-indicator d-flex align-center" v-if="loading">
      <v-progress-circular indeterminate size="36"></v-progress-circular>
      <span class="ml-4">Carregando informações</span>
    </div>
    <div class="event-container" v-else>
      <div class="day-events">
        <h6>Eventos do dia</h6>
        <ul class="event-list" v-if="todayEvents.length">
          <li
            class="event-list-item d-flex align-center"
            v-for="(event, index) in todayEvents"
            :key="index"
          >
            <span class="event-start">{{ event.start }}</span>
            <span class="event-name flex-grow-1 mx-2 mx-xl-4">{{ event.text }}</span>
            <v-menu offset-y :close-on-content-click="false">
              <template #activator="{ on, attrs }">
                <inn-icon-btn
                  class="event-action"
                  icon="mdi-dots-vertical"
                  v-on="on"
                  v-bind="attrs"
                />
              </template>
              <v-list>
                <v-list-item link @click="$emit('click:edit', event.id)">Editar</v-list-item>
                <v-list-item link>Histórico</v-list-item>
                <v-list-item link>Concluir com sucesso</v-list-item>
                <v-list-item link>Concluir como falha</v-list-item>
              </v-list>
            </v-menu>
          </li>
        </ul>
        <p v-else>Nenhum evento marcado para hoje</p>
      </div>
      <div class="next-events">
        <h6>Próximos eventos</h6>
        <ul class="event-list" v-if="nextEvents.length">
          <li
            class="event-list-item d-flex align-center"
            v-for="(event, index) in nextEvents"
            :key="index"
          >
            <span class="event-start">{{ event.start }}</span>
            <span class="event-name flex-grow-1 mx-2 mx-xl-4">{{ event.text }}</span>
            <v-menu offset-y :close-on-content-click="false">
              <template #activator="{ on, attrs }">
                <inn-icon-btn
                  class="event-action"
                  icon="mdi-dots-vertical"
                  v-on="on"
                  v-bind="attrs"
                />
              </template>
              <v-list>
                <v-list-item link @click="$emit('click:edit', event.id)">Editar</v-list-item>
                <v-list-item link>Histórico</v-list-item>
                <v-list-item link>Concluir com sucesso</v-list-item>
                <v-list-item link>Concluir como falha</v-list-item>
              </v-list>
            </v-menu>
          </li>
        </ul>
        <p v-else>Nenhum evento marcado para hoje</p>
      </div>
    </div>
  </aside>
</template>

<script>
export default {
  name: 'ScheduleSummary',
  props: {
    events: Array,
    loading: Boolean,
  },
  data() {
    return {
      todayMoment: this.$moment(),
    };
  },
  computed: {
    todayEvents() {
      const vm = this;
      return this.events
        .filter((e) => e.isToday)
        .map((e) => ({
          id: e.id,
          text: e.name,
          start: vm.$moment(e.start).format('HH:mm'),
        }));
    },
    nextEvents() {
      const vm = this;
      return this.events
        .filter((e) => e.isFuture)
        .map((e) => ({
          id: e.id,
          text: e.name,
          start: vm.$moment(e.start).format('DD/MM'),
        }));
    },
  },
  methods: {
    isSameDate(dateA, dateB) {
      return dateA.diff(dateB, 'days') == 0 && dateA.date() == dateB.date();
    },
  },
};
</script>

<style lang="sass">
.events-summary
  h6
    color: #36A3F7
    font-size: 20px

  .current-day
    color: #36A3F7

    .day
      font-size: 64px
      font-weight: bold

    .month,
    .year
      font-size: 24px
      font-weight: 500
      line-height: 1.2

  .event-list
    padding: 0

    .event-list-item
      background: #F2F3F7
      padding: 4px 8px
      border-radius: 4px

      & + .event-list-item
        margin-top: 4px
</style>
