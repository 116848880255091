<template>
  <v-menu
    v-model="model"
    :close-on-content-click="false"
    :close-on-click="false"
    :activator="selected"
    max-width="350px"
  >
    <v-card color="grey lighten-4" min-width="350px" flat>
      <v-toolbar flat dense>
        <v-toolbar-title v-if="event" v-html="event.name"></v-toolbar-title>
        <v-spacer></v-spacer>
        <inn-icon-btn icon="mdi-pencil" @click="$emit('click:edit')" />
        <inn-icon-btn icon="mdi-account" @click="handleClientClick" />
        <v-menu offset-y :close-on-content-click="false">
          <template #activator="{ on: innerOn, attrs: innerAttrs }">
            <inn-icon-btn icon="mdi-dots-vertical" v-on="innerOn" v-bind="innerAttrs">
            </inn-icon-btn>
          </template>
          <v-list>
            <v-list-item link @click="$emit('click:conclude', event.id, 'success')"
              >Concluir com sucesso</v-list-item
            >
            <v-list-item link @click="$emit('click:conclude', event.id, 'fail')"
              >Concluir como falha</v-list-item
            >
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-card-text>
        <span v-if="event" v-html="event.description"></span>
      </v-card-text>
      <v-card-actions>
        <inn-btn text color="red darken-1" @click="model = false">
          Fechar
        </inn-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import ModelManagementMixin from '@/mixins/ModelManagementMixin';
import ClientInteraction from '@/domain/models/ClientInteraction';

export default {
  name: 'EditorMenu',
  mixins: [ModelManagementMixin],
  props: {
    event: ClientInteraction,
    selected: Element,
  },
  methods: {
    handleClientClick() {
      // TODO dinamizar a rota do cliente
      this.$router.push('/clientes/1/historico-de-cobranca');
    },
  },
};
</script>

<style></style>
