import IOrderStatusOptions from '@/views/orders/interfaces/IOrderStatusOptions';

export default class OrderSupport {
  public static getStatusChipColor(status: keyof IOrderStatusOptions): string {
    return {
      0: 'green darken-1',
      1: 'red darken-2',
      2: 'blue-grey darken-1',
      3: 'cyan darken-2',
    }[status];
  }

  public static getStatusText(status: keyof IOrderStatusOptions): string {
    return {
      0: 'Confirmado',
      1: 'Bloqueado no crédito',
      2: 'Em espera',
      3: 'Sincronizado',
    }[status];
  }
}
