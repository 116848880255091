























































import {
  Component,
  Mixins,
  Prop,
} from 'vue-property-decorator';
import ModelManagementMixin from '@/mixins/ts/ModelManagementMixin';
import IVDataTableHeader from '@/types/IVDataTableHeader';
import GroupFilterParametersEnum from '@/domain/enums/GroupFilterParametersEnum';
import FilterParametersRepository from '@/repositories/FilterParametersRepository';
import LooseObjectType from '@/types/LooseObjectType';

@Component
export default class DynamicColumnMenu extends Mixins<ModelManagementMixin<IVDataTableHeader[]>>(
  ModelManagementMixin,
) {
  public filterParametersRepository: FilterParametersRepository;

  public constructor() {
    super();

    this.filterParametersRepository = new FilterParametersRepository();
  }

  @Prop(Object) readonly correlationBetweenKeysAndHeaders !: LooseObjectType<string>;

  @Prop(String) readonly groupEnum !: keyof typeof GroupFilterParametersEnum;

  public get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown;
  }

  public get getInfoCardColor(): string {
    if (this.$vuetify.theme.dark) {
      return '#565656';
    }

    return '#F2F3F7';
  }

  public async persistTableState(data: Required<Pick<IVDataTableHeader, 'text' | 'value' | 'show'>>): Promise<void> {
    this.$dialog.startLoading();

    try {
      await this.filterParametersRepository.setFilter(GroupFilterParametersEnum[this.groupEnum], [
        { key: this.correlationBetweenKeysAndHeaders[data.value], value: data.show },
      ]);
    } catch (error) {
      this.$notification.error('Houve um problema ao salvar a configuração de exibição de colunas!');
    } finally {
      this.$dialog.stopLoading();
    }
  }
}
