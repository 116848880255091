import Vue from 'vue';
import { isNullOrEmpty } from '@/utils';

export default class ClientInteraction {
  constructor(id, start, end, details, channel, clientName, contactName, status) {
    this.id = id;
    this.start = start;
    this.end = end;
    this.details = details;
    this.channel = channel;
    this.clientName = clientName;
    this.contactName = contactName;
    this.status = status;
  }

  get name() {
    return this.clientName;
  }

  get isToday() {
    const today = Vue.moment();
    return (
      Vue.moment(this.start).diff(today, 'days') == 0
      && Vue.moment(this.start).date() == today.date()
    );
  }

  get isFuture() {
    const today = Vue.moment();
    return (
      Vue.moment(this.start).diff(today, 'days') > 0
      && Vue.moment(this.start).date() != today.date()
    );
  }

  get isComplete() {
    return !isNullOrEmpty(this.status);
  }

  get description() {
    const formattedDay = Vue.moment(this.start).format('DD/MM/YYYY');
    const formattedStart = Vue.moment(this.start).format('HH:mm');
    const formattedEnd = Vue.moment(this.end).format('HH:mm');

    return `
      <p><strong>Data/Hora:</strong> ${formattedDay} ${formattedStart}-${formattedEnd}</p>
      <p><strong>Nome do responsável:</strong> ${this.contactName}</p>
      <p><strong>Canal para contato:</strong> ${this.channel || 'Indefindo'}</p>
      <p><strong>Descrição:</strong> ${this.details}</p>
    `;
  }
}
