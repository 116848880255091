export default class AccountReceivableChart {
    constructor(
        public expired: number,
        public toExpire: number,
        public paidOut: number,
        public totalExpired: number,
        public totalToExpire: number,
        public totalPaidOut: number,
        public averageMaturity: number,
        public averageDelay: number,
        public creditLimitBalance: number,
		public lastPayment: string,
		public securitiesBalance: number,
		public titleWithHighestValue: number,
		public biggestDelay: number,
		public currentDelay: number,
    ) {}

    static make(data: any): AccountReceivableChart {
        return new AccountReceivableChart(
            data.expired,
            data.toExpire,
            data.paidOut,
            data.totalExpired,
            data.totalToExpire,
            data.totalPaidOut,
            data.averageMaturity,
            data.averageDelay,
            data.creditLimitBalance,
            data.lastPayment,
            data.securitiesBalance,
            data.titleWithHighestValue,
            data.biggestDelay,
            data.currentDelay,
        );
    }
}
