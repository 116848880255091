import IOrderStatusOptions from '@/views/orders/interfaces/IOrderStatusOptions';

export default class CreditAnalisysOrderValues {
    constructor(
        public totalValueOrder: number,
        public higherOrderValue: number,
        public status: keyof IOrderStatusOptions,
    ) {}

    public static make(data: any): CreditAnalisysOrderValues {
        return new CreditAnalisysOrderValues(
            data.totalValueOrder,
            data.higherOrderValue,
            data.status === null ? 1 : parseInt(data.status, 10) as keyof IOrderStatusOptions,
        );
    }
}
