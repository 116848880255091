<template>
  <v-expansion-panels>
    <general-expansion-panel></general-expansion-panel>
    <filters-expansion-panel></filters-expansion-panel>
    <serasa-expansion-panel></serasa-expansion-panel>
    <erp-expansion-panel></erp-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import GeneralExpansionPanel from './GeneralExpansionPanel.vue';
import FiltersExpansionPanel from './FiltersExpansionPanel.vue';
import SerasaExpansionPanel from './SerasaExpansionPanel.vue';
import ErpExpansionPanel from './ErpExpansionPanel.vue';

export default {
  name: 'RuleExpansionPanels',
  components: {
    GeneralExpansionPanel,
    FiltersExpansionPanel,
    SerasaExpansionPanel,
    ErpExpansionPanel,
  },
};
</script>

<style></style>
