export default class Bank {
  constructor(
    public account: string,
    public agency: string,
    public code: string,
    public company: number | string | null,
    public id: number,
    public name: string,
    public shortName: string,
    public idCustomer: string,
  ) { }

  static make(data: any): Bank {
    return new Bank(
      data.account,
      data.agency,
      data.code,
      data.company,
      data.id,
      data.name,
      data.shortName,
      data.idCustomer,
    );
  }
}
